<template>
  <span>Exit...</span>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "Logout",

  mounted() {
    this.$auth.logout();
    this.$cookies.remove("is_login_in");
    this.resetState();
    this.$router.push("/login");
  },

  methods: {
    ...mapActions({
      resetState: "resetState"
    })
  }
};
</script>

<style scoped>

</style>