<template>
  <WizardWrap
    v-if="$route.fullPath != '/wallet-verification'"
    :texts="texts"
    :stage="stage"
  />
</template>

<script>
import _ from "lodash";
import CurrencySupport from "@/help/currencySupport";
import { mapGetters } from "vuex";

import WizardWrap from "./WizardWrap";

export default {
  components: {
    WizardWrap
  },

  data() {
    return {
      currencySup: new CurrencySupport()
    };
  },

  computed: {
    ...mapGetters({
      ethAddresses: "ethAddresses",
      auOwners: "auOwners",
      tokensUtility: "tokensUtility",
      defaultTokenMnemonic: "defaultToken/defaultTokenMnemonic",
      profile: "profile/profile"
    }),

    texts() {
      return [
        {
          label: {
            text: this.$t(`investWizard.title1${this.isEqual}`)
          },
          link: {
            text: this.$t("investWizard.titleLink1"),
            link: "/wallet-verification"
          },
          button: {
            text: this.$t("investWizard.titleBtn1"),
            link: "/wallet-verification"
          },
          verification: false
        },
        {
          label: {
            text: this.$t("investWizard.title2")
          },
          link: {
            text: this.$t("investWizard.titleLink2"),
            link: "/briefcase/buy-form"
          },
          button: {
            text: this.$t("investWizard.titleBtn2"),
            link: "/briefcase/buy-form"
          },
          verification: true
        },
        {
          label: {
            text: this.$t("investWizard.title3")
          },
          link: {
            text: this.$t("investWizard.titleLink3"),
            link: "/projects"
          },
          button: {
            text: this.$t("investWizard.titleBtn3"),
            link: "/projects"
          },
          verification: true
        }
      ];
    },

    tokenUSD() {
      const item = _.find(this.tokensUtility, el => el.mnemonicEng === process.env.VUE_APP_TOKEN_DEFAULT);
      return this.currencySup.getAmount(item.id) * this.currencySup.getCurrency(item.id, this.defaultTokenMnemonic);
    },

    stage() {
      if (!this.address || !this.address?.verifiedAt || !this.address?.status) {
        return 0;
      }
      if (!this.tokenUSD) {
        return 1;
      }
      return 2;
    },

    address() {
      return _.get(this.ethAddresses, "[0]", "");
    },

    isEqual() {
      const buf = process.env.VUE_APP_TITLE;
      return /equal/i.test(buf) ? "Equal" : "";
    }
  }
};
</script>