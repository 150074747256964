<template>
  <div v-if="company">
    <div class="title">
      {{ `${$t('requisites.label')} ${company.nameRus}` }}
    </div>
    <div class="req-data">
      <span class="req-name">{{ $t('requisites.fullNameLabel') }}:</span>
      <span class="req-value">{{ company.nameRus }}</span>
    </div>
    <div class="req-data">
      <span class="req-name">{{ $t('requisites.INNKPPLabel') }}:</span>
      <span class="req-value">{{ "rusInn" in company && "rusKpp" in company ? `${company.rusInn} / ${company.rusKpp}`
        :
        $t('requisites.noInfo') }}</span>
    </div>
    <div class="req-data">
      <span class="req-name">{{ $t('requisites.OGRNLabel') }}:</span>
      <span class="req-value">{{ "rusOgrn" in company ? company.rusOgrn : $t('requisites.noInfo') }}</span>
    </div>
    <div class="req-data">
      <span class="req-name">{{ $t('requisites.registeredAddressLabel') }}:</span>
      <span class="req-value">{{ "addressRus" in company ? company.addressRus : $t('requisites.noInfo') }}</span>
    </div>
    <ProgressLoader
      v-if="loading"
      mode="indeterminate"
      color="#5AC396"
      size="150px"
    />
    <div
      v-else
      class="req-data"
    >
      <span class="req-name">{{ $t('requisites.bankDetailsLabel') }}:</span>
      <span
        v-if="'requisites' in company"
        class="req-value"
      >
        {{ getBankRequisites(company) }}
      </span>
      <span
        v-else
        class="req-value"
      >{{ $t('requisites.noInfo') }}</span>
      <div class="req-buttons">
        <AuButton
          border-radius="10"
          bordered
          center
          width="200px"
          @click="editBankRequisites(company)"
        >
          {{ $t('requisites.editButton') }}
        </AuButton>
        <AuButton
          v-if="'requisites' in company"
          border-radius="10"
          bordered
          center
          width="200px"
          @click="clearCompanyBankRequisites(company.requisites.id, index)"
        >
          {{ $t('requisites.deleteButton') }}
        </AuButton>
      </div>
    </div>
  </div>
</template>

<script>
import ProgressLoader from "@/loaders/progress-bar";

import { mapActions, mapGetters } from "vuex";

export default {

  components: { ProgressLoader },

  props: {
    company: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      editDialog: false,
      editedBankRequisites: null,
      loading: false
    };
  },

  computed: {
    ...mapGetters({
      companies: "companies"
    })
  },

  methods: {
    ...mapActions({
      deleteBankRequisites: "deleteBankRequisites"
    }),

    getBankRequisites(company) {
      return `${this.$t("requisites.checkingAccountLabel")}: ${company.requisites.rs || this.$t("requisites.noInfo")}\n${this.$t("requisites.banknameLabel")}: ${company.requisites.bankname || this.$t("requisites.noInfo")}\n${this.$t("requisites.bikLabel")}: ${company.requisites.bik || this.$t("requisites.noInfo")}\n${this.$t("requisites.correspondentAccountLabel")}: ${company.requisites.ks || this.$t("requisites.noInfo")}`;
    },

    editBankRequisites(company) {
      this.$emit("editBankRequisites", company);
    },

    async clearCompanyBankRequisites(id) {
      this.loading = true;

      await this.deleteBankRequisites(id);

      this.loading = false;
    }
  }
};
</script>

<style scoped lang="scss">
.title {
  font-weight: 600;
  font-size: 24px;
  border-bottom: 1px solid var(--color-70);
  padding-bottom: 30px;
  margin-bottom: 30px;
}

.req {
  &-page-wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &-data {
    display: flex;
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 15px;
  }

  &-name {
    width: 30%;
  }

  &-value {
    width: 70%;
    padding-left: 5px;
    white-space: break-spaces;
    color: var(--color-60)
  }

  &-buttons {
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 10px;
    right: 20px;
  }
}
</style>