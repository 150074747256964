import StateControllerBase from "@/store/stateControllerBase";
import { setLoadingState } from "@/store/setLoadingState";

import ProfileController from "@/api/profileController";
const profileController = new ProfileController();

class Actions extends StateControllerBase {
  setDataLoading({ commit }, dataLoading) {
    commit("setDataLoading", dataLoading);
  }

  @setLoadingState
  async getProfile({ commit }, id) {
    let data = await profileController.getProfile(id);
    commit("setProfile", data);
  }
  // @setLoadingState // for Socket
  async getProfileWihtoutLoading({ commit }, id) {
    let data = await profileController.getProfile(id);
    commit("setProfile", data);
  }

  // @setLoadingState
  async editProfile(_context, data) {
    const params = {
      iName: data?.iName,
      fName: data?.fName,
      oName: data?.oName
    };
    await profileController.editProfile(data.id, params);
  }

  async confirmEmail(_context, url) {
    return await profileController.confirmEmail(url);
  }

  @setLoadingState
  async patchProfile(_context, { id, params }) {
    await profileController.editProfile(id, params);
  }
}
export default (new Actions).asPlainObject();