<template>
  <div class="wv-main">
    <Verification />
  </div>
</template>

<script>
import Verification from "@/components/Verification";

export default {
  name: "WalletVerification",
  components: {
    Verification
  }
};
</script>

<style scoped lang="scss">
.wv-main {
  margin-top: 10px;
}
</style>