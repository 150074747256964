export default class localStorageController {
  static setIsLightTheme(value) {
    localStorage.setItem("isLightTheme", value);
  }

  static getIsLightTheme() {
    return localStorage.getItem("isLightTheme");
  }

  static setUserRef(value) {
    localStorage.setItem("userRef", value);
  }

  static getUserRef() {
    return localStorage.getItem("userRef");
  }

  static setRefSource(value) {
    localStorage.setItem("refSource", value);
  }

  static getRefSource() {
    return localStorage.getItem("refSource");
  }

  static getUserMadeChoice() {
    return localStorage.getItem("userMadeChoice");
  }

  static setUserMadeChoice() {
    return localStorage.setItem("userMadeChoice", "true");
  }
}