import axios from "axios";

export default class axiosClient {
  async getDaoRequests(page, itemsPerPage, tokenDaoId, isFinished) {
    const headers = {
      accept: "application/ld+json",
      "Content-Type": "application/ld+json"
    };

    const params = {
      page: page,
      itemsPerPage: itemsPerPage,
      tokenDaoId: tokenDaoId,
      isFinished: isFinished
    };

    const resp = await axios.get("/api/dao_requests", {
      headers: headers,
      params: params
    });

    const data = resp.data["hydra:member"];
    const totalItems = resp.data["hydra:totalItems"];
    return { data, totalItems };
  }

  async getDaoElements(requestId) {
    const params = {
      requestId: requestId,
      pagination: false
    };
    const resp = await axios.get("/api/dao_elements", { params: params });
    return resp.data;
  }

  async addDaoRequest(body) {
    return await axios.post("/api/dao_requests", body);
  }

  async editDaoRequest(id, body) {
    const headers = {
      "Content-Type": "application/merge-patch+json"
    };
    return await axios.patch(`/api/dao_requests/${id}`, body, { headers });
  }

  async deleteDaoRequest(id) {
    return await axios.delete(`/api/dao_requests/${id}`);
  }

  async addDaoElements(body) {
    return await axios.post("/api/dao_elements", body);
  }

  async editDaoElement(id, body) {
    const headers = {
      "Content-Type": "application/merge-patch+json"
    };
    return await axios.patch(`/api/dao_elements/${id}`, body, { headers });
  }

  async deleteDaoElement(id) {
    return await axios.delete(`/api/dao_elements/${id}`);
  }

  async getDaoEvents(params) {
    const headers = {
      accept: "application/ld+json",
      "Content-Type": "application/ld+json"
    };

    const resp = await axios.get("/api/dao_events", {
      headers: headers,
      params: params
    });

    const data = resp.data["hydra:member"];
    const totalItems = resp.data["hydra:totalItems"];
    return { data, totalItems };
  }

  async getToken(id) {
    const resp = await axios.get(`/api/au_tokens/${id}`);
    return resp.data;
  }

  async getVoitingTokens() {
    const params = {
      pagination: false,
      isVoiting: true
    };
    const resp = await axios.get("/api/au_tokens", { params: params });
    return resp.data;
  }
}