import StateControllerBase from "@/store/stateControllerBase";

import MessageController from "@/api/messageController";

const messageController = new MessageController();

class Actions extends StateControllerBase {
  async getMessages({ commit }, params) {
    const resp = await messageController.getMessages(params);
    commit("setMessages", resp.data);
    commit("setTotalMessages", resp.totalItems);
  }

  async postMessage(_context, body) {
    return await messageController.postMessage(body);
  }

  async setViewed(_context, id) {
    return await messageController.setViewed(id);
  }

  async getUnviewedMessageCount(context) {
    const resp = await messageController.getUnviewedMessageCount();
    context.commit("setUnviewedMessageCount", resp.data?.count);
  }

  decUnviewedMessage(context) {
    const value = context.getters.unviewedMessageCount;
    context.commit("setUnviewedMessageCount", value === 0 ? 0 : value - 1);
  }
}

export default (new Actions).asPlainObject();