<template>
  <div class="avatar">
    <div
      class="avatar-content"
      @click="addAvatar"
    >
      <img
        v-if="src"
        class="img"
        :src="src"
        alt="icon"
      >
      <img
        v-else
        class="no-img"
        :src="`/svg/${theme}/plus.svg`"
        alt="icon"
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "AuAvatar",
  props: {
    src: {
      type: String,
      // default: "/img/Profile.png"
      default: ""
    }
  },
  data() {
    return {};
  },
  computed: {
    theme() {
      return this.$store.getters.isLightTheme ? "light" : "dark";
    }
  },
  methods: {
    addAvatar() {
      console.log("addAvatar");
    }
  }
};
</script>

<style lang="scss" scoped>

.avatar {
  cursor: pointer;

  .avatar-content {
    display: flex;
  }

  .img {
    border-radius: 50%;
    transition: 0.2s ease-in-out;
    object-fit: cover;
    width: 60px;
    height: 60px;

    &:hover {
      opacity: 0.7;
      transition: 0.2s ease-in-out;
    }
  }
  .no-img {
    border-radius: 50%;
    border: 1px solid var(--color-20);
    transition: 0.2s ease-in-out;
    object-fit: cover;
    padding: 20px;

    &:hover {
      opacity: 0.7;
      transition: 0.2s ease-in-out;
    }
  }
  .icon {
    font-size: 30px;
    border-radius: 50%;
    transition: 0.2s ease-in-out;
    background-color: var(--color-50);
    border: 1px solid var(--color-30);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 100px;

    &:hover {
      opacity: 0.7;
      transition: 0.2s ease-in-out;
    }
  }
}

</style>
