<template>
  <div
    class="switch"
    :selected="modelValue"
    @click="click"
  >
    {{ label }}
  </div>
</template>

<script>
export default {
  name: "AuSwitch",

  props: {
    label: {
      type: String,
      default: ""
    },
    modelValue: {
      type: Boolean,
      default: false
    }
  },

  emits: ["update:modelValue"],

  methods: {
    click() {
      this.$emit("update:modelValue", !this.modelValue);
    }
  }
};
</script>

<style scoped lang="scss">
.switch
{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--color-80);
  border-radius: 12px;
  font-size: 13px;
  color: var(--color-60);
  padding: 7px 10px;
  width: auto;
  height: 100%;
  cursor: pointer;

  &[selected=true], &:hover {
    border: 1px solid gray;
    color: #5AC396;
  }
}
</style>