<template>
  <div class="с-form">
    <div>
      <input
        v-model="body.status"
        type="radio"
        name="status"
        value="draft"
        checked
        @change="sendBody"
      >
      <span class="с-label">
        Сохранить как черновик
      </span>
    </div>
    <div>
      <input
        v-model="body.status"
        type="radio"
        name="status"
        value="sended"
        @change="sendBody"
      >
      <span class="с-label">
        Выпустить токен {{ isInMarketplace ? "и разместить его в маркет" : "" }}
      </span>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
export default {
  name: "Confirmation",
  props: {
    modelValue: {
      type: Object,
      default: () => {}
    },
    isInMarketplace: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    body() {
      if (!_.isEmpty(this.modelValue)) {
        return this.modelValue;
      }
      else {
        return {
          status: "draft"
        };
      }
    }
  },
  mounted() {
    this.sendBody();
  },
  methods: {
    sendBody() {
      this.$emit("update:modelValue", this.body);
    }
  }
};
</script>

<style scoped lang="scss">
.с-form{
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  border-radius: 10px;
}
.с-label{
  font-size: 15px;
  color: var(--text-color);
  margin-bottom: 15px;
}
</style>