<template>
  <ul class="Pagination">
    <li
      v-if="!hideFirstButton"
      class="PaginationControl"
    >
      <AuIcon
        icon="page_first"
        class="Control select__chevron-icon"
        :class="{ 'Control-active': isPrevControlsActive }"
        @click="goToFirst"
      />
    </li>
    <li class="PaginationControl">
      <AuIcon
        icon="page_left"
        class="Control select__chevron-icon"
        :class="{ 'Control-active': isPrevControlsActive }"
        @click="goToPrev"
      />
    </li>
    <MyPage
      v-for="page in pagination"
      :key="`pagination-page-${page}`"
      :page="page"
      :current="modelValue"
      :active-color="activeColor"
      @update="updatePageHandler"
    />
    <li class="PaginationControl">
      <AuIcon
        icon="page_right"
        class="Control select__chevron-icon"
        :class="{ 'Control-active': isNextControlsActive }"
        @click="goToNext"
      />
    </li>
    <li
      v-if="!hideLastButton"
      class="PaginationControl"
    >
      <AuIcon
        icon="page_last"
        class="Control select__chevron-icon"
        :class="{ 'Control-active': isNextControlsActive }"
        @click="goToLast"
      />
    </li>
  </ul>
</template>

<script>
import AuIcon from "@/components/extended/AuIcon.vue";
import MyPage from "./page/MyPage.vue";
import { computed, defineComponent } from "vue";

export default defineComponent({
  name: "MyPagination",
  components: {
    AuIcon,
    MyPage
  },
  props: {
    pages: {
      type: Number,
      default: 0
    },
    rangeSize: {
      type: Number,
      default: 1
    },
    modelValue: {
      type: Number,
      default: 0
    },
    activeColor: {
      type: String,
      default: ""
    },
    hideFirstButton: {
      type: Boolean,
      default: false
    },
    hideLastButton: {
      type: Boolean,
      default: false
    }
  },
  emits: ["update:modelValue"],

  setup(props, { emit }) {
    // pagination logic
    const pagination = computed(() => {
      const res = [];
      const minPaginationElems = 5 + props.rangeSize * 2;

      let rangeStart =
        props.pages <= minPaginationElems
          ? 1
          : props.modelValue - props.rangeSize;
      let rangeEnd =
        props.pages <= minPaginationElems
          ? props.pages
          : props.modelValue + props.rangeSize;
      rangeEnd = rangeEnd > props.pages ? props.pages : rangeEnd;
      rangeStart = rangeStart < 1 ? 1 : rangeStart;

      if (props.pages > minPaginationElems) {
        const isStartBoundaryReached = rangeStart - 1 < 3;
        const isEndBoundaryReached = props.pages - rangeEnd < 3;

        if (isStartBoundaryReached) {
          rangeEnd = minPaginationElems - 2;
          for (let i = 1; i < rangeStart; i++) {
            res.push(i);
          }
        }
        else {
          res.push(1);
          res.push(null);
        }

        if (isEndBoundaryReached) {
          rangeStart = props.pages - (minPaginationElems - 3);
          for (let i = rangeStart; i <= props.pages; i++) {
            res.push(i);
          }
        }
        else {
          for (let i = rangeStart; i <= rangeEnd; i++) {
            res.push(i);
          }
          res.push(null);
          res.push(props.pages);
        }
      }
      else {
        for (let i = rangeStart; i <= rangeEnd; i++) {
          res.push(i);
        }
      }

      return res;
    });

    function updatePageHandler(params) {
      emit("update:modelValue", params);
    }

    // controls
    const isPrevControlsActive = computed(() => {
      return props.modelValue > 1;
    });
    const isNextControlsActive = computed(() => {
      return props.modelValue < props.pages;
    });

    function goToFirst() {
      if (isPrevControlsActive.value) {
        emit("update:modelValue", 1);
      }
    }
    function goToPrev() {
      if (isPrevControlsActive.value) {
        emit("update:modelValue", props.modelValue - 1);
      }
    }

    function goToLast() {
      if (isNextControlsActive.value) {
        emit("update:modelValue", props.pages);
      }
    }
    function goToNext() {
      if (isNextControlsActive.value) {
        emit("update:modelValue", props.modelValue + 1);
      }
    }

    return {
      pagination,
      updatePageHandler,
      isPrevControlsActive,
      isNextControlsActive,
      goToFirst,
      goToLast,
      goToPrev,
      goToNext
    };
  }
});
</script>

<style scoped lang="scss">

.Pagination {
  display: flex;
  justify-content: center;
  flex-flow: row;
  flex-wrap: wrap;
  align-items: center;
  margin: 20px auto;
  padding: 0;
  list-style-type: none;
}

.PaginationControl {
  display: flex;
  align-items: center;
}

.Control {
  position: relative;
  display: block;
  width: 32px;
  height: 32px;
  margin: 0 2px;
  padding: 6px;

  &-active {
    cursor: pointer;
    transition: 0.2s ease-in-out;
    border: 1px solid var(--color-70);
    border-radius: 5px;

    &:hover {
      transition: 0.2s ease-in-out;
      border: 1px solid var(--color-50);
    }
  }
}
</style>
