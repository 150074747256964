<template>
  <div>
    <AuBreadcrumb
      :parent-name="$t('router.pages.user')"
      parent-path="user"
    />
    <div class="card">
      <div class="card__header">
        <div class="title">
          {{ $t("settings.title") }}
        </div>
      </div>
      <div class="fieldset">
        <AuSelect
          :value="language"
          :allow-empty="false"
          :select-label="$t('settings.language.label')"
          :options="languages"
          @input="setLocale"
        />
        <AuSelect
          :value="defaultTokenId"
          :allow-empty="false"
          :select-label="$t('settings.defaultToken.label')"
          :options="tokensOptions"
          @input="setDefaultToken"
        />
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";

import { mapActions, mapGetters } from "vuex";

export default {
  name: "Setting",

  computed: {
    ...mapGetters({
      language: "language",
      tokensFiat: "tokensFiat",
      tokensGold: "tokensGold",
      defaultTokenId: "defaultToken/defaultTokenId"
    }),

    tokensOptions() {
      return _.map([...this.tokensFiat, ...this.tokensGold], (token) => ({
        display: token.nameEng,
        value: token.id
      }));
    },

    languages() {
      return _.filter([
        { display: this.$t("settings.language.ru"), value: "ru" },
        { display: this.$t("settings.language.en"), value: "en" }
        // { display: this.$t("settings.language.uz"), value: "uz" }
      ], lang => process.env.VUE_APP_SUPPORTED_LOCALES.includes(lang.value));
    }
  },

  methods: {
    ...mapActions({
      setLanguage: "setLanguage",
      getBalances: "getBalances",
      patchCurrentUser: "patchCurrentUser"
    }),

    setLocale(language) {
      this.setLanguage(language);
    },

    async setDefaultToken(tokenId) {
      await this.patchCurrentUser({
        userId: this.$auth.user().id,
        body: { defaultToken: `/api/au_tokens/${tokenId}` }
      });
      await this.getBalances(tokenId);
      this.$auth.fetch();
    }
  }
};
</script>

<style lang="scss" scoped>

.card {
  width: 100%;
  padding: 22px;

  &__header {
    display: flex;
    flex-direction: column;
    gap: 40px;

    border-bottom: 1px solid var(--color-70);
    padding-bottom: 15px;
  }
  .fieldset {
    width: 50%;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }

  .title {
    color: var(--text-color);
    font-size: 24px;
    line-height: 1em;
  }
  .profile {
    display: flex;
  }
  .person-info {
    color: var(--color-20);
  }
  .profile-fields {
    width: 50%;
    margin-top: 20px;
    @media screen and (max-width: 768px) {
      width: 100%;
    }
    .inp{
      margin: 10px 0px;
    }
    .btn-save {
      margin-top: 30px;
      width: 100%;
    }
  }
  .person-fio {
    margin-right: 6px;
  }
  .data-in {
    border-radius: 10px;
    border: 1px solid lightgray;
    padding: 15px;
    margin-bottom: 10px;

    &::placeholder {
      color: var(--color-50);
    }
}
}
</style>