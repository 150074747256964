<template>
  <AuModal
    :model-value="modelValue"
    width="500px"
    @update:modelValue="value => $emit('update:modelValue', value)"
  >
    <template #header>
      <span class="modal_head_text">
        {{ headerText }}
      </span>
    </template>
    <template #body>
      <span>
        {{ bodyText }}
      </span>
    </template>
    <template #footer>
      <div class="modal_buttons">
        <AuButton
          bordered
          center
          width="100%"
          @click="confirm"
        >
          {{ confirmButtonText }}
        </AuButton>
        <AuButton
          bordered
          center
          width="100%"
          @click="decline"
        >
          {{ declineButtonText }}
        </AuButton>
      </div>
    </template>
  </AuModal>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    headerText: {
      type: String,
      default: "Подтвердите действие"
    },
    bodyText: {
      type: String,
      default: ""
    },
    confirmButtonText: {
      type: String,
      default: "Да"
    },
    declineButtonText: {
      type: String,
      default: "Нет"
    }
  },

  emits: ["update:modelValue"],

  methods: {
    decline() {
      this.$emit("update:modelValue", false);
    },

    confirm() {
      this.$emit("update:modelValue", false);
      this.$emit("confirm");
    }
  }
};
</script>

<style lang="scss" scoped>
.modal {

  &_head_text {
    font-weight: 450;
    font-size: 20px;
    margin-bottom: 20px;
  }

  &_buttons {
    display: flex;
    gap: 20px;
    justify-content: space-between;
    margin-top: 20px
  }
}
</style>