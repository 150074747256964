<template>
  <span
    v-if="showValue"
    class="decimals"
  >
    {{ displayValue }}

    <template v-if="showAddition">
      <span
        v-if="checkCurrency"
        class="mnemonic"
      >{{ checkCurrency }}</span>
      <span
        v-else
        class="no-currency"
      >EMPTY TOKEN NAME</span>
    </template>
  </span>
  <span v-else>-</span>
</template>

<script>
import BigNumber from "bignumber.js";
export default {
  name: "AuDecimalStatic",

  props: {
    value: {
      type: [String, Number, BigNumber],
      default: ""
    },

    decimal: {
      type: Number,
      default: 0
    },

    indent: {
      type: Number,
      default: 2
    },

    //Валюта
    addition: {
      type: String,
      default: ""
    },

    showAddition: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    showValue() {
      const value = this.value ? this.value.toString().replace(",", ".") : 0;
      return !BigNumber(value).isNaN();
    },

    displayValue() {
      let value = this.formatedValue;
      if (this.indent && value && value.includes(".")) {
        value = Number(value).toLocaleString("ru", {
          minimumFractionDigits: this.indent,
          maximumFractionDigits: this.indent,
          roundingMode: "trunc" });
      }
      else {
        value = Number(value).toLocaleString("ru", { maximumFractionDigits: this.decimal, roundingMode: "trunc" });
      }
      return value.replace(",", ".");
    },

    checkCurrency() {
      if (this.addition == "-") {
        return null;
      }
      else {
        return this.addition;
      }
    },

    formatedValue() {
      const type = typeof this.value;
      let value = this.value ? this.value.toString().replace(",", ".") : 0;

      let result = "";

      if (this.decimal == 0) {
        return value;
      }

      if (type == "number") {
        result = BigNumber(value).toFixed(this.decimal);
      }
      else {
        result = BigNumber(value).dividedBy(BigNumber(10).pow(this.decimal)).toFixed(this.decimal);
      }

      return BigNumber(result).toString();
    }
  }
};

</script>

<style scoped lang="scss">
.no-currency {
  color: grey;
}

</style>