<template>
  <div class="user-view" />
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "Verify",
  data() {
    return {
    };
  },
  created() {
    this.checkEmail();
  },
  methods: {
    ...mapActions({
      emailVerify: "emailVerify"
    }),

    goToLogin() {
      if (this.$auth.check()) {
        this.$router.push({ name: "dashboard" });
      }
      else {
        this.$router.push({ name: "login" });
      }
    },

    async checkEmail() {
      const hash = this.$route.params.hash;

      const params = {
        token: `${hash}`
      };
      try {
        const resp = await this.emailVerify(params);
        if (resp.status == 267) {
          this.$store.dispatch("setIsTokenVerify", "error");
        }
        if (resp.status == 204) {
          this.$store.dispatch("setIsTokenVerify", "success");
        }
      }
      catch (error) {
        this.$store.dispatch("setIsTokenVerify", "error");
        console.log("error", error.response);
      }
      finally {
        this.goToLogin();
      }
    }
  }
};
</script>

<style scoped lang="scss">
.user-view {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: var(--main-color);
  height: 100vh;
  width: 100vw;
}
</style>
