import AxiosController from "@/api/axiosController";
import NftController from "@/api/nftController";

const nftController = new NftController();
const axiosController = new AxiosController();

export default {
  namespaced: true,
  state: {
    nfts: [],
    totalNft: null,
    userContact: [],
    totalUserContact: null
  },

  getters: {
    nfts: state => {
      return state.nfts;
    },

    totalNft: state => {
      return state.totalNft;
    },

    userContact: state => {
      return state.userContact;
    },

    totalUserContact: state => {
      return state.totalUserContact;
    }
  },

  mutations: {
    setNfts(state, nfts) {
      state.nfts = nfts;
    },

    setTotalNft(state, totalNft) {
      state.totalNft = totalNft;
    },

    setUserContact(state, userContact) {
      state.userContact = userContact;
    },

    setTotalUserContact(state, totalUserContact) {
      state.totalUserContact = totalUserContact;
    }
  },

  actions: {
    async getNfts({ commit }, { page, countryCode, sectionId, tokenQuoteId, name, inSale, status }) {
      const params = {
        pagination: true,
        page: page,
        countryCode: countryCode,
        section_id: sectionId,
        token_quote_id: tokenQuoteId,
        itemsPerPage: 30,
        in_sale: inSale,
        status: status
      };

      if (name) {
        params.product_name = name;
      }

      let resp = await nftController.getNfts(params);
      const data = resp.data;
      const totalItems = resp.totalItems;
      commit("setNfts", data);
      commit("setTotalNft", totalItems);
      return { data, totalItems };
    },

    async getNftById(_context, id) {
      return await nftController.getNftById(id);
    },

    async getNftOwnersById(_context, { id, isInMarket }) {
      return await axiosController.getNftOwnersById(id, isInMarket);
    },

    async getUserContact({ commit }, { page, itemsPerPage }) {
      const resp = await axiosController.getUserContact(page, itemsPerPage);
      commit("setUserContact", resp.data);
      commit("setTotalUserContact", resp.totalItems);
    }
  }
};
