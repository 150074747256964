<template>
  <a
    :href="link + volume"
    target="_blank"
  >{{ name }}</a>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: ""
    },
    volume: {
      type: String,
      default: "#"
    }
  },

  computed: {
    theme() {
      return this.$store.getters.isLightTheme ? "light" : "dark";
    },
    link() {
      return `${process.env.VUE_APP_SCAN_BASE_URL}address/`;
    }
  }
};
</script>

<style scoped lang="scss"></style>
