<template>
  <div class="projects-info card">
    <div class="head">
      <div class="head-main">
        <div>Мои инвест. проекты</div>
        <div>
          <AuButton
            bordered
            width="100%"
            @click="openApplication"
          >
            Подать заявку на новый проект
          </AuButton>
        </div>
      </div>

      <div>
        <div>{{ projectsData.length }}</div>
        <div class="head-explain">
          Количество проектов
        </div>
      </div>
    </div>
    <AuTable
      class="projects-table"
      :columns="columns"
      :items="projectsData"
      :icons="null"
    />
    <div class="projects-accordion">
      <AuAccordion
        v-for="(project, index) in projectsData"
        :key="project"
      >
        <template #title>
          <span>Проект {{ index + 1 }}</span>
        </template>
        <template #content>
          <div class="container-fluid">
            <div class="row">
              <div class="col">
                <div class="projects-accordion-title">
                  {{ columns[0].name }}
                </div>
                <div class="projects-accordion-content">
                  {{ project.name }}
                </div>
                <div class="projects-accordion-title">
                  {{ columns[1].name }}
                </div>
                <div class="projects-accordion-content">
                  {{ project.condition }}
                </div>
                <div class="projects-accordion-title">
                  {{ columns[2].name }}
                </div>
                <div class="projects-accordion-content">
                  {{ project.costNeed }}
                </div>
                <div class="projects-accordion-title">
                  {{ columns[3].name }}
                </div>
                <div class="projects-accordion-content">
                  {{ project.costAvail }}
                </div>
                <div class="projects-accordion-title">
                  {{ columns[4].name }}
                </div>
                <div class="projects-accordion-content">
                  {{ project.costRest }}
                </div>
              </div>
            </div>
          </div>
        </template>
      </AuAccordion>
    </div>
  </div>
</template>

<script>

export default {
  name: "InvestProjects",
  data() {
    return {
      columns: [{ name: "Наименование проекта", align: "end" },
        { name: "Условия инвестирования" },
        { name: "Сколько средств нужно" },
        { name: "Сколько купили" },
        { name: "Сколько осталось" }],
      projectsData: [
        {
          name: "Закупка кранов для строительства жилого комлекса «LUX»",
          condition: "Пробежать 10 км",
          costNeed: `5 000 000 ${this.$auth.user().defaultTokenMnemonic}`,
          costAvail: "831 842 SRUR",
          costRest: "4 168 158 SRUR"
        },
        {
          name: "Покупка алюминиевых труб",
          condition: "Стоять на 1 ноге",
          costNeed: `3 201 390 ${this.$auth.user().defaultTokenMnemonic}`,
          costAvail: "98 264 SRUR",
          costRest: "3 103 126 SRUR"
        }
      ]
    };
  },

  methods: {
    openApplication() {
      this.$router.push({ name: "invest projects application" });
    }
  }
};
</script>

<style lang="scss" scoped>
.projects {
  &-info {
    padding: 20px 20px 0 20px;
  }

  &-table {
    @media screen and (max-width:520px) {
      display: none;
    }
  }

  &-accordion {
    display: none;

    @media screen and (max-width:520px) {
      display: block;
      &-title{
        color: var(--color-60);
      }
      &-content{
        margin-bottom: 15px;
      }
    }
  }
}
.head{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 80px;
  margin-bottom: 20px;
  &-explain {
    font-size: 12px;
    color: var(--color-60);
  }
  &-main {
    display: flex;
    justify-content: space-between;
  }
}
</style>