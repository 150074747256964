<template>
  <div class="main-contain">
    <div class="restore-form">
      <AuIcon
        class="logo"
        :icon="logo"
      />
      <div class="email-block">
        <span class="mb-2">
          {{ message }}
        </span>
        <input
          v-if="showEmailInput"
          v-model="email"
        >
      </div>
      <AuError
        class="mb-4"
        :error-msg="error"
      />
      <div class="button-place">
        <AuButton
          active-bg-color="#2aba7c"
          hovered-bg-color="#2aba7c"
          bordered
          border-color="black"
          font-color="white"
          font-size="12px"
          center
          height="55px"
          :is-progress="progress"
          @click="doAction"
        >
          {{ btnText }}
        </AuButton>
      </div>
    </div>
    <div
      v-if="showEmailInput"
      class="text-remember"
      @click="$router.back()"
    >
      {{ $t("restore.back") }}
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { email, requiredEmail } from "@/localization/localized-validators";
import { mapActions } from "vuex";
import { useVuelidate } from "@vuelidate/core";

export default {
  name: "PasswordRestore",
  props: {
    hash: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      v$: useVuelidate(),
      email: "",
      error: null,
      btnMode: "",
      progress: false,
      showEmailInput: false,
      message: ""
    };
  },
  validations() {
    return {
      email: { requiredEmail, email }
    };
  },
  computed: {
    btnText() {
      return this.btnMode === "next" ? this.$t("restore.next") : this.$t("restore.back");
    },
    logo() {
      return /equal/i.test(process.env.VUE_APP_TITLE) ? "logo.equal" : "logo";
    }
  },
  async mounted() {
    if (this.hash === "") {
      this.goToLogin();
    }
    if (this.hash === "restore") {
      this.message = this.$t("restore.inputEmail");
      this.showEmailInput = true;
      this.btnMode = "next";
    }
    else {
      this.message = this.$t("restore.wait");
      await this.sendPassword();
    }
  },
  methods: {
    ...mapActions({
      restorePassword: "restorePassword",
      restoreRequestCheck: "restoreRequestCheck"
    }),

    checkEmail() {
      this.v$.email.$touch();
      const ret = _.find(this.v$.$errors, error => error.$propertyPath == "email");
      return ret?.$message ?? null;
    },

    async doAction() {
      if (this.btnMode === "next") {
        await this.sendHash();
      }
      else {
        this.goToLogin();
      }
    },

    goToLogin() {
      this.$router.push({ name: "login" });
    },

    async sendHash() {
      this.error = this.checkEmail();
      if (this.error) {
        return;
      }
      this.progress = true;
      try {
        await this.restorePassword(this.email);
        this.message = this.$t("restore.sendHash");
        this.btnMode = "login";
        this.showEmailInput = false;
      }
      catch (e) {
        this.error = this.$t("restore.error") + e?.response.data?.errors;
      }
      this.progress = false;
    },

    async sendPassword() {
      this.progress = true;
      try {
        await this.restoreRequestCheck(this.hash);
        this.message = this.$t("restore.sendPassword");
        this.btnMode = "login";
      }
      catch (e) {
        this.error = this.$t("restore.error") + e?.response.data?.errors;
      }
      this.progress = false;
    }
  }
};
</script>

<style scoped lang="scss">
.main-contain {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: var(--main-color);
  height: 100vh;
  width: 100vw;
}
.restore-form {
  display: flex;
  flex-direction: column;

  width: 350px;
  height: min-content;

  background: var(--card-color);
  border: 1px solid var(--color-90);
  border-radius: 20px;
  padding: 20px;
}
.logo {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
}
.email-block {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 20px;

  input {
    border-radius: 10px;
    border: 1px solid lightgray;
    padding: 15px;
    margin-bottom: 10px;
  }
  span {
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    color: grey;
  }
}
.restore-error {
  text-align: center;
  font-size: 12px;
  color: red;
  margin-bottom: 5px;
}
.button-place {
  display: flex;
  flex-direction: column;
  // gap: 20px;s
}
.text-remember {
  color: grey;
  font-weight: 450;
  font-size: 14px;
  cursor: pointer;
  text-align: center;
  margin-top: 20px;
  text-decoration: underline;
}
</style>