import actions from "./actions";

export default {
  namespaced: true,
  state: {
    dividends: [],
    dividendsTotal: null,
    dividendsHistory: [],
    dividendsHistoryTotal: null,
    dataLoading: false
  },

  getters: {
    dividends: state => {
      return state.dividends;
    },

    dividendsTotal: state => {
      return state.dividendsTotal;
    },

    dividendsHistory: state => {
      return state.dividendsHistory;
    },

    dividendsHistoryTotal: state => {
      return state.dividendsHistoryTotal;
    },

    dataLoading: state => {
      return state.dataLoading;
    }
  },

  mutations: {
    setDividends(state, dividends) {
      state.dividends = dividends;
    },

    setDividendsTotal(state, dividendsTotal) {
      state.dividendsTotal = dividendsTotal;
    },

    setDividendsHistory(state, dividendsHistory) {
      state.dividendsHistory = dividendsHistory;
    },

    setDividendsHistoryTotal(state, dividendsHistoryTotal) {
      state.dividendsHistoryTotal = dividendsHistoryTotal;
    },

    setDataLoading(state, dataLoading) {
      state.dataLoading = dataLoading;
    }
  },

  actions
};
