<template>
  <div class="loader skeleton">
    <div
      v-if="!isInvestment"
      class="project-cover"
    >
      <div
        class="no-image-wrap"
      >
        <div
          class="no-image skeleton skeleton-inner"
        />
      </div>
    </div>

    <div class="body">
      <div class="text skeleton skeleton-inner">
        &nbsp;
      </div>

      <div class="another skeleton skeleton-inner" />

      <div class="button skeleton skeleton-inner" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    height: {
      type: String,
      default: "200px"
    },
    isInvestment: {
      type: Boolean,
      default: false
    }
  }
};
</script>


<style scoped lang="scss">
@keyframes bgAnimate {
  0% {
    background-position: 50% 0;
  }

  100% {
    background-position: -150% 0;
  }
}

.skeleton {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  user-select: none;

  background: var(--card-color);
  background-size: 200% 100%;

  &-inner {
    animation: bgAnimate 2s linear infinite;

    background-image: linear-gradient(
      to right,
      var(--color-90) 0%,
      var(--color-95) 10%,
      var(--color-95) 20%,
      var(--color-90) 100%
    );
  }
}

.loader {
  height: v-bind(height);
  width: calc(25% - 19px);
  border-radius: 25px;

  padding: 15px;

  @media screen and (max-width:1600px) {
    width: calc(33% - 14px);
  }

  @media screen and (max-width:1200px) {
    width: calc(50% - 20px);
  }

  @media screen and (max-width:700px) {
    width: 100%;
  }

  .text {
    width: 60%;
    border-radius: 8px;
  }

  .another {
    height: 100%;
    margin: 20px 0;
    border-radius: 8px;
  }

  .body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    height: 100%;
  }

  .button {
    height: 44px;
    border-radius: 8px;
  }

  .no-image-wrap {
    position: relative;
    width: 100%;

    margin-bottom: 20px;

    &:before{
      content: "";
      display: block;
      padding-top: 50%;
    }

    .no-image {
      position:  absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;

      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 15px;
    };
  }
}
</style>