<template>
  <div class="fs-form">
    <div>
      <div class="mi-label">
        {{ $t("goods.form.goodMarketLabel") }}
      </div>
      <input
        v-model="body.isInMarketplace"
        type="checkbox"
      >
      <span class="fs-text">
        {{ $t('goods.form.goodMarketPlaceholder') }}
      </span>
    </div>
    <div
      v-if="body.isInMarketplace"
      class="fs-subform"
    >
      <AuSelect
        :value="body.tokenQuote"
        :options="tokensOptions"
        :select-label="$t('goods.form.goodTokensLabel')"
        :placeholder="$t('goods.form.goodTokensPlaceholder')"
        clearable
        @input="(value) => {body.tokenQuote = value; sendBody()}"
        @clearValue="body.tokenQuote = null"
      />
      <AuInput
        v-model="body.price"
        clearable
        :label="priceLabel"
        :decimal="decimals"
        type="Number"
        :placeholder="pricePlaceholder"
        @input="sendBody"
      />

      <div v-if="goodType==='single'">
        <div class="mi-label">
          Рассрочка с предоплатой
        </div>
        <div>
          <input
            v-model="body.prepay"
            type="checkbox"
            @change="sendBody"
          >
          <span class="mi-text">
            &nbsp;Разрешить рассрочку с предоплатой
          </span>
        </div>
      </div>

      <AuInput
        v-if="body.prepay && goodType==='single'"
        v-model="body.prepayPercent"
        type="Number"
        clearable
        label="Процент предоплаты"
        placeholder="Введите процент предоплаты"
        @input="sendBody"
      />

      <AuInput
        v-if="body.prepay && goodType==='single'"
        v-model="body.dayToPay"
        type="Number"
        clearable
        label="Количество дней для внесения остатка оплаты"
        placeholder="Введите количество дней"
        @input="sendBody"
      />
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { mapGetters } from "vuex";

export default {
  name: "ForSale",
  props: {
    modelValue: {
      type: Object,
      default: () => {}
    },
    goodType: {
      type: String,
      default: "single"
    }
  },

  computed: {
    ...mapGetters({
      tokensFiat: "tokensFiat",
      tokensGold: "tokensGold"
    }),

    tokensOptions() {
      return _.map([...this.tokensGold, ...this.tokensFiat], token => (
        { display: token.nameRus || token.nameEng, value: `/api/au_tokens/${token.id}` }));
    },

    decimals() {
      if (_.isNull(this.body.tokenQuote)) {
        return 0;
      }
      const token = _.find([...this.tokensGold, ...this.tokensFiat], token => this.body.tokenQuote == `/api/au_tokens/${token.id}`);
      if (_.isUndefined(token)) {
        return 0;
      }
      return token.decimals;
    },

    priceLabel() {
      return this.goodType === "fragment" ? "Цена части" : "Цена";
    },

    pricePlaceholder() {
      return this.goodType === "fragment" ? "Введите цену части" : "Введите цену";
    },

    body() {
      if (!_.isEmpty(this.modelValue)) {
        return this.modelValue;
      }
      else {
        return {
          price: null,
          tokenQuote: null,
          prepay: false,
          prepayPercent: null,
          dayToPay: null,
          isInMarketplace: false
        };
      }
    }
  },
  mounted() {
    this.sendBody();
  },
  methods: {
    sendBody() {
      this.$emit("update:modelValue", this.body);
    }
  }
};
</script>

<style scoped lang="scss">
.fs-form{
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  border-radius: 10px;
}
.fs-subform{
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.mi-label{
  font-size: 14px;
  color: var(--color-50);
  margin-bottom: 15px;
}
.fs-text{
  font-size: 15px;
  color: var(--text-color);
  margin-bottom: 15px;
}
</style>