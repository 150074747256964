export default {
  namespaced: true,
  state: {
    defaultToken: null,
    defaultTokenEnv: process.env.VUE_APP_TOKEN_DEFAULT,
    defaultAutMnemonic: process.env.VUE_APP_AUT_MNEMONIC

  },

  getters: {
    defaultTokenId: state => {
      return state.defaultToken?.id;
    },

    defaultTokenMnemonic: state => {
      return state.defaultToken?.mnemonic;
    },

    defaultAutMnemonic: state => {
      return state.defaultAutMnemonic;
    },

    defaultTokenEnv: state => {
      return state.defaultTokenEnv;
    }
  },

  mutations: {
    setDefaultToken(state, value) {
      state.defaultToken = value;
    }
  },

  actions: {
    setDefaultToken({ commit }, value) {
      commit("setDefaultToken", value);
    }
  }
};
