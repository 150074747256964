import StateControllerBase from "@/store/stateControllerBase";
import { setLoadingState } from "@/store/setLoadingState";

import CardController from "@/api/cardController";

const cardController = new CardController();

class Actions extends StateControllerBase {
  setDataLoading({ commit }, dataLoading) {
    commit("setDataLoading", dataLoading);
  }

  @setLoadingState
  async getCards({ commit }, userId) {
    let data = await cardController.getCards(userId);
    commit("setCards", data);
  }

  @setLoadingState
  async addCard(_context, card) {
    await cardController.addCard(card);
  }

  @setLoadingState
  async deleteCard(_context, id) {
    await cardController.deleteCard(id);
  }

  @setLoadingState
  async editCard(_context, card) {
    await cardController.editCard(card);
  }
}


export default (new Actions).asPlainObject();