<template>
  <div class="card">
    <div class="card__header">
      <div class="card__header-info">
        {{ $t("dashboard.changeChart.label") }}
      </div>

      <div class="card__header-period-buttons">
        <AuSwitchButton
          height="32px"
          width="auto"
          @click="clearFilter"
        >
          {{ $t("dashboard.changeChart.all") }}
        </AuSwitchButton>

        <AuSwitchButton
          height="32px"
          width="auto"
          @click="setFilterMonth(1)"
        >
          1{{ $t("dashboard.changeChart.oneMonth") }}
        </AuSwitchButton>

        <AuSwitchButton
          height="32px"
          width="auto"
          @click="setFilterMonth(4)"
        >
          4{{ $t("dashboard.changeChart.fourMonths") }}
        </AuSwitchButton>

        <AuSwitchButton
          height="32px"
          width="auto"
          @click="setFilterMonth(6)"
        >
          6{{ $t("dashboard.changeChart.sixMonths") }}
        </AuSwitchButton>

        <AuSwitchButton
          height="32px"
          width="auto"
          @click="setFilterYear"
        >
          1{{ $t("dashboard.changeChart.oneYear") }}
        </AuSwitchButton>
      </div>

      <div class="card__header-period-calendar">
        <Datepicker
          v-model="date"
          locale="ru"
          auto-apply
          :max-date="new Date()"
          :enable-time-picker="false"
          :format="format"
          :placeholder="$t('dashboard.changeChart.datePickerPlaceholder')"
          range
          :dark="!isLightTheme"
        />
      </div>
    </div>

    <ProgressLoader
      v-if="loading"
      mode="indeterminate"
      color="#5AC396"
      size="150px"
    />

    <Apexchart
      v-if="!loading"
      class="chart"
      :options="options"
      :series="[...series]"
      height="327"
    />
  </div>
</template>

<script>
import _ from "lodash";
import BigNumber from "bignumber.js";
import Datepicker from "@vuepic/vue-datepicker";
import moment from "moment";
import ProgressLoader from "@/loaders/progress-bar";
import { mapActions, mapGetters } from "vuex";
import "@vuepic/vue-datepicker/dist/main.css";

export default {
  components: {
    Datepicker,
    ProgressLoader
  },

  data() {
    return {
      date: null,
      loading: true
    };
  },

  computed: {
    ...mapGetters({
      history: "history",
      tokens: "tokens",
      defaultTokenId: "defaultToken/defaultTokenId",
      defaultTokenMnemonic: "defaultToken/defaultTokenMnemonic"
    }),

    isLightTheme() {
      return this.$store.getters.isLightTheme;
    },

    options() {
      let mnemonic = this.defaultTokenMnemonic;

      return {
        chart: {
          type: "area",
          zoom: {
            enabled: false
          },
          toolbar: {
            show: false
          }
        },
        dataLabels: {
          enabled: false
        },
        legend: {
          show: false
        },
        stroke: {
          curve: "smooth"
        },
        xaxis: {
          type: "datetime",
          min: this.startDate,
          tickAmount: 8,
          labels: {
            show: true,
            format: undefined,
            formatter(value) {
              return new Date(value).toLocaleDateString();
            },
            style: {
              colors: this.isLightTheme ? "#08080899" : "#FFFFFF99"
            }
          }
        },
        yaxis: {
          labels: {
            formatter(value) {
              return value.toLocaleString("ru", { currency: "RUB" });
            },
            style: {
              colors: this.isLightTheme ? "#08080899" : "#FFFFFF99"
            }
          }
        },
        tooltip: {
          theme: this.isLightTheme ? "light" : "dark",
          y: {
            formatter(value) {
              return value.toLocaleString("ru", { currency: "RUB" }) + " " + mnemonic;
            }
          },
          x: {
            format: "dd MMM yyyy"
          }
        }
      };
    },

    startDate() {
      return new Date(this.history[0]?.createdAt);
    },

    series() {
      let timeGrouped = _.groupBy(this.history, data => moment(data.createdAt).format("l"));
      let token = _.find(this.tokens, token => token.id == this.defaultTokenId);

      let historyItem = {
        name: token.nameEng,
        color: "#00A2E8",
        data: []
      };

      for (const date in timeGrouped) {
        let value = BigNumber(timeGrouped[date][0].value);
        value = value.dividedBy(BigNumber(10).pow(token.decimals)).toNumber();
        historyItem.data.push([date, value]);
      }

      return [historyItem];
    }
  },

  watch: {
    async date(value) {
      if (value == null) {
        await this.getHistoryData({ });
      }

      let dateFrom = new Date(value[0]);
      dateFrom.setHours(3, 0, 0);

      let dateTo = new Date(value[1]);
      dateTo.setHours(26, 59, 59);

      await this.getHistoryData({ dateFrom, dateTo });
    }
  },

  async created() {
    await this.getHistoryData({ });
  },

  methods: {
    ...mapActions({
      getHistory: "getHistory"
    }),

    async getHistoryData(params) {
      this.loading = true;
      await this.getHistory({ ...params, userId: this.$auth.user().id, tokenId: this.defaultTokenId });
      this.loading = false;
    },

    clearFilter() {
      this.date = null;
    },

    setFilterMonth(deduction = 0) {
      let currentDate = new Date();

      const day = currentDate.getDate();
      const month = currentDate.getMonth();
      const year = currentDate.getFullYear();

      this.date = [
        new Date(year, month - deduction, day),
        currentDate
      ];
    },

    setFilterYear() {
      let currentDate = new Date();

      const day = currentDate.getDate();
      const month = currentDate.getMonth();
      const year = currentDate.getFullYear();

      this.date = [
        new Date(year - 1, month, day),
        currentDate
      ];
    },

    format(date) {
      const day1 = date[0].getDate();
      const month1 = date[0].getMonth() + 1;
      const year1 = date[0].getFullYear();

      const day2 = date[1].getDate();
      const month2 = date[1].getMonth() + 1;
      const year2 = date[1].getFullYear();

      if (year1 == year2 && month1 == month2 && day1 == day2) {
        return `${moment(date[0]).locale("ru").format("L")}`;
      }

      if (year1 == year2 && month1 == month2 && day1 == 1 && (day2 == new Date(year2, month2, 0).getDate() || day2 == new Date().getDate())) {
        let month = moment(date[0]).locale("ru").format("MMMM");
        return month[0].toUpperCase() + month.slice(1) + " " + year1;
      }

      if (year1 == year2 && month1 == 1 && day1 == 1 && month2 == 12 && day2 == 31) {
        return year1;
      }

      return `${moment(date[0]).locale("ru").format("L")} - ${moment(date[1]).locale("ru").format("L")}`;
    }
  }
};
</script>

<style scoped lang="scss">
.card {
  padding: 20px 10px;
  display: flex;
  flex-direction: column;

  &__header {
    display: flex;
    justify-content: space-between;
    margin: 0px 9px;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;

    &-info {
      display: flex;
      font-size: 15px;
      gap: 5px;
      white-space: nowrap;
      align-items: center;
    }

    &-period {
      &-buttons {
        display: flex;
        gap: 10px;
      }

      &-calendar {
        display: flex;
        align-items: center;
        gap: 10px
      }

      display: flex;
      gap: 10px;
    }
  }
}

.select-period {
  color: var(--color-60);
  font-weight: 400;
  font-size: 11px;
}

.period {
  &-buttons {
    display: flex;
    gap: 5px;
  }
}

.chart {
  margin-left: -10px;
}
</style>
