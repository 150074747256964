import auth from "@/auth";
import StateControllerBase from "@/store/stateControllerBase";
import { setLoadingState } from "@/store/setLoadingState";

import BlogPageController from "@/api/blogPageController";

const blogPageController = new BlogPageController();

class Actions extends StateControllerBase {
  setDataLoading({ commit }, dataLoading) {
    commit("setDataLoading", dataLoading);
  }

  @setLoadingState
  async setBlogPagesPage({ commit }, page) {
    commit("setBlogPagesPage", page);
  }

  @setLoadingState
  async getBlogPages({ commit, getters }, authorId) {
    let data = await blogPageController.getBlogPages({ pageId: getters.page, authorId: authorId ? authorId : auth.user().id });
    commit("setBlogPages", data.data);
    commit("setBlogPagesCount", data.totalItems);
  }

  @setLoadingState
  async patchBlogPage({ dispatch }, body) {
    await blogPageController.patchBlogPage(body);
    await dispatch("getBlogPages");
  }

  @setLoadingState
  async removeBlogPage({ dispatch }, id) {
    await blogPageController.removeBlogPage(id);
    await dispatch("getBlogPages");
  }

  @setLoadingState
  async postBlogPage({ dispatch }, body) {
    await blogPageController.postBlogPage(body);
    await dispatch("getBlogPages");
  }
}


export default (new Actions).asPlainObject();