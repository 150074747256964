<template>
  <div
    id="projects-wrap"
    class="projects-wrap"
  >
    <div class="projects">
      <CapitalCard
        v-for="proj in projects"
        :key="proj.id"
        :project="proj"
        route-path="outer-project"
        route-name="outer project"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import CapitalCard from "@/components/capital/CapitalCard";
import { convertCapital } from "@/components/capital/capitalHelper";

export default {
  name: "DashboardProjects",

  components: {
    CapitalCard
  },

  data() {
    return {
      projects: [],
      filtersFlag: {},
      selectedCategoryId: null,
      auCapitals: [],
      page: 1,
      totalItems: 1,
      loading: true,
      type: "All",
      screenWidth: window.innerWidth
    };
  },

  computed: {
    ...mapGetters({
      sectionCapital: "sectionCapital"
    })
  },

  async mounted() {
    await this.getSectionCapital();
    await this.getCapital();
    for (let i = 0; i < this.sectionCapital; ++i) {
      let item = {};
      item[this.sectionCapital[i].id] = false;
      this.filtersFlag.push(item);
    }
    this.loading = false;

    this.windowResize();
  },

  methods: {
    ...mapActions({
      getSectionCapital: "getSectionCapital",
      getAllAuCapitalsPagination: "capitals/getAllAuCapitalsPagination",
      getInvestmentsAuCapitals: "capitals/getInvestmentsAuCapitals",
      sendCapital: "sendCapital"
    }),

    windowResize() {
      const element = document.getElementById("projects-wrap");
      window.parent.postMessage({ height: element.offsetHeight + 4 + "px" }, "*");
    },

    async getCapital() {
      try {
        let resp = null;
        resp = await this.getAllAuCapitalsPagination({
          page: this.page,
          order: "desc"
        });

        this.auCapitals = resp.data;
        this.totalItems = resp.totalItems;

        const capitalData = this.auCapitals;
        this.projects = await this.convertAuCapitals(capitalData);
      }
      catch (e) {
        console.log("error", e);
      }
    },

    async convertAuCapitals(auCapitals) {
      let projects = [];
      for (const item of auCapitals) {
        const project = convertCapital(item, this.sectionCapital);
        projects.push(project);
      }

      return projects;
    }
  }
};
</script>

<style lang="scss" scoped>
.projects-wrap {
  background: var(--main-color);
  padding: 20px;
  height: auto;

  .projects {
    display: flex;
    gap: 25px;
    flex-wrap: wrap;
  }

  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-bottom: 4px;
    font-size: 22px;

    a {
      padding-top: 4px;
      font-size: 11px;
      color: var(--color-50);
      text-decoration: none;
    }
  }
}
</style>
