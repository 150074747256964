import en from "./locales/en.json";
import ru from "./locales/ru.json";
import uz from "./locales/uz.json";
import { createI18n } from "vue-i18n";

let lang = null;
let langCookie = document.cookie.match(/lang=[a-zA-Z0-9]{1,}/);

if (langCookie) {
  lang = langCookie[0].slice(5);
}
else {
  lang = process.env.VUE_APP_DEFAULT_LOCALE ? process.env.VUE_APP_DEFAULT_LOCALE : "en";
}

const i18n = createI18n({
  locale: lang,
  fallbackLocale: lang,
  globalInjection: true,
  messages: {
    ru, en, uz
  }
});

export default i18n;
