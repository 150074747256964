import StateControllerBase from "@/store/stateControllerBase";
import { setLoadingState } from "@/store/setLoadingState";

import WithdrawalController from "@/api/withdrawalController";

const withdrawalController = new WithdrawalController();

class Actions extends StateControllerBase {
  setDataLoading({ commit }, dataLoading) {
    commit("setDataLoading", dataLoading);
  }

  @setLoadingState
  async getWithdrawalRequests({ commit }, params) {
    const resp = await withdrawalController.getWithdrawalRequests(params);
    commit("setWithdraws", resp.data);
    commit("setTotalWithdraws", resp.totalItems);
  }

  async sendMailConfirmation(_context, id) {
    return await withdrawalController.sendMailConfirmation(id);
  }

  async confirmMailCode(_context, params) {
    return await withdrawalController.confirmMailCode(params);
  }
}

export default (new Actions).asPlainObject();