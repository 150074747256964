<template>
  <div>
    <h1 class="title">
      {{ $t('auCapitals.investers.title') }}
    </h1>
    <div class="header">
      {{ $t('auCapitals.investers.subTitleOne') }}
    </div>

    <div class="container-fluid">
      <div class="row promises fs13">
        <div class="promise col-md-12 col-sm-12">
          <AuIcon
            icon="check_green"
            class="promise__icon"
          />
          <div class="promise__text first">
            {{ $t('auCapitals.investers.itemTextOne') }}
          </div>
        </div>
        <!-- <div class="promise col-md-6 col-sm-12 order-md-2 order-sm-4">
          <AuIcon
            icon="check_green"
            class="promise__icon"
          />
          <div class="promise__text second">
            До момента размещения проекта на платформе он проходит тщательный консалтинговый и юридический анализ
          </div>
        </div> -->
        <div class="promise col-md-12 col-sm-12 order-md-3 order-sm-2">
          <AuIcon
            icon="check_green"
            class="promise__icon"
          />
          <div class="promise__text third">
            {{ $t('auCapitals.investers.itemTextTwo') }}
          </div>
        </div>
        <!-- <div class="promise col-md-6 col-sm-12 order-md-4 order-sm-5">
          <AuIcon
            icon="check_green"
            class="promise__icon"
          />
          <div class="promise__text fourth">
            Мы проводим тщательный отбор проектов и осуществляем контроль использования ими инвестиционных средств
          </div>
        </div> -->
        <div class="promise col-md-12 col-sm-12 order-md-5 order-sm-3">
          <AuIcon
            icon="check_green"
            class="promise__icon"
          />
          <div class="promise__text fifth">
            {{ $t('auCapitals.investers.itemTextThree') }}
          </div>
        </div>
        <!-- <div class="promise col-md-6 col-sm-12 order-md-last order-sm-last">
          <AuIcon
            icon="check_green"
            class="promise__icon"
          />
          <div class="promise__text sixth">
            Карточка проекта содержит десятки основных параметров, которые структурированы нашими специалистами в удобный формат. Теперь принять решение об инвестировании в проект просто как никогда.
          </div>
        </div> -->
      </div>
    </div>

    <div class="header second">
      {{ $t('auCapitals.investers.subTitleTwo') }}
    </div>

    <div class="container-fluid">
      <div class="row tokens">
        <Swiper
          class="swiper"
          :navigation="true"
          :slides-per-view="1"
          :hash-navigation="{
            watchState: true,
          }"
          :space-between="20"
          :modules="modules"
          :breakpoints="{
            768: {
              slidesPerView: 2,
              spaceBetween: 10
            },
            992: {
              slidesPerView: 4,
              spaceBetween: 20
            }
          }"
        >
          <SwiperSlide
            v-for="owner of auOwners"
            :key="owner.id"
          >
            <div class="card">
              <div class="card__header">
                {{ $t('auCapitals.investers.tokenAdress') }} {{ owner.auToken.mnemonicEng }}:
              </div>

              <div class="card__body">
                {{ owner.auToken.createdAddress }}
              </div>

              <AuButton
                bordered
                center
              >
                <div class="btn_metamask">
                  <div
                    class="metamask"
                    @click="metamaskAddEthereumCoin(owner.auToken)"
                  />
                  <div @click="metamaskAddEthereumCoin(owner.auToken)">
                    {{ $t('auCapitals.investers.tokenAdd') }}
                  </div>
                </div>
              </AuButton>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  </div>
</template>

<script>
import MetaMask from "@/mixins/MetaMask";
import { mapGetters } from "vuex";
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";

export default {

  components: {
    Swiper,
    SwiperSlide
  },
  mixins: [MetaMask],

  setup() {
    return {
      modules: [Navigation]
    };
  },

  computed: {
    ...mapGetters({
      auOwners: "auOwners"
    })
  }
};
</script>

<style lang="scss" scoped>

.title {
  font-weight: 600;
  font-size: 24px;
}
.header {
  margin-top: 50px;
  &.second {
    margin-top: 100px;
  }
}

.promises {
  margin-top: 40px;
  row-gap: 30px;
}

.promise {
  display: flex;
  gap: 13px;
  line-height: 20px;

  &__icon {
    height: 24px;
    width: 24px;
  }
  &__text {
    max-width: 450px;
    &.first {
      max-width: 394px;
    }
    &.third {
      max-width: 454px;
    }
    &.fifth {
      max-width: 407px;
    }
  }
}

.tokens {
  margin-top: 40px;
  row-gap: 20px;
}
.container-fluid {
  padding: 0;
}
.card {
  padding: 15px;
  font-size: 13px;
  height: 100%;
  border-radius: 12px;

  &__body {
    color: var(--color-50);
    margin: 15px 15px 15px 0;
  }
}
.btn_metamask {
  display: flex;
  justify-content: center;
  align-items: center;
}
.metamask {
  background: url(../assets/img/metamask.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 15px;
  margin-right: 10px;
}
</style>