<template>
  <div class="security">
    <AuBreadcrumb
      parent-name="Profile"
      parent-path="user"
    />
    <EthAddresses />
  </div>
</template>

<script>
import EthAddresses from "@/panels/components/EthAddresses.vue";

export default {
  name: "Security",
  components: {
    EthAddresses
  }
};
</script>
