const setLoadingState = (_target, _property, descriptor) => {
  let actucalFunction = descriptor.value;

  descriptor.value = async (context, ...args) => {
    context.dispatch("setDataLoading", true);

    try {
      return await actucalFunction.call(this, context, ...args);
    }
    finally {
      context.dispatch("setDataLoading", false);
    }
  };
};

export { setLoadingState };
