<template>
  <div class="countdown">
    <div>
      <div class="frame numbers">
        {{ days < 10 ? `0${days}` : days }}
      </div>
      <div class="frame legend">
        {{ legend?.day }}
      </div>
    </div>
    <div>
      <div class="frame numbers">
        {{ hours < 10 ? `0${hours}` : hours }}
      </div>
      <div class="frame legend">
        {{ legend?.hour }}
      </div>
    </div>
    <div>
      <div class="frame numbers">
        {{ minutes < 10 ? `0${minutes}` : minutes }}
      </div>
      <div class="frame legend">
        {{ legend?.minute }}
      </div>
    </div>
    <div>
      <div class="frame numbers">
        {{ seconds < 10 ? `0${seconds}` : seconds }}
      </div>
      <div class="frame legend">
        {{ legend?.second }}
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "AuCountdown",
  props: {
    finishDate: {
      type: String,
      default: ""
    },
    legend: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      totalSeconds: null,
      interval: null,
      days: null,
      hours: null,
      minutes: null,
      seconds: null
    };
  },
  mounted() {
    this.totalSeconds = moment(this.finishDate).diff(moment(), "seconds");
    this.calcTick();
    this.interval = setInterval(this.calcTick, 1000);
  },
  unmounted() {
    clearInterval(this.interval);
  },
  methods: {
    calcCountdown() {
      let totalSeconds = this.totalSeconds;
      if (totalSeconds < 0) {
        this.days = 0;
        this.hours = 0;
        this.minutes = 0;
        this.seconds = 0;
        return;
      }
      this.days = (totalSeconds - (totalSeconds % 86400)) / 86400;

      totalSeconds = totalSeconds - (this.days * 86400);
      this.hours = (totalSeconds - (totalSeconds % 3600)) / 3600;

      totalSeconds = totalSeconds - (this.hours * 3600);
      this.minutes = (totalSeconds - (totalSeconds % 60)) / 60;

      this.seconds = totalSeconds - (this.minutes * 60);
    },
    calcTick() {
      this.totalSeconds--;
      this.calcCountdown();
    }
  }
};
</script>

<style scoped lang="scss">
.countdown {
  display: flex;
  .frame {
    display: flex;
    justify-content: center;
    width: 50px;
  }
  .numbers{
    font-weight: 500;
    font-size: 20px;
    border: 1px solid var(--scrollbar-color);
    border-radius: 5px;
    margin-right: 10px;
  }
  .legend{
    font-weight: 400;
    font-size: 10px;
    overflow: hidden;
  }
}
</style>