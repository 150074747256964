<template>
  <div>
    <div class="filters">
      <div class="col-xl d-flex order-xl-1 justify-content-between justify-content-xl-end my-2 my-xl-0 me-3">
        <div class="me-2">
          <AuInput
            icon="searchc"
            :placeholder="$t('marketplace.search')"
            border-radius="40"
            @update:modelValue="value => name = value"
          />
        </div>
      </div>
      <div class="selectors">
        <AuSelect
          clearable
          class="selector"
          :value="selectedSection"
          :placeholder="$t('marketplace.selectCategory')"
          :options="sectionMarketOptions"
          border-radius="40"
          @input="value => selectedSection = value"
        />
        <AuSelect
          class="selector"
          :value="selectedCountry"
          :placeholder="$t('marketplace.selectCountry')"
          :options="countryOptions"
          border-radius="40"
          @input="value => selectedCountry = value"
        />
        <AuSelect
          class="selector"
          :value="selectedCurrency"
          :placeholder="$t('marketplace.selectCurrency')"
          :options="tokensFiatOptions"
          border-radius="40"
          @input="value => selectedCurrency = value"
        />
      </div>

      <div class="filtration_with-text">
        <AuButton
          icon="filterc"
          bordered
        >
          <div class="filtration__text">
            Фильтрация
          </div>
        </AuButton>
      </div>

      <div class="filtration_without-text">
        <AuButton
          icon="filterc"
          bordered
        />
      </div>
    </div>

    <div class="products">
      <MarketCard
        v-for="product in products"
        :key="product.id"
        :product="product"
      />
    </div>

    <ProgressLoader
      v-if="loading"
      mode="indeterminate"
      color="#5AC396"
      size="150px"
    />

    <div class="footer">
      <!-- <div class="footer-button">
        <AuButton
          v-if="page > 1"
          height="36px"
          width="auto"
          bordered
          center
          @click="prevPage"
        >
          Предыдущая страница
        </AuButton>
      </div>

      <div>
        Страница: {{ page }}
      </div>

      <div class="footer-button">
        <AuButton
          v-if="nfts.length > 0"
          height="36px"
          width="auto"
          bordered
          center
          @click="nextPage"
        >
          Следующая страница
        </AuButton>
      </div> -->
      <AuPagination
        v-if="totalItems > 30"
        :total-items="totalItems"
        @pageChanged="pageChanged"
      />
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import moment from "moment";

import MarketCard from "@/components/MarketCard";
import ProgressLoader from "@/loaders/progress-bar";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Marketplace",

  components: {
    MarketCard,
    ProgressLoader
  },

  data() {
    return {
      name: "",
      page: 1,
      totalItems: 1,
      loading: true,
      selectedSection: null,
      selectedCountry: null,
      selectedCurrency: null,
      nfts: []
    };
  },

  computed: {
    ...mapGetters({
      sectionMarket: "sectionMarket",
      language: "language",
      country: "country",
      companies: "companies",
      tokensFiat: "tokensFiat",
      // nfts: "marketplace/nfts",
      nftOwners: "nftOwners"
    }),

    sectionMarketOptions() {
      return _.map(this.sectionMarket, sm => ({ display: sm.name, value: sm.id }));
    },

    countryOptions() {
      return _.map(this.country, c => ({ display: c.name, value: c.code }));
    },

    tokensFiatOptions() {
      return _.map(this.tokensFiat, tf => ({ display: tf.mnemonicEng, value: tf.id }));
    },

    products() {
      return _.map(this.nfts, item => {
        const company = _.find(this.companies, { id: Number(_.split(item.company, "/")[3]) });

        let owners = _.filter(this.nftOwners, owner => {
          return _.split(owner.nft, "/")[3] == item.id;
        });

        return {
          id: item.id,
          price: item.price,
          type: item.section.name,
          name: item.productName,
          owner: company?.nameRus,
          date: moment(item.createdAt).format("DD.MM.YYYY"),
          decimals: item.tokenQuote?.decimals,
          indent: item.tokenQuote?.indent,
          mnemonic: item.tokenQuote?.mnemonicEng,
          amount: item.amount,
          image: item.image,
          sectionId: item?.section?.id === undefined ? null : item.section.id,
          countryCode: company?.country?.countryCode === undefined ? null : company.country.countryCode,
          countryName: company?.country?.name === undefined ? null : company.country.name,
          currencyId: item?.tokenQuote?.id === undefined ? null : item?.tokenQuote.id,
          rest: _.chain(owners).map(owner => Number(owner.amount)).sum().value(),
          isMultiToken: item?.isMultiToken
        };
      });
    }
  },

  watch: {
    async selectedCountry() {
      await this.getNftsPage();
    },

    async selectedSection() {
      await this.getNftsPage();
    },

    async selectedCurrency() {
      await this.getNftsPage();
    },

    async name() {
      await this.getNftsPage();
    }
  },

  async created() {
    await this.getNftsPage();
    await this.getNftOwners();
    if (_.isEmpty(this.country)) {
      await this.getCountry();
    }
    if (_.isEmpty(this.sectionMarket)) {
      await this.getSectionMarket();
    }
    if (_.isEmpty(this.companies)) {
      await this.getCompanies();
    }
  },
  async mounted() {
    await this.getCountry();
    await this.getSectionMarket();
  },

  methods: {
    ...mapActions({
      getCountry: "getCountry",
      defaultRequest: "defaultRequest",
      getNfts: "marketplace/getNfts",
      getSectionMarket: "getSectionMarket",
      getCompanies: "getCompanies",
      getNftOwners: "getNftOwners"
    }),

    async getNftsPage() {
      this.loading = true;
      const resp = await this.getNfts({
        page: this.page,
        countryCode: this.selectedCountry,
        sectionId: this.selectedSection,
        tokenQuoteId: this.selectedCurrency,
        name: this.name,
        inSale: true,
        status: "issued"
      });
      this.nfts = resp.data;
      this.totalItems = resp.totalItems;

      this.loading = false;
    },

    async pageChanged(actualPage) {
      this.page = actualPage;
      await this.getNftsPage();
    },

    // async prevPage() {
    //   this.page--;
    //   await this.getNftsPage();
    // },

    // async nextPage() {
    //   this.page++;
    //   await this.getNftsPage();
    // },

    async getInfo(value) {
      try {
        return await this.defaultRequest(value);
      }
      catch (e) {
        console.log("error", e);
      }
    }
  }
};
</script>

<style scoped lang="scss">
.products {
  display: flex;
  gap: 25px;
  margin-top: 45px;
  flex-wrap: wrap;
}

.selectors
{
  display: flex;
  gap: 6px;

  @media screen and (max-width: 576px) {
    display: none;
  }
}

.selector {
  min-width: 220px;
}

.filters {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.filtration {
  &_with-text {
    display: none;

    @media screen and (max-width: 576px) and (min-width: 360px) {
      display: block;
    }
  }

  &_without-text {
    display: none;

    @media screen and (max-width: 360px) {
      display: block;
    }
  }
}

.footer {
  display: flex;
  justify-content: space-between;
  border-top: 1px dashed var(--color-70);

  &-button {
    min-width: 175px;
  }
}
</style>
