<template>
  <div class="reports-page-wrapper">
    <div class="card report-order mb-3">
      <div class="report-order-header report-order-header_bordered">
        <span> {{ $t("reports.label") }} </span>
      </div>
      <p class="report-order-subtitle">
        {{ $t("reports.pickCompanyText") }}
      </p>
      <div class="filter-line">
        <AuInput
          class="search"
          icon="search"
          border-radius="40"
          :placeholder="$t('reports.companyPlaceholder')"
        />

        <div class="search-period">
          <AuSwitchButton
            width="62px"
          >
            1{{ $t("reports.oneMonth") }}
          </AuSwitchButton>
          <AuSwitchButton
            width="62px"
          >
            4{{ $t("reports.fourMonths") }}
          </AuSwitchButton>
          <AuSwitchButton
            width="62px"
          >
            6{{ $t("reports.sixMonths") }}
          </AuSwitchButton>
          <AuSwitchButton
            width="62px"
          >
            1{{ $t("reports.oneYear") }}
          </AuSwitchButton>
          <AuButton
            width="62px"
            heigth="44px"
            center
            bordered
            border-radius="40"
            icon="more"
          />
        </div>

        <div>
          <AuButton
            bordered
            width="140px"
            heigth="44px"
            border-color="var(--color-50)"
            center
            border-radius="40"
          >
            <span class="button-text">
              {{ $t("reports.orderReport") }}
            </span>
          </AuButton>
        </div>
      </div>
    </div>
    <div class="reports-info card mb-3">
      <div class="report-order-header">
        <span> {{ $t("reports.tableLabel") }} </span>
      </div>

      <AuTable
        class="reports-table"
        :columns="columns"
        :items="edditedReports"
        :icons="companyIcons"
        :action-column="actionColumn"
      />
      <div class="reports-accordion">
        <AuAccordion
          v-for="(report, index) in edditedReports"
          :key="report"
          :last-child="(index == edditedReports.length - 1)"
          :header-action="actionColumn"
        >
          <template #title>
            <span class="company-header">{{ ( index + 1 ) }}</span>
          </template>
          <template #content>
            <div class="company-info container-fluid">
              <div class="row">
                <div class="col company-info-title">
                  <span>{{ $t("reports.firstColumn") }}</span>
                </div>
                <div class="col">
                  <img
                    class="company-icon"
                    :src="companyIcons[index]"
                    :alt="report.companyName"
                  >
                  <span>{{ report.companyName }}</span>
                </div>
              </div>
              <div class="row">
                <div class="col company-info-title">
                  <span>{{ $t("reports.secondColumn") }}</span>
                </div>
                <div class="col">
                  <span>{{ report.date }}</span>
                </div>
              </div>
              <div class="row">
                <div class="col company-info-title">
                  <span>{{ $t("reports.thirdColumn") }}</span>
                </div>
                <div class="col">
                  <span>{{ report.period }}</span>
                </div>
              </div>
            </div>
          </template>
        </AuAccordion>
      </div>
    </div>
    <div class="card">
      <ChangeChart />
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import ChangeChart from "@/components/ChangeChart";
import { mapActions, mapGetters } from "vuex";
export default {
  components: { ChangeChart },
  data() {
    return {
      columns: [{ name: this.$t("reports.firstColumn"), align: "start" },
        { name: this.$t("reports.secondColumn") },
        { name: this.$t("reports.thirdColumn"), align: "center" }],
      actionColumn: { label: this.$t("reports.fourthColumn"), icon: "paper_download", align: "center" }
    };
  },

  computed: {
    ...mapGetters({
      reports: "reports",
      tokens: "tokens",
      defaultTokenId: "defaultToken/defaultTokenId"
    }),

    companyIcons() {
      return _.map(_.cloneDeep(this.reports), company => {
        company.icon = "/svg/companies/" + _.get(company, "icon", "s_logo") + ".svg";
        return company.icon;
      });
    },

    edditedReports() {
      return _.map(_.cloneDeep(this.reports), report => {
        let newReport = {
          companyName: report.companyName,
          date: report.date,
          period: report.period
        };
        return newReport;
      });
    }
  },
  async mounted() {
    for (const token of this.tokens) {
      await this.getTokenHistory({ defaultTokenId: this.defaultTokenId, tokenId: token.id });
    }
  },
  methods: {
    ...mapActions({
      getTokenHistory: "getTokenHistory"
    })
  }
};
</script>

<style lang="scss" scoped>
.filter-line {
  display: flex;
  justify-content: space-between;
  @media screen and (max-width:640px) {
    flex-direction: column;
    gap: 10px;
  }
}

.reports {
  &-info {
    padding: 20px 20px 0 20px;;
    .title {
      font-weight: 600;
      font-size: 18px;
      color: var(--text-color);
      margin-bottom: 35px;
    }
  }

  &-table {
    @media screen and (max-width:520px) {
      display: none;
    }
  }

  &-accordion {
    display: none;

    @media screen and (max-width:520px) {
      display: block;
    }
  }
}
.company {
  &-header {
    color: var(--text-color);
    font-size: 12px;
  }
  &-info {
    color: var(--text-color);
    display: flex;
    flex-direction: column;
    gap: 20px;
    &-title {
      color: var(--color-60)
    }
    .row {
      font-weight: 450;
      font-size: 12px;
      .col:last-child {
        text-align: end;
      }
    }
  }
  &-icon {
    width: 26px;
    height: 26px;
    margin-right: 5px;
  }
}
.row > * {
  padding: 0;
  gap: 4px;
}

.report-order {
  padding: 20px;
  .row {
    gap: 20px;
  }
  &-header {
    padding-bottom: 23px;

    &_bordered {
      border-bottom: 1px solid var(--color-70);
    }

    span {
      font-weight: 600;
      font-size: 18px;
    }
  }
  &-subtitle {
    font-size: 14px;
    padding-top: 30px;
    margin-bottom: 25px;
    @media screen and (max-width: 768px){
      font-size: 13px;
    }
  }
}
.search{
  width: 230px;

  border-radius: 18px;

  &-period {
    display: flex;
    gap: 5px;
  }
}
</style>