<template>
  <div
    class="modal_screen"
    :class="{modal_light: isLight, modal_dark: !isLight}"
  >
    <div class="dialog_wrap">
      <header>
        {{ header }}
      </header>
      <main>
        {{ body }}
      </main>
    </div>
  </div>
</template>

<script>
export default {
  name: "AuDialog",
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    header: {
      type: String,
      default: "300px"
    },
    body: {
      type: String,
      default: "auto"
    }
  },
  emits: ["update:modelValue"],
  data() {
    return {
      startAnimation: false,
      onForm: false
    };
  },
  computed: {
    isLight() {
      return this.$store.getters.isLightTheme;
    }
  },
  methods: {
    hideModal() {
      if (!this.onForm) {
        this.closeModal();
      }
    },
    closeModal() {
      this.startAnimation = false;
      this.$emit("closeModal");
      this.$emit("update:modelValue", false);
    }
  }
};
</script>

<style scoped lang="scss">
.invisible {
  display: none;
}
.modal_screen {
  position: fixed;
  z-index: 9998;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
}
.modal_light{
  background: rgba(0, 0, 0, 0.5);
}
.modal_dark{
  background: rgba(21, 21, 21, 0.9);
}
.dialog_box{
  border: 2px solid var(--color-70);
  background: var(--card-color);
  padding: 10px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0;
  transition: margin-top 150ms linear;
  overflow: auto;
}
.dialog_wrap{
  overflow: hidden auto;
  padding-bottom: 100px;

  font-size: 18px;

  header {
    text-align: center;
    margin-bottom: 10px;
  }
}
.dialog_position {
  margin-top: 110px;
}
.close_position {
  display: flex;
  justify-content: space-between;
}
.close_size {
  font-size: 8px;
}

.close-wrapper {
  margin-right: 12px;
}
</style>