<template>
  <div class="au-accordion">
    <div
      :is-open="isOpen"
      class="au-accordion-header"
    >
      <div>
        <slot name="title" />
        <AuCheckbox
          v-if="checkboxes"
          class="au-accordion-checkbox"
        />
      </div>
      <AuIcon
        v-if="headerAction"
        :icon="headerAction.icon"
        @click="action()"
      />
      <AuIcon
        v-else
        icon="chevron_down"
        class="au-accordion-toggler"
        :aria-expanded="isOpen"
        :aria-controls="`collapse-${uid}`"
        @click="toggleAccordion()"
      />
    </div>

    <div
      v-show="isOpen"
      :id="`collapse-${uid}`"
      class="au-accordion-body"
    >
      <slot name="content" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    checkboxes: {
      type: Boolean,
      default: false
    },
    headerAction: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      isOpen: true,
      uid: ""
    };
  },

  mounted() {
    this.uid = this.randomString();
  },

  methods: {
    toggleAccordion() {
      this.isOpen = !this.isOpen;
    },

    randomString() {
      let rnd = "";
      while (rnd.length < 20) {
        rnd += Math.random().toString(36).substring(2);
      }
      return rnd.substring(0, 20);
    },

    action() {
      console.log("action!");
    }
  }
};
</script>

<style lang="scss" scoped>
  .au-accordion {
    &-header {
      display: flex;
      justify-content: space-between;
      padding: 9px 0;
      border-top: 1px solid var(--color-70);
      border-bottom: 1px dashed var(--color-70);
      border-left-width: 0px;
      border-right-width: 0px;
      &[is-open="false"] {
        border-bottom-width: 0px;
      }
    }
    &-checkbox {
      margin-left: 10px;
    }
    &-body {
      padding: 22px 0 50px 0;
    }
    &-toggler {
      &[aria-expanded="true"] {
        transform: rotate(180deg);
      }
    }

    &[last-child="true"] {
      .au-accordion-header {
        border-bottom-width: 1px;
      }
    }
  }
  .col {
  padding: 0;
  font-size: 12px;
    &:not(.info-title) {
      text-align: end;
    }
  }

</style>