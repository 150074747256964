<template>
  <input
    v-model="checked"
    type="checkbox"
    class="check"
    @change="$emit('change', checked)"
  >
</template>

<script>
export default {
  emits: ["change"],

  data() {
    return {
      checked: false
    };
  }

};
</script>

<style lang="scss" scoped>
.check {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  vertical-align: middle;
  border: 1px solid var(--text-color);
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  position: relative;
  &:checked {
    &::before {
      content: "•";
      position: absolute;
      top: calc(50% - 19.5px / 2);
      right: calc(50% - 5.81px / 2);
      color: var(--text-color);
    }
  }
}
</style>