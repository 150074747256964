<template>
  <div>
    <div class="nc-header">
      {{ $t("newsCapital.header") }}
    </div>
    <div class="d-flex justify-content-between nc-filter-indent">
      <div class="d-flex">
        <div class="nc-switch-indent">
          <AuSwitchButton
            width="66px"
            @click="isAll = true"
          >
            {{ $t('newsCapital.all') }}
          </AuSwitchButton>
        </div>
        <div class="nc-select-indent">
          <AuSelect
            border-radius="40"
            :placeholder="$t('newsCapital.category')"
          />
        </div>
        <div>
          <AuSelect
            border-radius="40"
            :placeholder="$t('newsCapital.year')"
          />
        </div>
      </div>
      <div>
        <AuInput
          icon="searchc"
          border-radius="40"
          :placeholder="$t('newsCapital.search')"
        />
      </div>
    </div>
    <div class="nc-news-indent d-flex flex-wrap">
      <div
        v-for="news of blogs"
        :key="news.id"
        class="nc-news-card"
      >
        <img
          :src="news.image"
          alt=""
          class="nc-image"
        >
        <div>
          <div class="nc-date-pos">
            <div class="nc-status">
              {{ news.status }}
            </div>
            <div class="nc-date">
              {{ news.date }}
            </div>
          </div>
          <div class="nc-text-pos">
            <div class="nc-title nc-text">
              {{ news.title }}
            </div>
            <div class="nc-subtitle nc-text">
              {{ news.subtitle }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import moment from "moment";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "NewsCapital",
  data() {
    return {
      isAll: true
    };
  },
  computed: {
    ...mapGetters({
      blogPages: "blogPages"
    }),
    blogs() {
      return _.map(this.blogPages.data, blog => ({
        id: blog.id,
        image: blog.imageFile,
        title: blog.title,
        subtitle: blog.subtitle,
        status: blog.isDraft ? this.$t("newsCapital.statusDraft") : this.$t("newsCapital.statusBlog"),
        date: moment(blog.dateForNews).format("DD.MM.YYYY")
      }));
    }
  },
  async mounted() {
    if (_.isEmpty(this.blogPages)) {
      await this.getBlogPages();
    }
    console.log(this.blogPages);
  },
  methods: {
    ...mapActions({
      getBlogPages: "getBlogPages"
    })
  }
};
</script>

<style scoped lang="scss">
.nc-header{
  font-weight: 600;
  font-size: 24px;
}
.nc-filter-indent{
  margin-top: 30px;
}
.nc-news-indent{
  margin-top: 35px;
}
.nc-switch-indent {
  margin-right: 40px;
}
.nc-select-indent {
  margin-right: 10px;
}
.nc-news-card{
  width: 390px;
  height: 180px;
  margin-right: 40px;
  margin-bottom: 35px;
  position: relative;
  @media screen and (max-width: 640px)  {
    width: 270px;
    margin-right: 20px;
    margin-bottom: 30px;
  }
}
.nc-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 16px;
}
.nc-text-pos {
  position: absolute;
  top: 102px;
  left: 15px;
}
.nc-title{
  font-weight: 450;
  font-size: 18px;
  color: white;
}
.nc-subtitle{
  font-weight: 400;
  font-size: 14px;
  color: #B4B4B4;
}
.nc-text{
  background: rgba(0, 0, 0, 0.7);
  padding: 5px;
  width: 350px;
  height: 30px;
  overflow: hidden;
  @media screen and (max-width: 640px) {
    width: 230px;
  }
}
.nc-date-pos{
  position: absolute;
  top: 10px;
  right: 10px;
}
.nc-status{
  border: 1px solid var(--color-70);
  border-radius: 8px;
  font-weight: 450;
  font-size: 12px;
  background: white;
  display: flex;
  justify-content: center;
  margin-bottom: 5px;
  color: black;
}
.nc-date{
  font-weight: 450;
  font-size: 12px;
  color: #B4B4B4;
  background: rgba(0, 0, 0, 0.7);
  padding: 2px;
}
</style>