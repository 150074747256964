<template>
  <div class="mark-wrapper">
    <svg
      ref="successMark"
      viewbox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M 18 32.34 l -8.34 -8.34 -2.83 2.83 11.17 11.17 24 -24 -2.83 -2.83 z"
          stroke="#2aba7c"
          fill="transparent"
        />
      </g>
    </svg>
  </div>
</template>

<script setup>
import { ref, watch } from "vue";
// eslint-disable-next-line no-undef
const props = defineProps({
  showAnimate: {
    type: Boolean,
    default: false
  }
});

const successMark = ref(null);

watch(
  () => props.showAnimate,
  value => {
    if (value && !successMark.value.classList.contains("animate")) {
      successMark.value.classList.add("animate");

      setTimeout(function () {
        successMark.value.classList.remove("animate");
      }, 1700);
    }
  }
);
</script>

<style lang="scss" scoped>
mark-wrapper {
  width: 48px;
  height: 44px;
}
svg {
  width: 48px;
  height: 44px;
  @media screen and (max-width: 800px){
    height: 25px;
  }
}
g {
  @media screen and (max-width: 800px){
    scale: 0.5;
  }
}

path {
  stroke-dasharray: 99.47578430175781;
  stroke-dashoffset: -99.47578430175781;
  fill: transparent;
}

svg.animate path {
  animation: 1.7s ease forwards draw;
  opacity: 1;
}

@keyframes draw {
  0% {
    opacity: 1;
    stroke-dashoffset: -99.47578430175781;
    fill: transparent;
    transform: translateY(0);
  }

  35% {
    stroke-dashoffset: 0;
    fill: transparent;
  }

  60% {
    fill: #2aba7c;
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    fill: #2aba7c;
    stroke-dashoffset: 0;
    opacity: 0;
    transform: translateY(-10px);
  }
}
</style>
