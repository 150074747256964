<template>
  <div class="footer">
    <div
      v-if="divider"
      class="divider"
    />
    <div class="info">
      <div class="copyright">
        © 2022 - {{ new Date().getFullYear() }} {{ titleFooter }}
      </div>

      <div class="links">
        <div
          v-if="socialLinks"
          class="media"
        >
          <AuIcon
            v-if="discord !='none'"
            icon="discord"
            class="btn"
            is-link
            :href="discord"
            target="_blank"
          />
          <AuIcon
            v-if="language == 'ru' && vk != 'none'"
            icon="vk"
            class="btn"
            is-link
            :href="vk"
            target="_blank"
          />
          <AuIcon
            v-if="youtube != 'none'"
            icon="youtube"
            class="btn"
            is-link
            :href="youtube"
            target="_blank"
          />
          <AuIcon
            v-if="telegram != 'none'"
            icon="telegram"
            class="btn"
            is-link
            :href="telegram"
            target="_blank"
          />
          <AuIcon
            v-if="twitter != 'none'"
            icon="twitter"
            class="btn"
            is-link
            :href="twitter"
            target="_blank"
          />
          <AuIcon
            v-if="language == 'ru' && dzen != 'none'"
            icon="dzen"
            class="btn"
            is-link
            :href="dzen"
            target="_blank"
          />
          <AuIcon
            v-if="fb != 'none'"
            icon="facebook"
            class="btn"
            is-link
            :href="fb"
            target="_blank"
          />
        </div>

        <div class="policy">
          <a
            :href="hrefPolicy"
            target="_blank"
          >{{ $t("footer.privacyPolicy") }}</a>
          <a
            :href="hrefAgreement"
            target="_blank"
          >{{ $t("footer.termsOfUse") }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "AuDialog",
  props: {
    divider: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      language: "language"
    }),
    titleFooter() {
      return process.env.VUE_APP_TITLE_FOOTER || "«Autentic»";
    },
    hrefPolicy() {
      return process.env.VUE_APP_POLICY || "/privacy-policy/";
    },
    hrefAgreement() {
      return process.env.VUE_APP_AGREEMENT || "/user-agreement/";
    },
    socialLinks() {
      return process.env.VUE_APP_SOCIAL_LINKS === "true" || "true";
    },
    discord() {
      return process.env.VUE_APP_SOCIAL_DISCORD || "https://discord.gg/8f854ZmVgK";
    },
    vk() {
      return process.env.VUE_APP_SOCIAL_VK || "https://vk.com/public217319173";
    },
    youtube() {
      return process.env.VUE_APP_SOCIAL_YOUTUBE || "https://www.youtube.com/@Autentic-Capital";
    },
    telegram() {
      return process.env.VUE_APP_SOCIAL_TELEGRAM || "https://t.me/AUT_Community_EN";
    },
    twitter() {
      return process.env.VUE_APP_SOCIAL_TWITTER || "https://twitter.com/AutCapital";
    },
    dzen() {
      return process.env.VUE_APP_SOCIAL_DZEN || "https://dzen.ru/autenticapital";
    },
    fb() {
      return process.env.VUE_APP_SOCIAL_FB ?? "none";
    }
  }
};
</script>

<style scoped lang="scss">
.footer {
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  gap: 10px;
}

.copyright {
  display: flex;
  justify-content: flex-start;
  width: 100%;

  font-size: 13px;
  color: var(--text-color);
}
.links {
  display: flex;
  flex-direction: column;

  margin-bottom: 6px;

  @media (min-width:1250px) {
    flex-direction: row;
  }

  .media {
    display: flex;
    justify-content: flex-start;
    gap: 5px;
    width: 100%;

    margin-left: -15px;
  }

  .policy  {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;

    gap: 25px;

    margin: 5px 0px;

    @media (min-width:1250px) {
      margin-left: 60px;
    }

    @media (max-width:480px) {
      flex-direction: column;
      align-items: flex-start;

      gap: 5px;
    }

    a {
      text-decoration: underline;
      white-space: pre;
      color: var(--text-color)
    }
  }
}

.info {
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;

  margin: 10px 5px;
}
</style>