<template>
  <div class="card voiting">
    <div class="title">
      Протокол голосования
    </div>
    <AuTable
      :columns="columns"
      :items="items"
      :loading="dataLoading"
    />
    <AuPagination
      v-if="daoEventsTotal > itemsPerPage"
      :total-items="daoEventsTotal"
      @pageChanged="changePage"
    />
  </div>
</template>

<script>
import _ from "lodash";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "VoitingProtocol",
  props: {
    id: {
      type: String,
      default: ""
    }
  },

  data() {
    return {
      page: 1,
      itemsPerPage: 30,
      columns: [
        { name: "Адрес", align: "center" },
        { name: "Количество голосов", align: "center" }
      ]
    };
  },

  computed: {
    ...mapGetters({
      daoEvents: "dao/daoEvents",
      daoEventsTotal: "dao/daoEventsTotal",
      dataLoading: "dao/dataLoading"
    }),

    items() {
      return _.map(this.daoEvents, event => ({
        address: event.address,
        amount: event.amount
      }));
    }
  },

  async mounted() {
    await this.getEvents();
  },

  methods: {
    ...mapActions({
      getDaoEvents: "dao/getDaoEvents"
    }),

    async changePage(page) {
      this.page = page;
      await this.getEvents();
    },

    async getEvents() {
      const params = {
        page: this.page,
        itemsPerPage: this.itemsPerPage,
        requestId: this.id
      };
      await this.getDaoEvents(params);
    }
  }
};
</script>

<style scoped lang="scss">
.voiting {
  padding: 20px;
  .title {
    font-weight: 600;
    font-size: 24px;
    margin-bottom: 40px;
  }
}
</style>