<template>
  <div
    class="ss-elements"
  >
    <div class="ss-explain">
      {{ $t("buyForm.second.title") }}
    </div>
    <div class="ss-select">
      <span>
        {{ $t("buyForm.second.tokenLabel") }}
      </span>
      <AuSelect
        :value="token"
        :options="tokensOptions"
        no-options="Loading..."
        image
        :placeholder="$t('buyForm.second.tokenPlaceholder')"
        @input="tokenSelect"
      />
      <div class="ss-btn-place">
        <AuButton
          v-if="showUSDT"
          center
          @click="setToken(usdtName)"
        >
          <div class="ss-btn-content">
            <img
              class="ss-image"
              src="/svg/buyForm/usdt.svg"
              alt=""
            >
            {{ usdtName }}
          </div>
        </AuButton>
        <AuButton
          v-if="showAUT"
          center
          @click="setToken(autName)"
        >
          <div class="ss-btn-content">
            <img
              class="ss-image"
              src="/svg/buyForm/aut.svg"
              alt=""
            >
            {{ autName }}
          </div>
        </AuButton>
      </div>
    </div>
    <div class="ss-select">
      <span>
        {{ $t("buyForm.second.networkLabel") }}
      </span>
      <AuSelect
        :value="network"
        :options="networkOptions"
        :disabled="token===null"
        :placeholder="$t('buyForm.second.networkPlaceholder')"
        @input="networkSelect"
      />
    </div>
    <ProgressLoader
      v-if="isLoad"
      mode="indeterminate"
      color="#5AC396"
      size="150px"
    />
    <div v-if="!isLoad && showAdr">
      <div class="ss-separator" />
      <div class="ss-explain">
        {{ $t("buyForm.second.adrDeposit") }}
      </div>
      <div class="ss-adr">
        <img
          v-if="qrCode"
          :src="qrCode"
        >
        <div
          class="ss-copy"
        >
          <span>{{ address }}</span>
          <SuccessMark
            v-show="copied"
            :show-animate="copied"
          />
          <img
            v-show="!copied"
            class="ss-icon"
            src="/svg/buyForm/copy.svg"
            alt=""
            @click="copyAddress"
          >
        </div>
      </div>
      <p class="ss-explain">
        {{ $t("buyForm.second.adrExplain1") + ` ${tokenName} ` + $t("buyForm.second.adrExplain2") + ` ${network} ` + $t("buyForm.second.adrExplain3") }}
      </p>
      <p class="ss-explain">
        {{ $t("buyForm.second.adrExplain4") }}
      </p>
      <AuButton
        class="mt-4"
        center
        width="40%"
        type="primary"
        :is-progress="isNotify"
        @click="nextStage"
      >
        {{ $t("buyForm.second.next") }}
      </AuButton>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import ProgressLoader from "@/loaders/progress-bar";
import QRCode from "qrcode";
import SuccessMark from "@/components/SuccessMark.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "SecondStage",
  components: {
    ProgressLoader, SuccessMark
  },
  data() {
    return {
      tokensDeposit: null,
      token: null,
      network: null,
      networkOptions: [],
      address: null,
      qrCode: null,
      payId: null,
      isLoad: false,
      showAdr: false,
      copied: false,
      isNotify: false,
      usdtName: "USDT",
      autName: "AUT"
    };
  },
  computed: {
    ...mapGetters({
      language: "language",
      tokensUtility: "tokensUtility",
      tokensFiat: "tokensFiat",
      ethAddresses: "ethAddresses",
      defaultTokenId: "defaultToken/defaultTokenId",
      defaultTokenMnemonic: "defaultToken/defaultTokenMnemonic",
      defaultTokenEnv: "defaultToken/defaultTokenEnv",
      profile: "profile/profile"
    }),

    addressFrom() {
      return (Array.isArray(this.ethAddresses) ?
        this.ethAddresses.at(0)?.value : this.ethAddresses) ?? "-";
    },

    tokensOptions() {
      const buf = {};
      for (const blockchain in this.tokensDeposit?.tokens) {
        _.forEach(Object.keys(this.tokensDeposit?.tokens[blockchain]), token => {
          const name = this.tokensDeposit?.tokens[blockchain][token]?.name;
          const image = this.tokensDeposit?.tokens[blockchain][token]?.iconUrl;
          if (!(name in buf)) {
            buf[name] = { image: image, blockchain: [] };
          }
          buf[name].blockchain.push(blockchain);
        });
      }
      const fakeKey = _.find(Object.keys(buf), key => /fake/i.test(key));
      if (fakeKey && !this.isTestRole) {
        delete buf[fakeKey];
      }
      const ret = _.map(buf, (value, key) => ({
        display: key,
        value: key,
        blockchains: value.blockchain,
        image: value.image ?? this.getTokenImage(key)
      }));
      return ret.sort((a, b) => {
        return a.display === b.display ? 0 : (a.display > b.display ? 1 : -1);
      });
    },

    type() {
      return _.find(this.networkOptions, item => item.value === this.network)?.type;
    },

    tokenName() {
      return _.find(this.tokensOptions, item => item.value === this.token)?.display;
    },

    showUSDT() {
      return _.find(this.tokensOptions, token => token.display === this.usdtName) !== undefined;
    },

    showAUT() {
      return _.find(this.tokensOptions, token => token.display === this.autName) !== undefined;
    },

    isTestRole() {
      return _.some(this.profile.roles, role => role == "ROLE_TEST");
    }
  },

  async mounted() {
    this.tokensDeposit = await this.getDepositTokens();
  },

  methods: {
    ...mapActions({
      payCreate: "payCreate",
      payNotify: "payNotify",
      getDepositTokens: "getDepositTokens"
    }),

    getTokenImage(tokenName) {
      if (tokenName === "USDT") {
        return "/svg/buyForm/usdt.svg";
      }
      if (tokenName === "ISLM") {
        return "/svg/buyForm/islm.svg";
      }
      if (tokenName === "Deenar") {
        return "/svg/buyForm/deen.svg";
      }
      if (tokenName === "AUT") {
        return "/svg/buyForm/aut.svg";
      }
      return "/svg/companies/s_logo.svg";
    },

    tokenSelect(value) {
      this.token = value;
      this.network = null;
      this.networkOptions.length = 0;
      this.showAdr = false;
      const unique = new Set();

      const blockchains = _.find(this.tokensOptions, token => token.value === value).blockchains;
      _.forEach(blockchains, network => {
        const type = this.tokensDeposit?.nodes[network]?.type ?? "evm";
        const name = this.tokensDeposit?.nodes[network]?.name;
        unique.add({ display: name, value: name, type: type });
      });
      this.networkOptions = [...unique.values()];
      if (this.networkOptions.length === 1) {
        this.networkSelect(this.networkOptions[0].value);
      }
      else {
        this.networkOptions = this.networkOptions.sort((a, b) => {
          return a.display === b.display ? 0 : (a.display > b.display ? 1 : -1);
        });
      }
    },

    setToken(tokenName) {
      this.tokenSelect(_.find(this.tokensOptions, token => token.display === tokenName)?.value);
    },

    async networkSelect(value) {
      this.network = value;
      await this.getAddress();
    },

    async getAddress() {
      this.isLoad = true;
      const params = {
        type: this.type
      };
      try {
        const resp = await this.payCreate(params);
        this.address = resp.address;
        this.payId = resp.payId;
        this.qrCode = await QRCode.toDataURL(this.address);
        this.showAdr = true;
        this.$emit("setAddress", this.address);
      }
      catch (e) {
        this.address = this.$t("buyForm.second.adrError");
      }
      this.isLoad = false;
    },

    async nextStage() {
      this.isNotify = true;
      await this.payNotify(this.payId);
      this.isNotify = false;
      this.$emit("next", this.tokenName);
    },

    copyAddress() {
      navigator.clipboard.writeText(this.address).then(() => {
        this.copied = true;
        setTimeout(() => this.copied = false, 1700);
      });
    },

    showError(message) {
      this.$toast.error(message);
    }
  }
};
</script>

<style lang="scss">
.ss-elements button.btn .btn__text {
  @media screen and (max-width:600px) {
    font-size: 14px !important;
  }

  @media screen and (max-width:450px) {
    font-size: 11px !important;
  }
}
</style>

<style scoped lang="scss">
.ss-elements {
  max-width: 700px;
}
.ss-explain {
  font-size: 16px;
}
.ss-select {
  margin-top: 15px;
  width: 100%;
  span {
    color: var(--color-50);
  }
}
.ss-btn-place {
  margin-top: 7px;
  display: flex;
  gap: 5px;
}
.ss-separator {
  border-bottom: 3px solid green;
  width: 200px;
  margin: 20px 0;
}
.ss-adr {
  display: flex;
  justify-content: space-between;
  background-color: lightgrey;
  border-radius: 15px;
  padding: 8px 18px;
  margin: 10px 0;
}
.ss-copy {
  width: 70%;
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 20px;

  @media screen and (max-width:600px) {
    width: 60%;
    font-size: 15px;
  }

  @media screen and (max-width:500px) {
    width: 50%;
    font-size: 11px;
  }

  span {
    width: 90%;
    word-wrap: break-word;
    text-align: center;
    @media screen and (max-width:500px) {
      width: 70%;
    }
    @media screen and (max-width:600px) {
      width: 80%;
    }
  };
}
.ss-btn-content {
  display: flex;
  align-items: center;
}
.ss-image {
  width: 30px;
}
.ss-icon {
  cursor: pointer;
  width: 48px;
  height: 44px;
  @media screen and (max-width: 800px){
    height: 25px;
  }
}
</style>