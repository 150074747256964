<template>
  <AuIcon
    :icon="isLightTheme ? 'moon' : 'sun'"
    :clickable="true"
    @click="switchTheme"
  />
</template>

<script>
import localStorageController from "@/help/localStorageController";

import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      isLightTheme: "isLightTheme"
    })
  },

  methods: {
    switchTheme() {
      this.$store.dispatch("switchTheme");
      localStorageController.setIsLightTheme(this.isLightTheme);
      localStorageController.setUserMadeChoice();
    }
  }
};
</script>

<style lang="scss" scoped>

</style>