<template>
  <div class="transaction">
    <img
      class="t-image"
      :src="nft.thumb"
      alt="token-image"
    >

    <div class="transaction-info">
      <div class="transaction-info__item transaction-info__left">
        {{ nft.productName }}

        <div class="fs11 transaction-info__type">
          {{ $t("goods.transfer") }}
        </div>

        <div class="fs11 transaction-info__type">
          {{ type }}
          <a
            class="address-link"
            :href="addressLink"
            target="_blank"
          > {{ $filters.shortAddress(address) }}</a>
        </div>
      </div>

      <div class="transaction-info__item transaction-info__right">
        <div>
          <div class="fs14 transaction-info__amount">
            {{ isMT ? amount : "NFT" }}
          </div>

          <div class="fs11 transaction-info__card-type">
            {{ formatDate(transferDate) }}
          </div>
        </div>
        <AuEthHash
          class="fs14 transaction-info__item-name a_link"
          :volume="hash"
        />
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  props: {
    icon: {
      type: String,
      default: "/svg/token/criptoactive_icon.svg"
    },
    nft: {
      type: Object,
      default: () => { }
    },
    transferDate: {
      type: String,
      default: ""
    },
    amount: {
      type: [Number, String],
      required: true
    },
    hash: {
      type: String,
      default: ""
    },
    type: {
      type: String,
      default: ""
    },
    address: {
      type: String,
      default: ""
    }
  },

  computed: {
    isMT() {
      return this.nft.isMultiToken;
    },

    addressLink() {
      return `${process.env.VUE_APP_SCAN_BASE_URL}address/${this.address}`;
    }
  },

  methods: {
    formatDate(date) {
      return moment(date).locale("ru").format("DD.MM.YYYY, HH:mm:ss");
    }
  }
};
</script>

<style scoped lang="scss">
.a_link {
  color: var(--text-color);
}

.t-image {
  width: 30px;
  height: 30px;
  border-radius: 40px;
  object-fit: cover;

  &:hover {
    cursor: pointer;
  }
}

.transaction {
  display: flex;
  align-items: center;

  &-info {
    display: flex;
    justify-content: space-between;
    width: 100%;

    margin-left: 8px;

    &__type {
      color: var(--color-60);
    }

    &__card-type {
      color: var(--color-60);
    }

    // &__amount[isRefill="true"] {
    //   color: #5ac396;
    // }

    // &__amount[isRefill="false"] {
    //   color: #e94545;
    // }

    &__item {
      display: flex;
      flex-direction: column;
      width: 100%;

      &-name {
        text-decoration: underline;
      }
    }

    &__left {
      align-items: flex-start;
    }

    &__right {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
    }
  }
}

.address-link {
  color: #5AC396;
}
</style>