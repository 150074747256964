<template>
  <div>
    <AuBreadcrumb />
    <ProgressLoader
      v-if="editLoading"
      mode="indeterminate"
      color="#5AC396"
      size="150px"
    />
    <div v-else>
      <div>
        <AuTabView>
          <AuTabPanel
            title="Основная информация"
            :show-error="mainError"
          >
            <MainInformation
              v-model="mainInfo"
              @error="e => mainError=e"
            />
          </AuTabPanel>
          <AuTabPanel
            title="Изображения, видео, документы"
            :show-error="imageFileError"
          >
            <ImageAndFiles
              v-model="imageAndFiles"
              @error="e => imageFileError=e"
            />
          </AuTabPanel>
          <AuTabPanel title="Параметры">
            <Params v-model="params" />
          </AuTabPanel>
          <AuTabPanel
            title="Дробление"
            :hidden="mainInfo.goodType !== 'fragment'"
          >
            <Fragmentation
              :amount="mainInfo?.amount"
              :price="forSale?.price"
              :mnemonic="forSale?.tokenQuote"
            />
          </AuTabPanel>
          <AuTabPanel
            title="Маркет"
          >
            <ForSale
              v-model="forSale"
              :good-type="mainInfo.goodType"
            />
          </AuTabPanel>
          <AuTabPanel title="Подтверждение">
            <Confirmation
              v-model="confirm"
              :is-in-marketplace="forSale?.isInMarketplace"
            />
          </AuTabPanel>
        </AuTabView>
      </div>
      <div v-if="showError">
        <span class="error">
          {{ $t("goods.form.emptyFiledMessage") }}
        </span>
      </div>
      <div class="mt-3">
        <ProgressLoader
          v-if="loading"
          mode="indeterminate"
          color="#5AC396"
          size="150px"
        />
        <AuButton
          v-else
          center
          bordered
          width="100%"
          :disabled="showError"
          @click="sendForm"
        >
          {{ confirm.status === "draft" ? "Сохранить как черновик" : $t('goods.form.sendButton') }}
        </AuButton>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import AuTabPanel from "@/components/extended/tabs/AuTabPanel";
import AuTabView from "@/components/extended/tabs/AuTabView";
import Confirmation from "@/components/goodTabs/Confirmation";
import ForSale from "@/components/goodTabs/ForSale";
import Fragmentation from "@/components/goodTabs/Fragmentation";
import ImageAndFiles from "@/components/goodTabs/ImageAndFiles";
import MainInformation from "@/components/goodTabs/MainInformation";
import Params from "@/components/goodTabs/Params";
import ProgressLoader from "@/loaders/progress-bar";
import { mapActions } from "vuex";
export default {
  components: { Params, Fragmentation, Confirmation, ImageAndFiles, AuTabPanel, AuTabView, ForSale, MainInformation, ProgressLoader },
  props: {
    id: {
      type: String,
      default: "new"
    }
  },
  data() {
    return {
      mainInfo: {},
      forSale: {},
      imageAndFiles: {},
      confirm: {},
      params: {},
      loading: false,
      mainError: false,
      imageFileError: false,
      editLoading: false
    };
  },
  computed: {
    showError() {
      return this.mainError || this.saleError || this.imageFileError;
    }
  },
  async mounted() {
    if (this.$route.params.id !== "new") {
      this.editLoading = true;
      const nft = await this.getNftById(this.$route.params.id);
      this.mainInfo.id = nft.id;
      this.mainInfo.section_id = nft?.section.id;
      this.mainInfo.productName = nft?.productName;
      this.mainInfo.description = nft?.description;
      this.mainInfo.company_id = nft?.company.id;
      this.mainInfo.amount = nft?.amount;
      this.mainInfo.units = nft?.units;
      this.mainInfo.matCity = nft?.matCity;
      this.mainInfo.placeOfShipment = nft?.placeOfShipment;
      this.mainInfo.serialNumber = nft?.serialNumber;
      this.mainInfo.internalCode = nft?.internalCode;
      this.mainInfo.goodType = nft?.isMultiToken ? (nft?.isFracted ? "fragment" : "multi") : "single";
      if ("country" in nft) {
        this.mainInfo.country = `/api/countries/${nft?.country.id}`;
      }

      this.forSale.price = String(nft?.price);
      this.forSale.isInMarketplace = nft?.isInMarketplace;
      if ("tokenQuote" in nft) {
        this.forSale.tokenQuote = `/api/au_tokens/${nft?.tokenQuote.id}`;
      }

      this.imageAndFiles.src = nft?.image;
      this.imageAndFiles.fileList = await this.getFilesNames(nft?.additionalFile);
      this.imageAndFiles.videoList = await this.getFilesNames(nft?.video);
      this.confirm.status = nft?.status;
      this.params.params = nft?.params;
      this.editLoading = false;
    }
  },
  methods: {
    ...mapActions({
      getNftById: "goods/getNftById",
      patchNft: "goods/patchNft",
      postNft: "goods/postNft",
      uploadFile: "files/uploadFile",
      getUploadedFile: "files/getUploadedFile"
    }),

    async sendForm() {
      this.loading = true;
      try {
        let body = {};
        if (this.mainInfo.goodType == "single") {
          this.mainInfo.amount = "1";
        }
        Object.assign(body, this.mainInfo, this.forSale, this.imageAndFiles, this.confirm, this.params);
        body.price = Number(body.price);
        body.additionalFile = await this.sendAllFiles(this.imageAndFiles.fileList);
        body.video = await this.sendAllFiles(this.imageAndFiles.videoList);

        if (this.$route.params.id != "new") {
          await this.patchNft(body);
          this.$toast.success(this.$t("toasts.success.goodEdit"));
        }
        else {
          body.tokenQuoted = body.tokenQuote.slice(body.tokenQuote.lastIndexOf("/") + 1);
          await this.postNft(body);
          this.$toast.success(this.$t("toasts.success.goodAdd"));
        }

        this.$router.go(-1);
      }
      catch (e) {
        console.error(e);
        if (this.$route.params.id != "new") {
          this.$toast.error(this.$t("toasts.error.goodEdit"));
        }
        else {
          this.$toast.error(this.$t("toasts.error.goodAdd"));
        }
      }
    },

    async sendAllFiles(fileList) {
      let ret = [];
      for (const file of fileList) {
        if (!(file instanceof File)) {
          ret.push(file.iri);
          continue;
        }
        const upload = await this.uploadFile(file);
        if (_.isNull(upload)) {
          return null;
        }
        ret.push(`/api/uploaded_files/${upload.id}`);
      }
      return ret;
    },

    async getFilesNames(files) {
      let ret = [];
      if (_.isNil(files)) {
        return ret;
      }
      for (const file of files) {
        const id = file.slice(file.lastIndexOf("/") + 1);
        const resp = await this.getUploadedFile(id);
        if (_.isUndefined(resp)) {
          return [];
        }
        ret.push({ name: resp.name, iri: file });
      }
      return ret;
    }
  }
};
</script>

<style lang="scss" scoped>
.error {
  display: flex;
  justify-content: center;
  font-size: 11px;
  color: red;
}
</style>