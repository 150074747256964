<template>
  <div class="profile-menu">
    <div class="login">
      <span class="name">{{ $auth.user().first_name }}</span>
      <span class="role">{{ $auth.user().type }}</span>
    </div>

    <div
      class="profile-menu__item"
      @click="openPage('user')"
      @mouseenter="user='user_hover'"
      @mouseleave="user='user'"
    >
      <AuIcon
        class="profile-menu__image"
        :icon="user"
      />
      {{ $t("profileMenu.profile") }}
    </div>

    <div
      class="profile-menu__item"
      @click="openPage('security')"
      @mouseenter="shield='shield_hover'"
      @mouseleave="shield='shield'"
    >
      <AuIcon
        class="profile-menu__image"
        :icon="shield"
      />
      {{ $t("profileMenu.security") }}
    </div>

    <div
      class="profile-menu__item"
      @click="openPage('settings')"
      @mouseenter="gear='gear_hover'"
      @mouseleave="gear='gear'"
    >
      <AuIcon
        class="profile-menu__image"
        :icon="gear"
      />
      {{ $t("profileMenu.settings") }}
    </div>

    <div class="logout">
      <div
        class="profile-menu__item "
        @click="logout"
      >
        {{ $t("profileMenu.logout") }}

        <AuIcon
          class="logout__icon"
          icon="logout"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      user: "user",
      shield: "shield",
      gear: "gear"
    };
  },
  methods: {
    logout() {
      this.$router.push("/logout");
    },

    openPage(name) {
      this.$router.push({ name });
    }
  }
};
</script>

<style lang="scss" scoped>
.profile-menu
{
  width: 245px;
  background-color: var(--menu-color);
  border: 1px solid var(--border-color);
  border-radius: 20px;
  padding: 20px 20px 0 20px;
  display: flex;
  flex-direction: column;
  gap: 17px;

  &__item {
    font-size: 15px;
    color: var(--color-60);
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 7px;

    &:hover {
      color: var(--text-color);
    }
  }

  &__image {
    width: 20px;
    height: 20px;
  }

  &__profile {
    font-size: 13px;
  }
}

.logout {
  display: flex;
  justify-content: center;
  padding: 20px 0;
  border-top: 1px dashed var(--color-60);
  margin-top: 15px;

  &__icon {
    margin-left: 5px;
  }
}
.login {
  display: none;
  .name {
    font-weight: 500;
    font-size: 16px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .role {
    font-weight: 400;
    font-size: 12px;
    color: var(--color-50);
  }
  @media screen and (max-width: 640px) {
    display: flex;
    flex-direction: column;
    border-bottom: 1px dashed var(--color-60);
  }
}
</style>