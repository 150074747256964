<template>
  <div class="if-form">
    <div class="if-label">
      Основное изображение
    </div>
    <AuUpload
      :src="body.src"
      :button="$t('projects.form.thirdStep.projectChooseImageButton')"
      required
      width="100%"
      @change="sendBody"
    />
    <div class="if-label">
      Дополнительные файлы
    </div>
    <AuUpload
      :key="refresh"
      :files="body.fileList"
      :button="$t('projects.form.thirdStep.projectAddDocsButton')"
      mode="multiple"
      width="100%"
      @added="addFile"
      @deleted="delFile"
    />
    <div class="if-label">
      Видео файлы
    </div>
    <AuUpload
      :key="refresh"
      :files="body.videoList"
      :button="$t('projects.form.thirdStep.projectAddDocsButton')"
      mode="multiple"
      width="100%"
      @added="addVideo"
      @deleted="delVideo"
    />
  </div>
</template>

<script>
import _ from "lodash";
export default {
  name: "ImageAndFiles",
  props: {
    modelValue: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      refresh: 0
    };
  },
  computed: {
    isError() {
      return _.isNull(this.body.src);
    },

    body() {
      if (!_.isEmpty(this.modelValue)) {
        return this.modelValue;
      }
      else {
        return {
          src: null,
          imageFile: null,
          fileList: [],
          videoList: []
        };
      }
    }
  },
  mounted() {
    this.$emit("error", this.isError);
  },
  methods: {
    sendBody(data) {
      this.body.src = data.src;
      this.body.imageFile = data.image;
      this.$emit("update:modelValue", this.body);
      this.$emit("error", _.isNull(data.src));
    },
    addFile(file) {
      this.body.fileList.push(file);
      this.refresh = this.refresh === 0 ? 1 : 0;
      this.$emit("update:modelValue", this.body);
    },
    delFile(index) {
      this.body.fileList.splice(index, 1);
      this.refresh = this.refresh === 0 ? 1 : 0;
      this.$emit("update:modelValue", this.body);
    },
    addVideo(file) {
      this.body.videoList.push(file);
      this.refresh = this.refresh === 0 ? 1 : 0;
      this.$emit("update:modelValue", this.body);
    },
    delVideo(index) {
      this.body.videoList.splice(index, 1);
      this.refresh = this.refresh === 0 ? 1 : 0;
      this.$emit("update:modelValue", this.body);
    }
  }
};
</script>

<style scoped lang="scss">
.if-form{
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  border-radius: 10px;
}
.if-label{
  font-size: 14px;
  color: var(--color-50);
  margin-bottom: 15px;
}
</style>