import StateControllerBase from "@/store/stateControllerBase";
import { setLoadingState } from "@/store/setLoadingState";

import FileController from "@/api/fileController";

const fileController = new FileController();

class Actions extends StateControllerBase {
  setDataLoading({ commit }, dataLoading) {
    commit("setDataLoading", dataLoading);
  }

  @setLoadingState
  async getUploadedFiles({ commit }, params) {
    const resp = await fileController.getUploadedFiles(params);
    commit("setUploadedFiles", resp.data);
    commit("setTotalUploadedFiles", resp.totalItems);
  }

  @setLoadingState
  async getUploadedFile({ commit }, id) {
    const resp = await fileController.getUploadedFile(id);
    commit("setUploadedFile", resp);
    return resp;
  }

  @setLoadingState
  async patchFile(_context, { id, body }) {
    return await fileController.patchFile(id, body);
  }

  @setLoadingState
  async uploadFile(_context, file) {
    return await fileController.uploadFile(file);
  }

  @setLoadingState
  async deleteFile(_context, id) {
    return await fileController.deleteFile(id);
  }
}

export default (new Actions).asPlainObject();