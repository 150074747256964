<template>
  <div class="fr-form">
    <ul>
      <li>
        <span class="fr-label">
          Всего частей: <b>{{ amount }}</b>. Общая стоимость <b>{{ total }}</b>.
        </span>
      </li>
      <li>
        <span class="fr-label">
          Первое предложение на выкуп разрешено направить эмитенту через: <b>365 дней</b>.
        </span>
      </li>
      <li>
        <span class="fr-label">
          Далее предложения эмитентом могут быть отправлены через каждые: <b>180 дней</b>.
        </span>
      </li>
      <li>
        <span class="fr-label">
          Период действия предложения эмитента: <b>30 дней</b>.
        </span>
      </li>
      <li>
        <span class="fr-label">
          Принудительный выкуп возможен, если держатель владеет более чем: <b>75%</b>.
        </span>
      </li>
      <li>
        <span class="fr-label">
          Принудительный выкуп возможен по средневзвешенной цене плюс: <b>0%</b>.
        </span>
      </li>
      <li>
        <span class="fr-label">
          Средневзвешенная цена части для выкупа рассчитывается за предшествующие (от даты заявки): <b>180 дней</b>.
        </span>
      </li>
      <li>
        <span class="fr-label">
          Заявка на принудительный выкуп срабатывает через: <b>14 дней</b>.
        </span>
      </li>
    </ul>
  </div>
</template>

<script>
import _ from "lodash";
import BigNumber from "bignumber.js";
import { mapGetters } from "vuex";

export default {
  name: "Fragmentation",
  props: {
    amount: {
      type: String,
      default: "1"
    },
    price: {
      type: String,
      default: "0"
    },
    mnemonic: {
      type: String,
      default: ""
    }
  },
  computed: {
    ...mapGetters({
      tokensFiat: "tokensFiat",
      tokensGold: "tokensGold"
    }),

    total() {
      return BigNumber(this.amount).
        multipliedBy(_.isNil(this.price) ? 0 : this.price).
        toString(10) + " " + this.additional;
    },

    additional() {
      if (_.isNil(this.mnemonic)) {
        return "";
      }
      const id = this.mnemonic.slice(this.mnemonic.lastIndexOf("/") + 1);
      const token = _.find([...this.tokensGold, ...this.tokensFiat], token => token.id == id);
      return _.get(token, "mnemonicEng", "");
    }
  }
};
</script>

<style scoped lang="scss">
.fr-form{
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  border-radius: 10px;
}
.fr-label{
  font-size: 15px;
  color: var(--text-color);
  margin-bottom: 15px;
}
</style>