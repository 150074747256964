<template>
  <div>
    <AuBreadcrumb />
    <div class="kyc-main card">
      <div class="title">
        {{ $t("kyc.title") }}
      </div>
      <KYC />
    </div>
  </div>
</template>

<script>
import KYC from "@/components/KYC";
export default {
  name: "KYCPage",
  components: { KYC }
};
</script>

<style scoped lang="scss">
.kyc-main {
  padding: 20px;

  .title {
    font-size: 24px;
  }
}
</style>