<template>
  <div
    :style="style"
    class="video"
    v-html="embeddedCode"
  />
</template>

<script>
export default {
  props: {
    embeddedCode: {
      type: String,
      required: true
    },
    src: {
      type: String,
      required: true
    },
    alt: {
      type: String,
      required: true
    },
    targetClass: {
      type: String,
      required: true
    },
    mainClass: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      isClicked: false,
      projectImageHeight: "100vh",
      projectImageWidth: "100vw"
    };
  },

  computed: {
    style() {
      return {
        "--iframe-height": this.projectImageHeight,
        "--iframe-width": this.projectImageWidth
      };
    },

    width() {
      return document.querySelector(this.targetClass)?.clientWidth;
    }
  },

  watch: {
    width(value) {
      console.log(value);
    }
  },

  mounted() {
    const resizeObserver = new ResizeObserver(() => {
      this.setSize();
    });

    this.setSize();
    resizeObserver.observe(document.querySelector(this.mainClass));
  },

  methods: {
    setSize() {
      const clientHeight = document.querySelector(this.targetClass)?.clientHeight;
      this.projectImageHeight = clientHeight ? clientHeight + "px" : "100vh";

      const clientWidth = document.querySelector(this.targetClass)?.clientWidth;
      this.projectImageWidth = clientWidth ? clientWidth + "px" : "100vw";
    }
  }
};
</script>

<style>
.video {
  iframe {
    max-height: 400px;
    max-width: 800px;
    height: var(--iframe-height);
    width: var(--iframe-width);
  }
}
</style>

<style scoped>
.video {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>