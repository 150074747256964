import actions from "./actions";

export default {
  namespaced: true,
  state: {
    withdraws: [],
    totalWithdraws: 0,
    dataLoading: true
  },

  getters: {
    withdraws: state => {
      return state.withdraws;
    },

    totalWithdraws: state => {
      return state.totalWithdraws;
    },

    dataLoading: state => {
      return state.dataLoading;
    }
  },
  mutations: {
    setWithdraws(state, withdraws) {
      state.withdraws = withdraws;
    },

    setTotalWithdraws(state, totalWithdraws) {
      state.totalWithdraws = totalWithdraws;
    },

    setDataLoading(state, dataLoading) {
      state.dataLoading = dataLoading;
    }
  },

  actions
};