<template>
  <div>
    <form
      class="form"
      @submit.prevent="submit"
    >
      <div
        v-if="step == 0"
        class="form-inner"
      >
        <div>
          <div class="input-title">
            Токен, для которого будет создаваться инвестиционный проект
          </div>
          <AuSelect
            v-model="body.tokenBaseId"
            class="input"
            :options="asset"
          />
        </div>

        <div>
          <div class="input-title">
            Количество токенов для реализации через Капитал
          </div>

          <ImaskInput
            v-model="body.token"
            class="input-mask"
            :mask="/^\d+$/"
          />
        </div>

        <div>
          <div class="input-title">
            Токен для покупки
          </div>

          <AuSelect
            v-model="body.tokenQuoteId"
            class="input"
            :options="fiat"
          />
        </div>
      </div>

      <div
        v-if="step == 1"
        class="form-inner"
      >
        <div>
          <div class="input-title">
            Стоимость одного токена
          </div>

          <ImaskInput
            v-model="body.priceBase"
            class="input-mask"
            :mask="/^\d+$/"
          />
        </div>

        <div>
          <div class="input-title">
            Желаемый старт инвестиционного проекта
          </div>

          <AuInput
            v-model="body.dateStart"
            type="Date"
          />
        </div>

        <div>
          <div class="input-title">
            Режим приоритетной предваритеной продажи
          </div>

          <div class="input-check">
            <input
              id="exampleRadios1"
              v-model="body.isPreSale"
              class="input-check__input"
              type="checkbox"
            >
            <label
              class="input-check__label"
              for="exampleRadios1"
            >
              Да
            </label>
          </div>
        </div>

        <div v-if="body.isPreSale">
          <div class="input-title">
            Количество токенов для предварительной приоритетной продажи
          </div>

          <ImaskInput
            v-model="body.preSalePart"
            class="input-mask"
            :mask="/^\d+$/"
          />
        </div>

        <div v-if="body.isPreSale">
          <div class="input-title">
            Желаемая цена в режиме приоритетной продажи
          </div>

          <ImaskInput
            v-model="body.preSalePrice"
            class="input-mask"
            :mask="/^\d+$/"
          />
        </div>
      </div>

      <div
        v-if="step == 2"
        class="form-inner"
      >
        <div>
          <div class="input-title">
            Выплата дивидендов
          </div>

          <div class="input-check">
            <input
              id="exampleRadios1"
              v-model="body.v1"
              class="input-check__input"
              type="checkbox"
            >
            <label
              class="input-check__label"
              for="exampleRadios1"
            >
              Да
            </label>
          </div>
        </div>

        <div>
          <div class="input-title">
            Режим выплаты
          </div>

          <AuSelect
            v-model="body.v2"
            class="input"
            :options="paymentModeOptions"
          />
        </div>

        <div>
          <div class="input-title">
            Начало первых выплат
          </div>

          <AuInput
            v-model="body.v3"
            type="Date"
          />
        </div>

        <div>
          <div class="input-title">
            Процент выплаты
          </div>

          <AuInput
            v-model="body.v4"
          />
        </div>

        <div>
          <div class="input-title">
            Погашаемые
          </div>

          <div class="input-check">
            <input
              id="exampleRadios1"
              v-model="body.v5"
              class="input-check__input"
              type="checkbox"
            >
            <label
              class="input-check__label"
              for="exampleRadios1"
            >
              Да
            </label>
          </div>
        </div>
      </div>

      <div class="form-buttons">
        <AuButton
          v-if="step==0"
          bordered
          width="100%"
          center
          border-radius="10"
          active-bg-color="#279063"
          type="submit"
          @click="$router.go(-1)"
        >
          <span class="button-text">
            Вернуться
          </span>
        </AuButton>

        <AuButton
          v-else
          bordered
          width="100%"
          center
          border-radius="10"
          active-bg-color="#279063"
          type="submit"
          @click="step--"
        >
          <span class="button-text">
            Назад
          </span>
        </AuButton>

        <AuButton
          v-if="step==2"
          bordered
          width="100%"
          center
          border-radius="10"
          active-bg-color="#279063"
          type="submit"
          @click="sendForm"
        >
          <span class="button-text">
            Отправить
          </span>
        </AuButton>

        <AuButton
          v-else
          bordered
          width="100%"
          center
          border-radius="10"
          active-bg-color="#279063"
          type="submit"
          @click="step++"
        >
          <span class="button-text">
            Дальше
          </span>
        </AuButton>
      </div>
    </form>
  </div>
</template>

<script>
import _ from "lodash";

import { IMaskComponent } from "vue-imask";

export default {
  components: {
    "ImaskInput": IMaskComponent
  },

  data() {
    return {
      step: 0,
      body: {
        tokenBaseId: "",
        token: "",
        tokenQuoteId: "",
        priceBase: "",
        dateStart: "",
        isPreSale: "",
        preSalePrice: "",
        preSalePart: "",
        v1: "",
        v2: "",
        v3: "",
        v4: "",
        v5: ""
      },
      paymentModeOptions: [
        {
          display: "Месяц",
          value: "month"
        },
        {
          display: "Квартал",
          value: "quarter"
        },
        {
          display: "Год",
          value: "year"
        }
      ]
    };
  },

  computed: {
    auTokens() {
      return this.$store.getters.tokens;
    },

    asset() {
      if (this.auTokens) {
        return _.chain(this.auTokens).filter({ type: "asset" }).map(v => ({ value: v.id, display: v.nameEng })).value();
      }

      return [];
    },

    fiat() {
      if (this.auTokens) {
        return _.chain(this.auTokens).filter({ type: "fiat" }).map(v => ({ value: v.id, display: v.nameEng })).value();
      }

      return [];
    }
  },

  methods: {
    async sendForm() {
      try {
        await this.$store.dispatch("postCapitalMp", this.body);
      }
      catch (e) {
        console.error(e);
      }
      finally {
        this.$router.go(-1);
      }
    }
  }
};
</script>

<style lang="scss">
.input-check {
  display: flex;
  align-items: center;
  gap: 10px;
  padding-left: 0px;
  margin-bottom: 12px;

  &__input {
    width: 20px;
    height: 20px;
    border-radius: 25%;
    vertical-align: middle;
    cursor: pointer;
    border: 1px solid var(--text-color);
    appearance: none;
    -webkit-appearance: none;
    outline: none;
    position: relative;
    &:checked {
      &::before {
        content: "✓";
        width: 10px;
        height: 10px;
        position: absolute;
        right: 4.4px;
        color: #28E593;
        border-radius: 100%;
      }
    }
  }

  &__label {
    font-size: 13px;
  }
}

.form {
  display: flex;
  gap: 35px;
  flex-direction: column;

  &-inner {
    display: flex;
    gap: 25px;
    flex-direction: column;
  }

  &-buttons {
    display: flex;
    justify-content: space-between;
    gap: 50%
  }
}

.input {
  width: 100%;

  .multiselect__tags {
    width: auto;
  }

  &-mask {
    height: 41px;
    border: 1px solid var(--color-80);
    padding: 15px;
    padding-left: 25px;
    border-radius: 14px;
    width: 100%;
  }

  &-title {
    font-size: 13px;
    color: var(--color-60);
    margin-bottom: 10px;
  }

  &:not(:placeholder-shown) {
    color: var(--text-color);
  }
}
</style>