export default {
  namespaced: true,
  state: {
    socket: null,
    socketStatus: null,
    socketHasPreviousConnection: false
  },
  getters: {
    socket: state => {
      return state.socket;
    },
    socketStatus: state => {
      return state.socketStatus;
    },
    socketHasPreviousConnection: state => {
      return state.socketHasPreviousConnection;
    }
  },
  mutations: {
    setSocket(state, socket) {
      state.socket = socket;
    },
    setSocketStatus(state, socketStatus) {
      state.socketStatus = socketStatus;
    },
    setSocketHasPreviousConnection(state, socketHasPreviousConnection) {
      state.socketHasPreviousConnection = socketHasPreviousConnection;
    }
  },
  actions: {
    getSocket: state => {
      return state.socket;
    },
    getSocketStatus: state => {
      return state.socketStatus;
    },
    setSocketHasPreviousConnection({ commit }, socketHasPreviousConnection) {
      commit("setSocketHasPreviousConnection", socketHasPreviousConnection);
    }
  }
};