<template>
  <div>
    <div class="form-header">
      <div class="title">
        {{ $t("counterparties.form.label") }}
      </div>
      <AuButton
        width="auto"
        bordered
        @click="goBack"
      >
        {{ $t("counterparties.form.backButton") }}
      </AuButton>
    </div>
    <div class="form-wrapper">
      <div class="form-line">
        <div class="label">
          {{ $t("counterparties.form.FIOlabel") }}
        </div>
        <AuInput
          v-model="name"
          :placeholder="$t('counterparties.form.FIOPlaceholder')"
          required
        />
      </div>
      <div class="form-line">
        <div class="label">
          {{ $t("counterparties.form.addressLabel") }}
        </div>
        <AuInput
          v-model="address"
          :placeholder="$t('counterparties.form.addressPlaceholder')"
          required
        />
      </div>
      <div class="form-line">
        <div class="label">
          {{ $t("counterparties.form.statusLabel") }}
        </div>
        <AuInput
          v-model="status"
          :placeholder="$t('counterparties.form.statusPlaceholder')"
          required
        />
      </div>
      <AuButton
        class="submit-btn"
        bordered
        :disabled="!$filters.correctETH(address)"
        @click="save"
      >
        {{ $t("counterparties.form.saveBtn") }}
      </AuButton>
      <div
        v-if="reqError"
        class="req-error"
      >
        {{ $t("counterparties.form.error") }}
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";

import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      name: "",
      address: "",
      status: "",
      reqError: false
    };
  },

  computed: {
    ...mapGetters({
      counterparties: "counterparties"
    }),

    counterpartyId() {
      return this.$route.params.id;
    },

    isNewCounterparty() {
      return this.counterpartyId == "new";
    }
  },

  async mounted() {
    if (_.isEmpty(this.counterparties)) {
      await this.getCounterparties();
    }
    if (!this.isNewCounterparty) {
      let editableСounterparty = _.find(this.counterparties, { id: Number(this.counterpartyId) });
      this.name = editableСounterparty.name;
      this.address = editableСounterparty.address;
      this.status = editableСounterparty.status;
    }
  },

  methods: {
    ...mapActions({
      postCounterparty: "postCounterparty",
      updateCounterparty: "updateCounterparty",
      getCounterparties: "getCounterparties"
    }),

    goBack() {
      this.$router.push({ name: "counterparties" });
    },

    async save() {
      if (!_.isEmpty(this.name) && !_.isEmpty(this.address) && !_.isEmpty(this.status)) {
        this.reqError = false;
        try {
          if (!this.isNewCounterparty) {
            await this.updateCounterparty({ id: Number(this.counterpartyId),
              newValue: {
                name: this.name,
                address: this.address,
                status: this.status,
                user: `/api/users/${this.$auth.user().id}`
              }
            });
          }
          else {
            await this.postCounterparty({
              name: this.name,
              address: this.address,
              status: this.status,
              user: `/api/users/${this.$auth.user().id}`
            });
          }

          this.$toast.success(this.isNewCounterparty ? this.$t("toasts.success.contactAdd") : this.$t("toasts.success.contactEdit"));
          this.$router.push({ name: "counterparties" });
        }
        catch (error) {
          this.$toast.error(this.$t("toasts.error.contactAdd"));
        }
      }
      else {
        this.reqError = true;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.form-wrapper, .form-line {
  display: flex;
  flex-direction: column;
  gap: 20px;
  .form-line {
    gap: 12px;
  }
  .submit-btn {
    align-self: flex-end;
    width: fit-content;
  }
  .req-error {
    display: flex;
    justify-content: center;
    color: red;
  }
}
</style>