<template>
  <div>
    <AuBreadcrumb
      v-if="showProjectsTable"
      parent-name="Dashboard"
    />
    <div
      v-if="showProjectsTable"
      class="card projects"
    >
      <div class="form-header">
        <div>
          <div class="title">
            {{ $t('projects.label') }}
          </div>
          <div
            class="d-flex justify-content-between subtitle-line"
          >
            <span
              class="explain"
            >
              {{ $t('projects.projectsCount') }}: <span class="count">{{ projectCount || "0" }} </span>
            </span>
          </div>
        </div>
        <div>
          <div>
            <AuButton
              bordered
              center
              type="primary"
              width="237"
              @click="createNewProject"
            >
              <span class="bnt-text">
                {{ $t('projects.addProjectButton') }}
              </span>
            </AuButton>
          </div>
        </div>
      </div>
      <div>
        <AuTable
          class="projects-table"
          :columns="columns"
          :items="formattedProjects"
          :icons="null"
          :loading="loading"
        >
          <template #name="{ value }">
            <div>
              {{ value.name }}
            </div>
            <div class="explain">
              {{ value.type }}
            </div>
          </template>
          <template #token="{value}">
            <div>
              {{ value.tokenMnemonic }}
            </div>
            <div class="explain">
              {{ value.volume }}
            </div>
          </template>
          <template #status="{value}">
            <div class="d-flex justify-content-center">
              <div class="status">
                <div v-if="value.isDraft">
                  {{ value.statusName }}
                </div>
                <div v-if="value.isCompleted && value.value">
                  <div class="progress_line">
                    <div>
                      {{ value.statusProject }}
                    </div>
                    <ProgressBar
                      class="indent_progress"
                      :procent="value.procent"
                      :status="value.statusProject"
                    />

                    <div class="progress_text">
                      <AuDecimalStatic
                        :value="value.value"
                        :addition="value.addition"
                        :decimal="value.decimal"
                        :indent="value.indent"
                      />
                      <div class="progress_text">
                        {{ value.procent + "%" }}
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="!value.isDraft && !value.value">
                  {{ $t('projects.status.inProcess') }}
                </div>
              </div>
            </div>
          </template>
          <template #action="{value}">
            <div
              v-click-outside="closeAllMenus"
              class="container-menu"
            >
              <AuIcon
                icon="more"
                as-button
                @click="openMenu(value.index)"
              />
              <div
                v-if="showMenu[value.index]"
                class="context-menu"
              >
                <AuButton
                  v-if="!value.isCompleted"
                  width="100%"
                  center
                  popup
                  border-color="none"
                  active-bg-color="none"
                  hovered-bg-color="none"
                  @click="editProject(value.id)"
                >
                  {{ $t("counterparties.editAction") }}
                </AuButton>
                <!-- <AuButton
                  width="100%"
                  center
                  popup
                  border-color="none"
                  active-bg-color="none"
                  hovered-bg-color="none"
                  @click="showVerifyDialog(value.index)"
                >
                  {{ $t("projects.verificationAction") }}
                </AuButton> -->
                <AuButton
                  v-if="!value.isCompleted"
                  width="100%"
                  center
                  popup
                  border-color="none"
                  active-bg-color="none"
                  hovered-bg-color="none"
                  @click="showDeleteDialog(value.index)"
                >
                  {{ $t("counterparties.deleteAction") }}
                </AuButton>
                <AuButton
                  v-if="value.isCompleted"
                  width="100%"
                  center
                  popup
                  border-color="none"
                  active-bg-color="none"
                  hovered-bg-color="none"
                  @click="goToPage(value.capitalId)"
                >
                  {{ $t("projects.investHistory") }}
                </AuButton>
                <AuButton
                  v-if="value.isCompleted && loginAsTester"
                  width="100%"
                  center
                  popup
                  border-color="none"
                  active-bg-color="none"
                  hovered-bg-color="none"
                  @click="goToProjectInvestors(value.capitalId)"
                >
                  {{ $t("counterparties.investorsAction") }}
                </AuButton>
                <AuButton
                  v-if="value.isCompleted && value.type=='invest'"
                  width="100%"
                  center
                  popup
                  border-color="none"
                  active-bg-color="none"
                  hovered-bg-color="none"
                  @click="goToDividends(value.tokenId)"
                >
                  {{ $t("counterparties.dividendsAction") }}
                </AuButton>
                <!-- <AuButton
                  v-if="value.isCompleted && value.type=='voting'"
                  width="100%"
                  center
                  popup
                  border-color="none"
                  active-bg-color="none"
                  hovered-bg-color="none"
                  @click="goToDAORequests(value.index)"
                >
                  {{ $t("counterparties.daoRequests") }}
                </AuButton> -->
              </div>
            </div>
          </template>
        </AuTable>
        <div class="projects-accordion">
          <AuAccordion
            v-for="(project, index) in formattedProjects"
            :key="project"
            :last-child="(index == formattedProjects.length - 1)"
          >
            <template #title>
              <div>
                {{ project.projectName.name }}
              </div>
              <div class="explain">
                {{ project.projectName.type }}
              </div>
            </template>
            <template #content>
              <div class="company-info container-fluid">
                <div class="row">
                  <div class="col info-title">
                    <span>{{ $t('projects.secondColumn') }}</span>
                  </div>
                  <div class="col">
                    <span>{{ project.type }}</span>
                    <div>
                      {{ project.token.tokenMnemonic }}
                    </div>
                    <div class="explain">
                      {{ project.token.volume }}
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col info-title">
                    <span>{{ $t('projects.fourthColumn') }}</span>
                  </div>
                  <div class="col">
                    <div v-if="project.status.isDraft">
                      {{ project.status.statusName }}
                    </div>
                    <div v-if="project.status.isCompleted && project.status.value">
                      <div class="progress_line">
                        <div>
                          {{ project.status.statusProject }}
                        </div>
                        <ProgressBar
                          class="indent_progress"
                          :procent="project.status.procent"
                          :status="project.status.statusProject"
                        />
                        <div class="progress_text">
                          <AuDecimalStatic
                            :value="project.status.value"
                            :addition="project.status.addition"
                            :decimal="project.status.decimal"
                            :indent="project.status.indent"
                          />
                          <div class="progress_text">
                            {{ project.status.procent + "%" }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-if="!project.status.isDraft && !project.status.value">
                      {{ $t('projects.status.inProcess') }}
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col info-title">
                    <span>{{ $t('projects.startOpen') }}</span>
                  </div>
                  <div class="col">
                    <span>{{ project.dateOpen }}</span>
                  </div>
                </div>

                <div class="row">
                  <div class="col buttons">
                    <AuButton
                      v-if="!project.action.isCompleted"
                      width="100%"
                      center
                      @click="editProject(project.action.id)"
                    >
                      {{ $t("counterparties.editAction") }}
                    </AuButton>
                    <!-- <AuButton
                      width="100%"
                      center
                      @click="showVerifyDialog(project.action.index)"
                    >
                      {{ $t("projects.verificationAction") }}
                    </AuButton> -->
                    <!-- v-if="project.action.isCompleted" -->
                    <AuButton
                      v-if="!project.action.isCompleted"
                      width="100%"
                      center
                      @click="showDeleteDialog(project.action.index)"
                    >
                      {{ $t("counterparties.deleteAction") }}
                    </AuButton>
                    <AuButton
                      v-if="project.action.isCompleted"
                      width="100%"
                      center
                      @click="goToPage(project.action.capitalId)"
                    >
                      {{ $t("projects.investHistory") }}
                    </AuButton>
                    <AuButton
                      v-if="project.action.isCompleted && loginAsTester"
                      width="100%"
                      center
                      @click="goToProjectInvestors(project.action.capitalId)"
                    >
                      {{ $t("counterparties.investorsAction") }}
                    </AuButton>
                    <AuButton
                      v-if="project.action.isCompleted && project.action.type=='invest'"
                      width="100%"
                      center
                      @click="goToDividends(project.action.tokenId)"
                    >
                      {{ $t("counterparties.dividendsAction") }}
                    </AuButton>
                    <!-- <AuButton
                      v-if="project.action.isCompleted && project.action.isVoiting"
                      width="100%"
                      center
                      @click="goToDAORequests(project.action.index)"
                    >
                      {{ $t("counterparties.daoRequests") }}
                    </AuButton> -->
                  </div>
                </div>
              </div>
            </template>
          </AuAccordion>
        </div>
      </div>

      <!-- <AuPagination
          :total-items="totalWithdraws"
          @pageChanged="pageWithdrawChanged"
        /> -->

      <AuPagination
        v-if="projectCount > itemsPerPage"
        :hide="loading"
        :total-items="projectCount"
        @pageChanged="getPagePagination"
      />
    </div>
    <RouterView />
    <AuModal
      v-model="modifyDialog"
      width="500px"
    >
      <template #header>
        <span class="modal_head_text">
          {{ modalHeader }}
        </span>
      </template>
      <template #body>
        <span>
          {{ modalBody }}
        </span>
      </template>
      <template #footer>
        <div class="modal_button">
          <div class="modal_button_margin">
            <AuButton
              bordered
              center
              width="80px"
              @click="modalClick"
            >
              {{ $t('projects.dialog.agree') }}
            </AuButton>
          </div>
          <div class="modal_button_margin">
            <AuButton
              bordered
              center
              width="80px"
              @click="modifyDialog=false"
            >
              {{ $t('projects.dialog.cancel') }}
            </AuButton>
          </div>
        </div>
      </template>
    </AuModal>
  </div>
</template>

<script>
import _ from "lodash";
import moment from "moment";
import ProgressBar from "@/components/progressBar/ProgressBar";
import { convertPercent, convertStatus } from "@/components/capital/capitalHelper";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "MyProjects",
  components: {
    ProgressBar
  },

  data() {
    return {
      page: 1,
      itemsPerPage: 30,
      isStep1: true,
      loading: true,
      columns: [{ name: this.$t("projects.firstColumn"), slot: "name", property: "projectName", align: "start", width: "100px" },
        { name: this.$t("projects.secondColumn"), align: "center", slot: "token" },
        { name: this.$t("projects.fourthColumn"), align: "center", slot: "status" },
        { name: this.$t("projects.startOpen"), align: "center" },
        { name: this.$t("projects.sixthColumn"), align: "center", slot: "action" }],
      typeNames: {
        investment: this.$t("projects.cryptoActiveTypes.investment"),
        access: this.$t("projects.cryptoActiveTypes.access"),
        ticket: this.$t("projects.cryptoActiveTypes.ticket"),
        voucher: this.$t("projects.cryptoActiveTypes.voucher"),
        management: this.$t("projects.cryptoActiveTypes.management")
      },
      investTypeNames: {
        redeemable: ` ${this.$t("projects.investType.redeemable")}`,
        "non-redeemable": ` ${this.$t("projects.investType.non-redeemable")}`
      },
      isModify: false,
      modifyDialog: false,
      modifyId: null,
      modifyIndex: null,
      modifyName: null,
      showMenu: []
    };
  },

  computed: {
    ...mapGetters({
      ethAddresses: "ethAddresses",
      requestsToIssue: "requestsToIssue",
      projectCount: "projectCount",
      profile: "profile/profile"
    }),

    loginAsTester() {
      return _.some(this.profile.roles, role => role == "ROLE_TEST");
    },

    address() {
      return _.get(this.ethAddresses, "[0]", "");
    },

    formattedProjects() {
      return _.map(this.requestsToIssue, (requestsToIssue, i) => ({
        projectName: {
          name: requestsToIssue.tokenNameEng || this.$t("projects.noName"),
          type: requestsToIssue.projectType
        },
        token: {
          tokenMnemonic: requestsToIssue.tokenMnemonicEng,
          volume: requestsToIssue.tokenCount
        },
        status: {
          statusName: requestsToIssue.isDraft ? this.$t("projects.status.draft") :
            (requestsToIssue.isCompleted ? this.$t("projects.status.approved") : this.$t("projects.status.inProcess")),
          isDraft: requestsToIssue.isDraft,
          isCompleted: requestsToIssue.isCompleted,
          value: requestsToIssue?.auCapital?.currentCap,
          decimal: requestsToIssue?.tokenQuote?.decimals,
          indent: requestsToIssue?.tokenQuote?.indent,
          addition: requestsToIssue?.tokenQuote?.mnemonicEng,
          procent: convertPercent(requestsToIssue?.auCapital),
          statusProject: convertStatus(requestsToIssue?.auCapital)
        },
        dateOpen: moment(requestsToIssue?.dateStart).format("DD MMM YYYY") + " - " + moment(requestsToIssue?.dateEnd).format("DD MMM YYYY"),
        action: {
          id: requestsToIssue.id,
          type: requestsToIssue.projectType,
          index: i,
          tokenId: requestsToIssue?.token?.id,
          capitalId: requestsToIssue?.auCapital?.id,
          isVoiting: requestsToIssue?.isVoiting,
          isCompleted: requestsToIssue.isCompleted
        }
      }));
    },

    showProjectsTable() {
      return !_.includes(this.$route.path, "edit-project");
    },

    modalHeader() {
      return this.isModify ?
        this.$t("projects.dialog.verificationTitle") :
        this.$t("projects.dialog.deleteTitle");
    },

    modalBody() {
      return this.isModify ?
        `${this.$t("projects.dialog.verificationSubtitle")} - ${this.modifyName}?` :
        `${this.$t("projects.dialog.deleteSubtitle")} - ${this.modifyName}?`;
    }
  },

  async mounted() {
    if (this.$auth.user().type != "issuer") {
      this.$router.push({ name: "base" });
    }
    this.closeAllMenus();
    this.loading = false;
  },

  methods: {
    ...mapActions({
      delRequestToIssue: "delRequestToIssue",
      editRequestToIssue: "editRequestToIssue",
      getHydraRequestToIssue: "getHydraRequestToIssue"
    }),

    toLinkProject(link) {
      this.$router.push({ name: "project card", params: { id: link } });
    },

    toLinkToken(link) {
      this.$router.push({ name: "token info", params: { id: link } });
    },

    getType(value) {
      let ret = _.get(this.typeNames, value.cryptoActiveType, "-");
      ret += _.get(this.investTypeNames, value.investType, "");
      if ("isVoiting" in value) {
        ret += value.isVoiting ? ` ${this.$t("projects.isVoiting.voiting")}` :
          ` ${this.$t("projects.isVoiting.non-voiting")}`;
      }
      return ret;
    },

    closeAllMenus() {
      this.showMenu = _.map(this.requestsToIssue, () => false);
    },

    openMenu(index) {
      this.closeAllMenus();
      this.showMenu[index] = true;
    },

    createNewProject() {
      if (!this.address?.verifiedAt || !this.address?.status) {
        this.showError(this.$t("toasts.error.projectNeedVerify"));
        return;
      }
      this.$router.push({ name: "edit project", params: { id: "new" } });
    },

    editProject(id) {
      if (!id) {
        this.showError(this.$t("toasts.error.projectEdit"));
        return;
      }
      this.closeAllMenus();
      this.$router.push({ name: "edit project", params: { id: id } });
    },

    showVerifyDialog(index) {
      if (!index) {
        this.showError(this.$t("toasts.error.projectNoVerify"));
        return;
      }
      this.isModify = true;
      this.showModifyDialog(index);
    },

    showDeleteDialog(index) {
      if (!index && index != 0) {
        this.showError(this.$t("toasts.error.projectNoVerify"));
        return;
      }
      this.isModify = false;
      this.closeAllMenus();
      this.showModifyDialog(index);
    },

    showModifyDialog(index) {
      this.modifyId = this.requestsToIssue[index].id;
      this.modifyName = this.requestsToIssue[index]?.tokenNameEng;
      this.modifyIndex = index;
      this.modifyDialog = true;
    },

    modalClick() {
      if (this.isModify) {
        this.verify();
      }
      else {
        this.del();
      }
    },

    async verify() {
      this.modifyDialog = false;
      this.loading = true;
      try {
        const params = { isDraft: false };
        await this.editRequestToIssue([this.modifyId, params]);
        this.requestsToIssue[this.modifyIndex].isDraft = false;
        this.showSuccess(this.$t("toasts.success.projectVerify"));
      }
      catch (e) {
        this.showError(this.$t("toasts.error.projectVerify"));
      }
      this.loading = false;
    },

    goToPage(id) {
      if (!id) {
        this.showError(this.$t("toasts.error.projectNoVerify"));
        return;
      }
      this.$router.push({ name: "capital history", params: { id: id } });
    },

    goToDividends(id) {
      if (!id) {
        this.showError(this.$t("toasts.error.projectNoVerify"));
        return;
      }
      this.$router.push({ name: "dividends issuer", params: { id: id } });
    },

    goToDAORequests(index) {
      if (!index) {
        this.showError(this.$t("toasts.error.projectNoVerify"));
        return;
      }
      this.$router.push({ name: "dao requests", params: { id: index } });
    },

    goToProjectInvestors(id) {
      this.$router.push({ name: "project investors", params: { id: id } });
    },

    async del() {
      this.modifyDialog = false;
      this.loading = true;
      try {
        await this.delRequestToIssue(this.modifyId);
        this.requestsToIssue.splice(this.modifyIndex, 1);
        this.showSuccess(this.$t("toasts.success.projectDelete"));
      }
      catch (e) {
        this.showError(this.$t("toasts.error.projectDelete"));
      }
      this.loading = false;
    },

    showSuccess(message) {
      this.$toast.success(message);
    },

    showError(message) {
      this.$toast.error(message);
    },

    async getPagePagination(actualPage) {
      this.loading = true;
      await this.getHydraRequestToIssue({ page: actualPage });
      this.loading = false;
    }
  }
};
</script>

<style scoped lang="scss">
.projects{
  padding: 20px;
  font-family: 'Suisse Intl',serif;
  font-style: normal;

  &-table {
    @media screen and (max-width:670px) {
      display: none;
    }
  }

  &-accordion {
    display: none;

    @media screen and (max-width:670px) {
      display: block;
    }

    .buttons {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;

      &>button {
        flex: 1 1 calc(50% - 20px);
      }
    }
  }
}

.form-header {
  margin-bottom: 20px;
  .title {
    font-size: 24px;
    color: var(--text-color);
    line-height: 1.1;
  }
}

.col {
  padding: 0;
  font-size: 12px;
  &:not(.info-title) {
    text-align: end;
  }
}

.company {
  &-header {
    color: var(--text-color);
    font-size: 12px;
  }
  &-info {
    color: var(--text-color);
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}

.subtitle-line {
  flex-direction: row;
  @media screen and (max-width:460px) {
    gap: 20px;
    flex-direction: column;
  }
}

.count{
  font-weight: 500;
  font-size: 14px;
  color: var(--color-60);;
}
.bnt-text{
  font-weight: 450;
  font-size: 12px;
}
.explain {
  font-weight: 450;
  font-size: 12px;
  color: var(--color-60);
  @media screen and (max-width:384px) {
    line-height: 1.1;
  }
}

.modal_head_text {
  font-family: 'Suisse Intl',serif;
  font-style: normal;
  font-weight: 450;
  font-size: 20px;
  margin-bottom: 20px;
}
.btn_indent{
  display: flex;
  justify-content: end;
  margin-bottom: 15px;
}
.modal_button {
  display: flex;
  justify-content: end;
  margin-top: 15px;
}
.modal_button_margin {
  margin-right: 10px;
}
.btn-text{
  font-weight: 450;
  font-size: 12px;
}
.container-menu{
  position: relative;
}
.context-menu {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 128px;
  right: calc(50% - 128px / 2);
  background: var(--menu-color);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  z-index: 10;
  top: 30px;
}
.wizard {
  margin-bottom: 25px;
}
.projects-table {
  margin-bottom: 0;
}
.progress_line {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.progress_text {
  display: flex;
  justify-content: space-between;
  font-weight: 450;
  font-size: 12px;
  color: var(--color-20);
}
.indent_progress {
  margin-top: 6px;
  width: 100%;
}
.status {
  width: 135px;
}
</style>
