<template>
  <WizardWrap
    :texts="texts"
    :stage="stage"
  />
</template>

<script>
import _ from "lodash";
import WizardWrap from "@/components/wizard/WizardWrap";
import { mapGetters } from "vuex";
export default {
  name: "IssuerWizard",
  components: { WizardWrap },
  data() {
    return {
      texts: [{
        label: {
          text: this.$t("issuerWizard.title1")
        },
        link: {
          text: this.$t("issuerWizard.titleLink1"),
          link: "/wallet-verification"
        },
        button: {
          text: this.$t("issuerWizard.titleBtn1"),
          link: "/wallet-verification"
        },
        verification: false
      },
      {
        label: {
          text: this.$t("issuerWizard.title2")
        },
        link: {
          text: this.$t("issuerWizard.titleLink2"),
          link: "/companies/company-manager"
        },
        button: {
          text: this.$t("issuerWizard.titleBtn2"),
          link: "/companies/company-manager"
        },
        verification: false
      },
      {
        label: {
          text: this.$t("issuerWizard.title3")
        },
        link: {
          text: this.$t("issuerWizard.titleLink3"),
          link: "/my-projects/edit-project/new"
        },
        button: {
          text: this.$t("issuerWizard.titleBtn3"),
          link: "/my-projects/edit-project/new"
        },
        verification: true
      }]
    };
  },
  computed: {
    ...mapGetters({
      companyCount: "companyCount",
      ethAddresses: "ethAddresses"
    }),

    address() {
      return _.get(this.ethAddresses, "[0]", "");
    },

    stage() {
      if (!this.address || !this.address?.verifiedAt || !this.address?.status) {
        return 0;
      }
      return this.companyCount > 0 ? 2 : 1;
    }
  }
};
</script>

<style scoped>

</style>