<template>
  <div>
    <AuTabView>
      <AuTabPanel :title="$t(`ethAddresses.titleAddress`)">
        <div class="card">
          <div class="title">
            {{ $t("ethAddresses.myAddressesTitle") }}
          </div>
          <div class="eth__list">
            <div class="eth__item">
              <div class="eth__line">
                <span class="eth__label">
                  {{ $t("ethAddresses.walletAddress") }}
                </span>

                <span
                  :isSet="eth?.value != null"
                  class="eth__value"
                >
                  {{ eth?.value ? eth?.value : $t("ethAddresses.notSet") }}
                </span>
              </div>

              <div class="eth__line">
                <span class="eth__label">
                  {{ $t("ethAddresses.signedByMetaMask") }}
                </span>

                <span
                  v-if="eth?.verifiedAt"
                  class="eth__mark_green"
                >
                  &#10004;
                </span>

                <span
                  v-else
                  class="eth__mark_red"
                >
                  &#x2715;
                </span>
              </div>

              <div class="eth__line">
                <span class="eth__label">
                  {{ $t("ethAddresses.addedToRegistry") }}
                </span>

                <span
                  v-if="eth?.status"
                  class="eth__mark_green"
                >
                  &#10004;
                </span>

                <span
                  v-else
                  class="eth__mark_red"
                >
                  &#x2715;
                </span>
              </div>
            </div>
          </div>
        </div>
      </AuTabPanel>
      <AuTabPanel :title="`2FA`">
        <div class="card">
          <Au2FA />
        </div>
      </AuTabPanel>
      <AuTabPanel :title="$t(`ethAddresses.titlePassword`)">
        <div class="card">
          <ChangePassword />
        </div>
      </AuTabPanel>
    </AuTabView>

    <AuModal
      v-model="showModal"
      width="450px"
    >
      <template #header>
        <div class="modal_header">
          {{ $t("ethAddresses.modal.title") }}
        </div>
      </template>
      <template #body>
        <div class="modal_text_small">
          {{ `${$t("ethAddresses.modal.text")} ${currentEth}` }}?
        </div>
      </template>
      <template #footer>
        <div class="modal_button">
          <AuButton
            bordered
            center
            width="80"
            @click="removeEth"
          >
            {{ $t("ethAddresses.modal.removeEthButton") }}
          </AuButton>
          <AuButton
            bordered
            center
            width="80"
            @click="showModal = false"
          >
            {{ $t("ethAddresses.modal.resetButton") }}
          </AuButton>
        </div>
      </template>
    </AuModal>
  </div>
</template>

<script>
import Au2FA from "@/components/extended/Au2FA.vue";
import AuButton from "@/components/extended/AuButton.vue";
import AuTabPanel from "@/components/extended/tabs/AuTabPanel.vue";
import AuTabView from "@/components/extended/tabs/AuTabView.vue";
import ChangePassword from "@/components/ChangePassword.vue";
import MetaMask from "@/mixins/MetaMask";

import { mapActions, mapGetters } from "vuex";

import _ from "lodash";

export default {
  components: {
    Au2FA,
    AuButton,
    AuTabPanel,
    AuTabView,
    ChangePassword
  },

  mixins: [MetaMask],

  data() {
    return {
      newEth: "",
      currentEth: "",
      currentEth_id: "",
      validate: false,
      showModal: false
    };
  },

  computed: {
    ...mapGetters({
      arrEth: "ethAddresses",
      accountAddress: "accountAddress"
    }),

    isLight() {
      return this.$store.getters.isLightTheme;
    },

    eth() {
      return _.get(this.arrEth, "[0]");
    }
  },

  methods: {
    ...mapActions({
      postEthAddresses: "postEthAddresses",
      deleteEthAddresses: "deleteEthAddresses",
      getEthAddresses: "getEthAddresses",
      patchEthAddresses: "patchEthAddresses"
    }),

    async addToRegistry(item) {
      let body = {
        status: "add"
      };

      try {
        await this.patchEthAddresses({ id: item.id, body: body });
        await this.getEthAddresses();
      }
      catch (error) {
        console.error(error);
      }
    },

    pasteFromWallet() {
      this.newEth = this.accountAddress;
    },

    async addEth() {
      const id = this.$auth.user().id;
      try {
        const resp = await this.postEthAddresses({
          value: this.newEth,
          user: `/api/users/${id}`
        });
        if (resp?.data.message == "Address already exists!") {
          this.$toast.warning(resp?.data.message);
        }
        else {
          this.$toast.success(this.$t("toasts.success.ethAdd"));
          await this.getEthAddresses();
          this.newEth = "";
        }
      }
      catch (error) {
        console.log(error);
        this.$toast.error(this.$t("toasts.error.ethAdd"));
      }
    },

    removeEthClick(item) {
      this.currentEth = item.value;
      this.currentEth_id = item.id;
      this.showModal = true;
    },

    async removeEth() {
      this.showBuyDialog = false;
      try {
        await this.deleteEthAddresses(this.currentEth_id);
        this.$toast.success(this.$t("toasts.success.ethDelete"));
        await this.getEthAddresses();
      }
      catch (error) {
        console.log(error);
        this.$toast.error(this.$t("toasts.error.ethDelete"));
      }
      finally {
        this.showModal = false;
      }
    }
  }
};
</script>

<style scoped lang="scss">
.card {
  padding: 20px;
  border-radius: 12px;
}

.title {
  font-weight: 600;
  font-size: 22px;
  margin-bottom: 30px;
}

.subtitle {
  font-size: 14px;
  margin-bottom: 15px;
}

.create_eth {
  display: flex;
  justify-content: space-between;
  gap: 15px;

  @media screen and (max-width: 640px) {
    flex-direction: column;
  }
}

.inp_eth {
  width: 100%;

  &__wrap {
    flex: 1 1 auto;
  }
}

.eth {
  &__list {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &__item {
    display: flex;
    flex-direction: column;
    gap: 15px;

    @media screen and (max-width: 640px) {
      flex-direction: column;
      gap: 25px;
      margin-bottom: 50px;
    }
  }

  &__value[isSet=false] {
    color: var(--color-50);
  }

  &__line {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  &__mark_green {
    color: #5ac396;
  }

  &__mark_red {
    color: red;
  }

  &__label {
    font-size: 14px;
    color: var(--color-50);

    align-self: start;

    width: 160px;
  }
}

.btn_block {
  display: flex;
  align-items: center;
  gap: 15px;

  @media screen and (max-width: 640px) {
    flex-direction: column;
    width: 100%;
  }
}

.modal_button {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.add-button {
  margin-top: 30px;
}

.adaptive-btn {
  @media screen and (max-width: 640px) {
    width: 100% !important;
  }
}

.pucket-button {
  white-space: nowrap;
  color: #ffffff;
}
</style>
