<template>
  <div
    class="transaction-card"
    :style="cssVars"
  >
    <div>
      {{ name }}
    </div>

    <div>
      {{ value }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true
    },
    value: {
      type: String,
      required: true
    },
    color: {
      type: String,
      required: true
    }
  },

  computed: {
    cssVars() {
      return {
        "background-color": this.color
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.transaction-card {
  display: flex;
  font-size: 11px;
  padding: 8px 15px;
  border-radius: 20px;
  gap: 20px;
}
</style>