import AuAccordion from "./AuAccordion.vue";
import AuAvatar from "./AuAvatar.vue";
import AuBreadcrumb from "./AuBreadcrumb.vue";
import AuButton from "./AuButton.vue";
import AuCheckbox from "./AuCheckbox.vue";
import AuCountdown from "@/components/extended/AuCountdown";
import AuDecimalInput from "./AuDecimalInput.vue";
import AuDecimalStatic from "./AuDecimalStatic.vue";
import AuDialogModal from "./AuDialogModal.vue";
import AuError from "./AuError.vue";
import AuEthAddress from "./AuEthAddress.vue";
import AuEthHash from "./AuEthHash.vue";
import AuIcon from "./AuIcon.vue";
import AuInput from "./AuInput";
import AuModal from "@/components/extended/AuModal";
import AuNoData from "@/components/extended/AuNoData";
import AuPagination from "@/components/extended/AuPagination";
import AuSelect from "./AuSelect";
import AuSwitch from "./AuSwitch";
import AuSwitchButton from "./AuSwitchButton.vue";
import AuTable from "./AuTable.vue";
import AuTooltip from "./AuTooltip.vue";
import AuUpload from "./AuUpload.vue";

const Components = {
  AuButton,
  AuAvatar,
  AuSwitchButton,
  AuIcon,
  AuBreadcrumb,
  AuTable,
  AuDialogModal,
  AuAccordion,
  AuCheckbox,
  AuInput,
  AuSwitch,
  AuSelect,
  AuDecimalStatic,
  AuEthAddress,
  AuEthHash,
  AuDecimalInput,
  AuModal,
  AuNoData,
  AuPagination,
  AuUpload,
  AuTooltip,
  AuCountdown,
  AuError
};

const install = function (Vue) {
  for (const i in Components) {
    Vue.component(i, Components[i]);
  }
};

if (typeof window !== "undefined" && window.Vue) {
  install(window.Vue);
}

Components.install = install;
export default Components;
