<template>
  <div>
    <h1 class="title">
      {{ $t('auCapitals.business.title') }}
    </h1>
    <div class="paragraph">
      <p>
        {{ $t('auCapitals.business.descriptionOne') }}
      </p>

      <p>
        {{ $t('auCapitals.business.descriptionTwo') }}
      </p>
    </div>

    <div class="header">
      {{ $t('auCapitals.business.subTitleOne') }}
    </div>

    <div class="container-fluid">
      <div class="row businesses fs13">
        <div class="business col-md-6 col-sm-12">
          <div class="business__icon">
            1
          </div>
          <div class="business__paragraph">
            <div class="business__title">
              {{ $t('auCapitals.business.itemSubtitleOne') }}
            </div>
            <div class="business__text first">
              {{ $t('auCapitals.business.itemTextOne') }}
            </div>
          </div>
        </div>
        <div class="business col-md-6 col-sm-12">
          <div class="business__icon">
            2
          </div>
          <div class="business__paragraph">
            <div class="business__title">
              {{ $t('auCapitals.business.itemSubtitleTwo') }}
            </div>
            <div class="business__text second">
              {{ $t('auCapitals.business.itemTextTwo') }}
            </div>
          </div>
        </div>
        <div class="business col-md-6 col-sm-12">
          <div class="business__icon">
            3
          </div>
          <div class="business__paragraph">
            <div class="business__title">
              {{ $t('auCapitals.business.itemSubtitleThree') }}
            </div>
            <div class="business__text third">
              {{ $t('auCapitals.business.itemTextThree') }}
            </div>
          </div>
        </div>
        <div class="business col-md-6 col-sm-12">
          <div class="business__icon">
            4
          </div>
          <div class="business__paragraph">
            <div class="business__title">
              {{ $t('auCapitals.business.itemSubtitleFour') }}
            </div>
            <div class="business__text fourth">
              {{ $t('auCapitals.business.itemTextFour') }}
            </div>
          </div>
        </div>
        <div class="business col-md-6 col-sm-12">
          <div class="business__icon">
            5
          </div>
          <div class="business__paragraph">
            <div class="business__title">
              {{ $t('auCapitals.business.itemSubtitleFive') }}
            </div>
            <div class="business__text fifth">
              {{ $t('auCapitals.business.itemTextFive', {token: defaultTokenEnv}) }}
            </div>
          </div>
        </div>
        <div class="business col-md-6 col-sm-12">
          <div class="business__icon">
            6
          </div>
          <div class="business__paragraph">
            <div class="business__title">
              {{ $t('auCapitals.business.itemSubtitleSix') }}
            </div>
            <div class="business__text sixth">
              {{ $t('auCapitals.business.itemTextSix') }}
            </div>
          </div>
        </div>
        <div class="business col-md-6 col-sm-12">
          <div class="business__icon">
            7
          </div>
          <div class="business__paragraph">
            <div class="business__title">
              {{ $t('auCapitals.business.itemSubtitleSeven') }}
            </div>
            <div class="business__text seventh">
              {{ $t('auCapitals.business.itemTextSeven') }}
            </div>
          </div>
        </div>
        <div class="business col-md-6 col-sm-12">
          <div class="business__icon">
            8
          </div>
          <div class="business__paragraph">
            <div class="business__title">
              {{ $t('auCapitals.business.itemSubtitleEight') }}
            </div>
            <div class="business__text eighth">
              {{ $t('auCapitals.business.itemTextEight') }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="request">
      <div class="request-header">
        Оставьте заявку
      </div>

      <form
        class="form"
        @submit.prevent="submit"
      >
        <div
          class="form-inner"
          :requestSent="requestSent"
        >
          <div>
            <div class="input-title">
              Ваше ФИО
            </div>

            <ImaskInput
              v-model="fullName"
              class="input"
              :mask="/^[а-яА-ЯёЁA-Za-z ,.'-]+$/i"
              placeholder="Ваше ФИО"
            />
          </div>

          <div>
            <div class="input-title">
              Ваш e-mail
            </div>

            <ImaskInput
              v-model="email"
              class="input"
              :mask="/^\S*@?\S*$/"
              placeholder="example@site.com"
            />
          </div>

          <div>
            <div class="input-title">
              Телефон
            </div>

            <ImaskInput
              v-model="phone"
              class="input"
              mask="+{0}(000)000-00-00"
              placeholder="+7(999)999-99-99"
            />
          </div>

          <div>
            <div class="check-title">
              Есть ли у Вас действующее юр. лицо?
            </div>

            <div>
              <div class="form-check">
                <input
                  id="exampleRadios1"
                  v-model="optionVar"
                  class="form-check__input"
                  type="radio"
                  name="exampleRadios"
                  value="0"
                  checked
                >
                <label
                  class="form-check__label"
                  for="exampleRadios1"
                >
                  Да
                </label>
              </div>
              <div class="form-check">
                <input
                  id="exampleRadios2"
                  v-model="optionVar"
                  class="form-check__input"
                  type="radio"
                  name="exampleRadios"
                  value="1"
                >
                <label
                  class="form-check__label"
                  for="exampleRadios2"
                >
                  Нет
                </label>
              </div>
              <div class="form-check">
                <input
                  id="exampleRadios3"
                  v-model="optionVar"
                  class="form-check__input"
                  type="radio"
                  value="2"
                >
                <label
                  class="form-check__label"
                  for="exampleRadios3"
                >
                  Затрудняюсь ответить
                </label>
              </div>
            </div>
          </div>

          <AuButton
            bordered
            width="100%"
            center
            border-radius="10"
            active-bg-color="#279063"
            type="submit"
            @click="sendRequest"
          >
            <span class="button-text">
              Отправить заявку
            </span>
          </AuButton>
        </div>

        <div
          class="form-request-result"
          :requestSent="requestSent"
        >
          Ваше обращение отправлено
        </div>
      </form>
    </div> -->
  </div>
</template>

<script>
// import { IMaskComponent } from "vue-imask";

import { mapActions, mapGetters } from "vuex";

export default {
  // components: {
  //   "ImaskInput": IMaskComponent
  // },

  data() {
    return {
      fullName: "",
      email: "",
      phone: "",
      optionVar: 0,
      requestSent: false
    };
  },

  computed: {
    ...mapGetters({
      defaultTokenEnv: "defaultToken/defaultTokenEnv"
    }),
    isLegalEntity() {
      return this.optionVar == 0;
    }
  },

  methods: {
    ...mapActions({
      requestForms: "requestForms"
    }),

    async sendRequest() {
      let body = {
        fullName: this.fullName,
        email: this.email,
        phone: this.phone,
        isLegalEntity: this.isLegalEntity,
        captchaUID: "",
        captchaPhrase: ""
      };

      await this.requestForms(body);
      this.requestSent = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.title {
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 40px;
}
.form-check {
  display: flex;
  align-items: center;
  gap: 10px;
  padding-left: 0px;
  margin-bottom: 12px;

  &__input {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    vertical-align: middle;
    cursor: pointer;
    border: 1px solid var(--text-color);
    appearance: none;
    -webkit-appearance: none;
    outline: none;
    position: relative;
    &:checked {
      &::before {
        content: "";
        width: 10px;
        height: 10px;
        position: absolute;
        right: 4.4px;
        top: 4px;
        background: #28E593;
        border-radius: 100%;
      }
    }
  }

  &__label {
    font-size: 13px;
  }
}

.button-text {
  color: (--main-color)
}

.form {
  margin-top: 45px;
  position: relative;
}

.form-inner {
  display: flex;
  gap: 35px;
  flex-direction: column;

  &[requestSent=true] {
    visibility: hidden;
    opacity: 0%;
    transition: visibility 1s ease;
    transition: opacity 1s ease;
  }
}

.form-request-result {
  position: absolute;
  visibility: hidden;
  opacity: 0%;

  top: 50%;
  left: 50%;

  &[requestSent=true] {
    visibility: visible;
    opacity: 100%;

    transition: visibility 1s ease;
    transition: opacity 1s ease;
  }
}

.input {
  height: 45px;
  border: 1px solid var(--color-80);
  color: var(--color-80);
  padding: 15px;
  border-radius: 14px;
  width: 100%;

  &:not(:placeholder-shown) {
    color: var(--text-color);
  }
}

.check-title {
  font-size: 13px;
  color: var(--color-60);
  margin-bottom: 30px;
}

.input-title {
  font-size: 13px;
  color: var(--color-60);
  margin-bottom: 10px;
}

.request {
  width: 50%;

  @media screen and (max-width: 768px)  {
    width: 100%;
  }

  &-header {
    margin-top: 50px;
  }
}

.businesses {
  margin-top: 40px;
  row-gap: 60px;
}

.business {
  display: flex;
  gap: 30px;

  &__icon {
    height: 34px;
    min-width: 34px;
    border-radius: 100%;
    color: #2ABA7C;
    border: 1px solid var(--color-80);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px;
    font-size: 14px;
  }

  &__paragraph {
    display: flex;
    flex-direction: column;
    line-height: 20px;
    gap: 15px;
    font-size: 14px;
  }

  &__text {
    color: var(--color-50);
    &.first {
      max-width: 415px;
    }
    &.second, &.third, &.fifth {
      max-width: 378px;
    }
    &.eighth {
      max-width: 360px;
    }
  }
}
.container-fluid {
  padding: 0;
}
.paragraph {
  color: var(--color-50);
  max-width: 464px;
  font-size: 14px;
  margin-bottom: 80px;
}
</style>