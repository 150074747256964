<template>
  <div>
    <div
      v-if="widgetMode"
      class="vote-header"
    >
      {{ $t("topVotes.title") }}
      <RouterLink
        to="/top-votes"
        class="vote-transition"
      >
        {{ $t("topVotes.link") }}
        <AuIcon icon="chevron_right" />
      </RouterLink>
    </div>
    <div
      class="card"
      :class="{'vote-widget':widgetMode, 'vote-main':!widgetMode}"
    >
      <div
        v-if="!widgetMode"
        class="vote-title vote-underline"
      >
        {{ $t("topVotes.title") }}
      </div>
      <ProgressLoader
        v-if="loadAndCalc"
        mode="indeterminate"
        color="#5AC396"
        size="150px"
      />
      <div v-else>
        <span v-if="topVoting.length===0">
          {{ $t("topVotes.empty") }}
        </span>
        <Apexchart
          v-else
          class="vote-chart"
          :options="options"
          :series="topVoting"
          :height="height"
          @click="goToProject"
        />
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import BigNumber from "bignumber.js";
import ProgressLoader from "@/loaders/progress-bar";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "TopVotes",
  components: { ProgressLoader },
  props: {
    widgetMode: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loadAndCalc: false,
      sectionId: null,
      maxX: 100,
      category: null,
      topVoting: [],
      barCount: 0,
      barHeight: 50,
      indentHeight: 30
    };
  },
  computed: {
    ...mapGetters({
      isLightTheme: "isLightTheme",
      sectionCapital: "sectionCapital"
    }),

    height() {
      return 30 + (this.barCount * this.barHeight) + (this.barCount * this.indentHeight) + "px";
    },

    options() {
      return {
        chart: {
          type: "bar",
          toolbar: {
            show: false
          }
        },
        grid: {
          show: false
        },
        plotOptions: {
          bar: {
            horizontal: true,
            barHeight: `${this.barHeight}px`
          }
        },
        tooltip: {
          intersect: false,
          theme: this.isLightTheme ? "light" : "dark",
          custom: this.drawTooltip,
          style: {
            fontFamily: "Suisse Intl"
          },
          marker: {
            show: false
          }
        },
        dataLabels: {
          formatter: (v, _) => {
            return `${v}%`;
          }
        },
        xaxis: {
          type: "category",
          min: 0,
          max: this.maxX
        },
        yaxis: {
          labels: {
            style: {
              fontFamily: "Suisse Intl",
              fontSize: "14px",
              colors: this.isLightTheme ? "black" : "white",
              cssClass: "votes-clickable"
            }
          }
        }
      };
    }
  },
  async created() {
    this.category = this.$route?.query?.category;
    if (this.category) {
      await this.getSectionCapital();
      this.sectionId = _.find(this.sectionCapital, item => item.name == this.category)?.id;
    }
    if (this.widgetMode) {
      this.barHeight = 40;
      this.indentHeight = 25;
    }
    await this.calcTop();
  },
  methods: {
    ...mapActions({
      getSectionCapital: "getSectionCapital",
      getAllAuCapitalsPagination: "capitals/getAllAuCapitalsPagination"
    }),

    drawTooltip(params) {
      const currentData = params.w.config.series[0].data[params.dataPointIndex];
      const quorum = currentData.quorum;
      const mnemonic = currentData.mnemonic;
      const tokenAchievement = currentData.y;
      const currentCap = BigNumber(quorum).multipliedBy(tokenAchievement).dividedBy(100).toFixed(2);
      let ret = "<div style='border-radius: 10px;padding: 20px;'>";
      ret += `<div>Current cap: ${currentCap} ${mnemonic}</div>`;
      for (const item of currentData.goals) {
        const value = item.value < 0 ? `${-item.value}%` :
          BigNumber(quorum).multipliedBy(item.value).dividedBy(100).toFixed(2) + " " + mnemonic;
        ret += `<div>${item.name} ${value}</div>`;
      }
      return `${ret}</div>`;
    },

    goToProject(event, noUse, graphData) {
      let vote = null;
      if (graphData.dataPointIndex === -1) {
        vote = _.find(item => item.x === event.target.innerHTML);
      }
      else {
        vote = this.topVoting[0]?.data[graphData.dataPointIndex];
      }
      if (vote) {
        this.$router.push({ name: "project card", params: { id: vote.id } });
      }
    },

    getQuorum(project) {
      return BigNumber(project?.minCap ? project.minCap : project.maxCap)
        .dividedBy(BigNumber(10).pow(project.tokenQuote.decimals)).toFixed(2);
    },

    getPercent(project) {
      const value = BigNumber(project.currentCap);
      const oneHundredPercent = BigNumber(project?.minCap ? project.minCap : project.maxCap);
      return value.multipliedBy(100).dividedBy(oneHundredPercent).toFixed(2);
    },

    getGoals(project) {
      const ret = [];
      let quorum = 100;
      let hardCapName = "Hard cap (quorum):";

      ret.push({
        name: "Quorum achievement:",
        strokeWidth: -1,
        value: -this.getPercent(project)
      });
      if (project?.minCap) {
        quorum = BigNumber(project.maxCap).multipliedBy(100).dividedBy(project.minCap).toFixed(2);
        hardCapName = "Hard cap:";
        ret.push({
          name: "Soft cap (quorum):",
          strokeWidth: -1,
          value: 100
        });
      }
      ret.push({
        name: hardCapName,
        strokeWidth: -1,
        value: quorum
      });
      return ret;
    },

    async calcTop() {
      this.loadAndCalc = true;
      const params = {
        page: 1,
        itemsPerPage: 100,
        projectType: "voting",
        status: "open"
      };
      if (this.sectionId) {
        params.sectionId = this.sectionId;
      }
      try {
        const resp = await this.getAllAuCapitalsPagination(params);
        if (resp.data.length === 0) {
          this.loadAndCalc = false;
          return;
        }
        // сортировка пока на фронте, но как будут фильтры бэка
        // все это будет переделано
        const sorted = resp.data.sort((a, b) => {
          return this.getPercent(b) - this.getPercent(a);
        });
        if (this.widgetMode) {
          sorted.length = sorted.length < 5 ? sorted.length : 5;
        }
        this.barCount = sorted.length;
        this.topVoting = [{
          data: _.map(sorted, item => ({
            x: item.tokenBase.nameEng,
            y: this.getPercent(item),
            id: item.id,
            quorum: this.getQuorum(item),
            mnemonic: item.tokenQuote.mnemonicEng,
            goals: this.getGoals(item)
          })),
          name: "Current cap"
        }];
        this.maxX = Number(this.topVoting[0].data[0].y);
      }
      catch (e) {
        console.error(e);
      }
      this.loadAndCalc = false;
    }
  }
};
</script>

<style lang="scss">
.votes-clickable {
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
}
</style>

<style scoped lang="scss">
.vote-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
  font-size: 22px;
}
.vote-transition {
  font-size: 11px;
  color: var(--color-50);
  text-decoration: none;
}
.vote-main {
  padding: 20px;
}
.vote-widget {
  padding: 20px;
}
.vote-title {
  font-size: 24px;
}
.vote-underline {
  border-bottom: 1px solid var(--color-70);
  padding-bottom: 15px;
  margin-bottom: 15px;
}
.vote-chart {
  margin-left: -20px;
}
</style>