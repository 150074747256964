<template>
  <div
    class="up-main"
    :class="{'up-required' : required ? !isFileSelected : false}"
    :style="css"
  >
    <div class="up-header">
      <div class="up-file">
        <input
          ref="file-input"
          class="invisible"
          type="file"
          @change="getPreview"
        >
        <div>
          <AuButton
            border-radius="12"
            bordered
            center
            @click="$refs['file-input'].click()"
          >
            {{ button }}
          </AuButton>
        </div>
      </div>
      <div class="up-close">
        <AuIcon
          v-if="isActive"
          icon="close"
          @click="close"
        />
      </div>
    </div>
    <img
      v-if="isActive"
      class="preview"
      :src="src"
      alt=""
      @click="showFullSize"
    >
    <div v-if="!isImage">
      <div
        v-for="(file, index) of files"
        :key="index"
        class="d-flex align-items-center mb-2"
      >
        <div
          class="up-close me-5"
          @click="del(index)"
        >
          <AuIcon
            icon="close"
            @click="del(index)"
          />
        </div>
        <div>
          {{ file.name }}
        </div>
        <div
          v-if="getIsImage(file)"
          class="embedded-input"
        >
          <AuInput
            :model-value="file.embeddedCode"
            placeholder="Embedded YouTube video code"
            type="Text"
            @update:modelValue="embedded => addEmbedded(index, embedded)"
          />
        </div>
      </div>
    </div>
    <AuModal
      v-model="fullSize"
      width="50vw"
    >
      <template #header>
        <div class="up-modal-header" />
      </template>
      <template #body>
        <img
          class="modal-preview"
          :src="src"
          alt=""
        >
      </template>
    </AuModal>
  </div>
</template>

<script>
export default {
  name: "AuUpload",
  props: {
    src: {
      type: String,
      default: null
    },
    files: {
      type: Array,
      default: () => []
    },
    mode: {
      type: String,
      default: "image"
    },
    button: {
      type: String,
      default: "Открыть"
    },
    width: {
      type: String,
      default: "100%"
    },
    height: {
      type: String,
      default: "250px"
    },
    required: {
      type: Boolean,
      default: false
    },
    embedded: {
      type: Boolean,
      default: false
    }
  },

  emits: ["change", "added", "deleted", "embeddedChange"],

  data() {
    return {
      fullSize: false
    };
  },

  computed: {
    isActive() {
      return this.src !== null;
    },

    isImage() {
      return this.mode === "image";
    },

    css() {
      return `width:${this.width};` +
          `height:${this.height};`;
    },

    isFileSelected() {
      return this.src !== null && this.src !== undefined && this.src !== "";
    }
  },

  methods: {
    getPreview(event) {
      let input = event.target;
      if (input.files && input.files[0]) {
        if (this.isImage) {
          let reader = new FileReader();
          reader.onload = (e) => {
            this.$emit("change", { src: e.target.result, image: input.files[0] });
          };
          reader.readAsDataURL(input.files[0]);
        }
        else {
          this.$emit("added", input.files[0]);
        }
      }
    },

    getIsImage(file) {
      return file.type.includes("image");
    },

    showFullSize() {
      this.fullSize = true;
    },

    del(index) {
      this.$emit("deleted", index);
      if (this.files.length < 2) {
        this.$emit("change", { src: null, image: null });
      }
    },

    close() {
      URL.revokeObjectURL(this.src);
      this.$emit("change", { src: null, image: null });
    },

    addEmbedded(index, embedded) {
      this.$emit("embeddedChange", index, embedded);
    }
  }
};
</script>

<style scoped lang="scss">
.up-main{
  border: 1px solid var(--color-70);
  border-radius: 10px;
  padding: 15px 20px;
  overflow-y: auto;
}
.up-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.up-file{
  width: 90%;
}
.invisible{
  display: none;
}
.up-close{
  cursor: pointer;
}
.preview{
  object-fit: contain;
  width: 100%;
  height: 75%;
  cursor: pointer;
}
.up-modal-header{
  display: flex;
  justify-content: end;
}
.modal-preview{
  object-fit: cover;
  width: 100%;
}
.up-required{
  border: 1px solid red
}

.embedded-input {
  margin-left: 10px;
  width: 100%;
}
</style>