<template>
  <div
    class="blog-item-wrapper"
    @click.native="goToNews"
  >
    <span>
      {{ blogItem.title }}
    </span>
    <p>
      {{ blogItem.subtitle }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    blogItem: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    goToNews() {
      this.$router.push({ name: "single news", params: { id: this.blogItem.id } });
    }
  }
};
</script>

<style lang="scss" scoped>
  .blog-item {
    &-wrapper {
      background: var(--bg-image);
      background-size: cover;
      display: flex;
      padding: 73px 0 0 15px;
      flex-direction: column;
      width: 220px;
      height: 140px;
      border-radius: 20px;
      text-decoration: none;
      &:hover {
        cursor: pointer;
      }
      span {
        font-weight: 500;
        font-size: 16px;
        color: white;
        margin-bottom: 2px;
      }
      p {
        margin-bottom: 0;
        color: var(--color-20);
        font-weight: 450;
        font-size: 11px;
        line-height: 120%;
      }
    }
  }
</style>