<template>
  <div>
    <AuBreadcrumb
      :pages="breadcrumb"
    />

    <div
      v-if="$route.params.id"
      class="card request"
    >
      <ProgressLoader
        v-if="loading"
        mode="indeterminate"
        color="#5AC396"
        size="150px"
      />

      <div v-else>
        <div>
          <div class="d-flex justify-content-between token-header">
            <div class="logo-wrap">
              <img
                class="logo"
                :src="tokenIcon"
                alt="cryptoactive"
              >
              {{ tokenName }}
            </div>
            <div>
              <span>1 {{ mnemonic }} <AuIcon
                class="mb"
                icon="swap_horizontal_green"
              /> <AuDecimalStatic
                :value="course"
                :show-addition="false"
              />  {{ defaultTokenMnemonic }}</span>
            </div>
          </div>
        </div>

        <div>
          <span class="balance">

            <div class="token-balance">
              {{ $t("tokeninfo.tokenBalance") }}
            </div>

            <AuDecimalStatic
              class="balance__value"
              :value="balance"
              :decimal="decimals"
              :indent="indent"
              :addition="mnemonic"
            />

          </span>
        </div>

        <form
          class="form"
          @submit.prevent="submit"
        >
          <div class="form-inner">
            <div class="form-line">
              <div class="input-title">
                {{ $t("sendToken.address") }}
              </div>
              <div class="address-line">
                <div class="address-input-wrapper">
                  <AuInput
                    v-model="transferWallet"
                    class="send-tocken-input"
                    type="Mask"
                    :mask="/^[0xa-fA-F0-9]+$/i"
                    :placeholder="$t('sendToken.addressPlaceholder')"
                  />
                  <div
                    v-if="transferWallet && !isCorrectETH"
                    class="error"
                  >
                    {{ $t('sendToken.incorrectAddress') }}
                  </div>
                </div>
                <AuButton
                  bordered
                  border-radius="10"
                  center
                  width="180px"
                  height="45px"
                  @click="selectFromCounterparties = true"
                >
                  {{ $t('sendToken.myCounterparties') }}
                </AuButton>
              </div>
              <div
                v-if="selectedCounterparty"
                class="is-counterparty"
              >
                <span>
                  {{ $t('sendToken.haveCounterparty') }}
                </span>
                <div>{{ selectedCounterparty }}</div>
              </div>
              <div
                v-else-if="isCorrectETH && !selectedCounterparty"
                class="no-counterparty"
              >
                <span>{{ $t('sendToken.noCounterparty') }}</span>

                <div class="input-title">
                  {{ $t('sendToken.newCounterpartyNameLabel') }}
                </div>
                <div class="address-line">
                  <AuInput
                    v-model="newCounterpartyName"
                    class="send-tocken-input"
                    type="Mask"
                    :placeholder="$t('sendToken.newCounterpartyNamePlaceholder')"
                  />
                  <AuButton
                    bordered
                    border-radius="10"
                    center
                    :class="{ 'added': added }"
                    width="180px"
                    height="45px"
                    @click="save"
                  >
                    {{ added ?
                      $t('sendToken.newCounterpartyAdded') :
                      $t('sendToken.newCounterpartyAdd') }}
                  </AuButton>
                </div>
              </div>
            </div>

            <div class="form-line">
              <div class="input-title">
                {{ $t('sendToken.transferSum') }}
              </div>
              <div class="d-flex align-items-center">
                <AuDecimalInput
                  :value="transferAmount"
                  :decimal="decimals"
                  :indent="indent"
                  :placeholder="$t('sendToken.transferSumPlaceholder')"
                  @input="value => transferAmount = value"
                />
                <span class="mnemonic">{{ mnemonic }}</span>
              </div>
            </div>

            <div class="buttons-wrapper">
              <div class="d-flex justify-content-between gap-3">
                <AuButton
                  bordered
                  width="120px"
                  height="44px"
                  center
                  :border-radius="10"
                  type="reset"
                  @click="reset"
                >
                  {{ $t('sendToken.cancel') }}
                </AuButton>
                <AuButton
                  bordered
                  class="submit-mobile"
                  width="140px"
                  height="44px"
                  center
                  :border-radius="10"
                  active-bg-color="#279063"
                  type="submit"
                  :disabled="!isCorrectETH || isTransactionActive"
                  @click="confirmationMethodChoice = true"
                >
                  {{ $t('sendToken.send') }}
                </AuButton>
              </div>

              <div class="right-btns">
                <AuButton
                  bordered
                  width="100%"
                  center
                  height="44px"
                  icon="metamask"
                  :border-radius="10"
                  :disabled="!isCorrectETH || !isVerified"
                  @click="sendMetamaskToken"
                >
                  {{ $t('sendToken.sendTroughCryptoWallet') }}
                </AuButton>
                <AuButton
                  bordered
                  class="submit-desktop"
                  width="125px"
                  height="44px"
                  center
                  type="primary"
                  :disabled="!isCorrectETH || isTransactionActive"
                  @click="confirmationMethodChoice = true"
                >
                  {{ $t('sendToken.send') }}
                </AuButton>
              </div>
            </div>

            <div v-if="!isVerified">
              <div class="my-3">
                <div
                  class="alert alert-warning"
                  role="alert"
                >
                  {{ $t('sendToken.addressNotVerified') }}
                </div>
              </div>
            </div>
          </div>
        </form>
        <AuModal
          v-model="selectFromCounterparties"
          width="420px"
        >
          <template #header>
            <span class="modal_head_text">
              {{ $t('sendToken.form.label') }}
            </span>
          </template>
          <template #body>
            <div class="d-flex modal-wrapper">
              <div class="search-wrapper">
                <AuInput
                  v-model="search"
                  icon="search"
                  clearable
                  :placeholder="$t('sendToken.form.searchPlaceholder')"
                />
              </div>
              <div class="list-wrapper">
                <div
                  v-for="(value, key) in shownCounterparties"
                  :key="value"
                  class="section-wrapper"
                >
                  <div class="letter-wrapper">
                    {{ key }}
                  </div>
                  <div
                    v-for="val in value"
                    :key="val"
                    class="result-wrapper"
                    @click="selectCounterparty(val)"
                  >
                    {{ val.name }} <br> <span>{{ $filters.shortAddress(val.address) }}</span>
                  </div>
                </div>
              </div>
              <div
                v-if="noResult"
                class="no-result"
              >
                <span>{{ $t("sendToken.form.noSearchResults") }}</span>
              </div>
            </div>
          </template>
        </AuModal>
        <AuModal
          v-model="confirmationMethodChoice"
          width="420px"
        >
          <template #header>
            <span class="modal_head_text">
              {{ $t("sendToken.conformationModal.title") }}
            </span>
          </template>
          <template #body>
            <div class="d-flex modal-code-wrapper">
              <AuSelect
                :value="confirmationType"
                :options="confirmationOptions"
                :select-label="$t('sendToken.conformationModal.selectLabel')"
                :placeholder="$t('sendToken.conformationModal.selectPlaceholder')"
                @input="value => confirmationType = value"
              />

              <AuButton
                v-if="!showConfirmationCodeInput"
                bordered
                height="44px"
                center
                :border-radius="10"
                :disabled="!confirmationType"
                :is-progress="isRequestSend"
                @click="confirmationTypeSelect"
              >
                {{ $t("sendToken.conformationModal.confirmationTypeSelectButton") }}
              </AuButton>

              <AuInput
                v-if="showConfirmationCodeInput"
                v-model="confirmationCode"
                class="code-input"
                :placeholder="$t('sendToken.conformationModal.confirmationCodePlaceholder')"
              />

              <AuButton
                v-if="confirmationType==='mailCode' && showConfirmationCodeInput"
                bordered
                center
                height="44px"
                :border-radius="10"
                active-bg-color="#279063"
                :disabled="timer!==0"
                @click="sendMail"
              >
                {{ sendBtnText }}
              </AuButton>

              <AuButton
                v-if="showConfirmationCodeInput"
                bordered
                class="submit-desktop"
                height="44px"
                center
                :border-radius="10"
                active-bg-color="#279063"
                type="submit"
                :disabled="!confirmationCode"
                @click="checkCode"
              >
                {{ $t('sendToken.send') }}
              </AuButton>
            </div>
          </template>
        </AuModal>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import CurrencySupport from "@/help/currencySupport";
import MetaMask from "@/mixins/MetaMask";
import moment from "moment";
import ProgressLoader from "@/loaders/progress-bar";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    ProgressLoader
  },
  mixins: [MetaMask],
  props: {
    id: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      loading: false,
      transferWallet: null,
      transferAmount: null,
      currencySup: new CurrencySupport(),
      selectFromCounterparties: false,
      search: null,
      newCounterpartyName: "",
      added: false,
      isTransactionActive: false,
      balance: 0,
      owner: null,
      decimals: null,
      indent: null,
      confirmationMethodChoice: false,
      confirmationType: null,
      showConfirmationCodeInput: false,
      confirmationCode: null,
      timer: null,
      isRequestSend: false,
      eventId: null
    };
  },
  computed: {
    ...mapGetters({
      language: "language",
      auOwners: "auOwners",
      tokens: "tokens",
      counterparties: "counterparties",
      accountAddress: "accountAddress",
      defaultTokenMnemonic: "defaultToken/defaultTokenMnemonic",
      arrEth: "ethAddresses"
    }),

    breadcrumb() {
      return [
        { key: 0, name: "Dashboard", path: "/" },
        { key: 1, name: this.$t("router.pages.briefcase"), path: "/briefcase" },
        { key: 2, name: this.tokenName, path: `/token-info/${this.id}` }
      ];
    },

    course() {
      let currency = this.currencySup.getCurrency(this.id, this.defaultTokenMnemonic);
      return currency ? Number(currency).toFixed(2) : 0;
    },

    isVerified() {
      return _.some(this.arrEth, item => item.verifiedAt);
    },

    isOn2Fa() {
      return this.$auth.user().is2faEnabled;
    },

    confirmationOptions() {
      let confirmationOptions = [];
      if (this.isOn2Fa) {
        confirmationOptions.push({ display: this.$t("sendToken.conformationModal.twofaСonfirmationOption"), value: "2faCode" });
      }
      else {
        confirmationOptions.push({ display: this.$t("sendToken.conformationModal.emailСonfirmationOption"), value: "mailCode" });
      }
      return confirmationOptions;
    },

    isCorrectETH() {
      return this.$filters.correctETH(this.transferWallet);
    },

    counterpartiesDictionary() {
      let counterparties = _.cloneDeep(this.counterparties);
      let dict = {};
      counterparties.forEach(counterparty => {
        if (!(counterparty.name[0] in dict)) {
          dict[counterparty.name[0]] = [counterparty];
        }
        else {
          dict[counterparty.name[0]].push(counterparty);
        }
      });
      const sortObject = o => Object.keys(o).sort().reduce((r, k) => (r[k] = o[k], r), {});
      return sortObject(dict);
    },

    noResult() {
      return _.isEmpty(this.shownCounterparties);
    },

    shownCounterparties() {
      if (this.search) {
        let shown = _.cloneDeep(this.counterpartiesDictionary);
        let finded = {};
        for (let key in shown) {
          shown[key].forEach(counterparty => {
            if (_.includes(counterparty.name.toLowerCase(), this.search.toLowerCase()) || _.includes(counterparty.address, this.search)) {
              if (!(counterparty.name[0] in finded)) {
                finded[counterparty.name[0]] = [counterparty];
              }
              else {
                finded[counterparty.name[0]].push(counterparty);
              }
            }
          });
        }
        return finded;
      }
      else {
        return this.counterpartiesDictionary;
      }
    },

    selectedCounterparty() {
      let counterparty = _.find(this.counterparties, { address: this.transferWallet });
      if (counterparty) {
        // let splittedName = counterparty.name.split(" ");
        // let abbrName;
        // if (splittedName.length > 2) {
        //   abbrName = `${splittedName[0]} ${splittedName[1][0]}.`;
        // }
        // else {
        //   abbrName = splittedName[0];
        // }
        return counterparty.name;
      }
      return null;
    },

    tokenName() {
      return _.find(this.tokens, token => token.id == this.$route.params.id).nameEng;
    },

    mnemonic() {
      return _.find(this.tokens, token => token.id == this.$route.params.id).mnemonicEng;
    },

    tokenIcon() {
      return (this.correctToken?.logoMain ?? this.correctToken?.mainImage?.name) ?? "/svg/companies/s_logo.svg";
    },

    sendBtnText() {
      return this.timer === 0 ? this.$t("sendToken.form.sendCode") :
        this.$t("sendToken.form.sendAgain") + this.timer + this.$t("sendToken.form.sec");
    }
  },
  watch: {
    confirmationMethodChoice() {
      this.confirmationType = null,
      this.showConfirmationCodeInput = false;
    },
    confirmationType() {
      this.showConfirmationCodeInput = false;
      this.confirmationCode = null;
    },
    transferWallet() {
      this.added = false;
      this.newCounterpartyName = "";
    },
    selectFromCounterparties(val) {
      if (!val) {
        this.search = "";
      }
    }
  },
  async mounted() {
    this.loading = true;
    if (_.isEmpty(this.counterparties)) {
      await this.getCounterparties();
    }
    const data = await this.getAuOwnerByTokenId({ tokenId: this.id, ownerAddress: this.arrEth[0].value });
    this.owner = data[0];
    this.balance = this.owner.amount;
    this.decimals = this.owner.auToken.decimals;
    this.indent = this.owner.auToken.indent;
    this.loading = false;
  },
  methods: {
    ...mapActions({
      postCounterparty: "postCounterparty",
      sendTokenEvents: "sendTokenEvents",
      getAuOwnerByTokenId: "getAuOwnerByTokenId",
      getCounterparties: "getCounterparties",
      sendMailConfirmation: "withdrawal/sendMailConfirmation",
      confirmMailCode: "withdrawal/confirmMailCode"
    }),

    async confirmationTypeSelect() {
      this.isRequestSend = true;
      await this.sendRequest();
      if (this.confirmationType === "mailCode") {
        this.startTimer();
      }
      this.showConfirmationCodeInput = true;
    },

    async sendMail() {
      try {
        await this.sendMailConfirmation(this.eventId);
        this.startTimer();
      }
      catch (e) {
        this.$toast.error(this.$t("sendToken.sendCodeError"));
      }
    },

    startTimer() {
      this.timer = 60;
      const interval = setInterval(() => {
        this.timer--;
        if (this.timer === 0) {
          clearInterval(interval);
        }
      }, 1000);
    },

    async checkCode() {
      const params = {
        code: this.confirmationCode,
        tokenEvent: `/api/token_events/${this.eventId}`
      };
      try {
        await this.confirmMailCode(params);
        this.$toast.success(this.$t("toasts.success.tokenSend"));
        this.confirmationMethodChoice = false;
        this.$router.push({ name: "briefcase" });
      }
      catch (e) {
        this.$toast.error(this.$t("sendToken.wrongCode"));
        return false;
      }
      return true;
    },

    async sendMetamaskToken() {
      let token = _.find(this.tokens, token => token.id == this.id);
      const chain = {
        chainId: "0x6f1",
        chainName: "Soluchain 2024 (testnet)",
        rpcUrls: ["https://node-avx-1.soluchain.ru/ext/bc/8g5w4VShgxZGubrvK8L7zNN6rchkYnreUrsCDhLVPP7wLnXmZ/rpc"],
        blockExplorerUrls: [process.env.VUE_APP_SCAN_BASE_URL],
        nativeCurrency: null
      };

      if (token.createdAddress) {
        try {
          await this.sendToken(token, this.transferWallet, this.transferAmount, this.accountAddress, chain);

          this.$toast.success(this.$t("toasts.success.tokenSend"));
        }
        catch (error) {
          this.$toast.error(this.$t("toasts.error.tokenSend"));
        }
      }
    },

    reset() {
      this.$router.push({ name: "briefcase" });
    },

    selectCounterparty(val) {
      this.transferWallet = val.address;
      this.selectFromCounterparties = false;
    },

    async save() {
      try {
        await this.postCounterparty({
          name: this.newCounterpartyName,
          address: this.transferWallet,
          status: "Контрагент",
          user: `/api/users/${this.$auth.user().id}`
        });
        this.added = true;
        this.$toast.success(this.$t("toasts.success.contactCreate"));
      }
      catch (error) {
        this.$toast.error(this.$t("toasts.error.contactCreate"));
      }
    },

    async sendRequest() {
      this.isTransactionActive = true;
      try {
        let params = {
          user: `api/users/${this.$auth.user().id}`,
          createdAt: moment().format("DD.MM.YYYY"),
          type: "token_transfer",
          description: "send",
          value: this.transferAmount,
          addressAfter: this.transferWallet,
          token: `api/au_tokens/${this.$route.params.id}`,
          address: this.arrEth[0]?.value || null,
          isTask: true,
          locale: this.language
        };
        const resp = await this.sendTokenEvents(params);
        this.eventId = resp.data.id;
      }
      catch (err) {
        this.$toast.error(this.$t("toasts.error.tokenSend"));
      }
      this.isTransactionActive = false;
      this.isRequestSend = false;
    }
  }
};
</script>
<style lang="scss">
.send-tocken-input {
  .input_block {
    height: 44px;
  }
}
</style>
<style lang="scss" scoped>
.form {
  margin-top: 45px;
  position: relative;
}

.form-inner {
  display: flex;
  gap: 35px;
  flex-direction: column;
}

.form-request-result {
  position: absolute;
  visibility: hidden;
  opacity: 0;

  top: 50%;
  left: 50%;
}

.buttons-wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: -10px;
  gap: 15px;

  .submit-mobile {
    display: none;
  }

  .right-btns {
    display: flex;
    gap: 15px;
  }

  @media screen and (max-width:560px) {
    flex-direction: column;

    .submit-mobile {
      display: block;
    }

    .submit-desktop {
      display: none;
    }
  }
}

.input {
  height: 45px;
  border: 1px solid var(--color-80);
  color: var(--color-80);
  padding: 15px;
  border-radius: 14px;
  width: 100%;

  &:not(:placeholder-shown) {
    color: var(--text-color);
  }
}

.input-title {
  font-size: 14px;
  color: var(--color-60);
  margin-bottom: 15px;
}

.address-line {
  display: flex;
  gap: 20px
}

.request {
  width: 100%;
  padding: 20px 22px 22px 20px;

  &-header {
    margin-top: 50px;
  }
}

.btn_metamask {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 8px;
}

.mnemonic {
  position: absolute;
  right: 15px;
  color: var(--color-60);
  font-size: 14px;
}

.modal-wrapper {
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  margin: 0px -10px;
}

.no-result {
  padding: 0 30px;

  span {
    font-size: 14px;
  }
}

.address-input-wrapper {
  width: 100%;
  position: relative;

  .error {
    font-size: 12px;
    margin-top: 5px;
    position: absolute;
  }
}

.search-wrapper {
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 0 30px;
  margin-bottom: 30px;
}

.is-counterparty {
  span {
    color: #2ABA7C;
    font-size: 12px;
    display: block;
    margin-top: 15px;
    margin-bottom: 20px;
  }

  div {
    padding: 12px 15px;
    font-size: 12px;
    border: 1px solid #373737;
    border-radius: 100px;
    width: fit-content;
  }
}

.no-counterparty {
  span {
    color: #D17440;
    font-size: 12px;
    display: block;
    margin-top: 15px;
    margin-bottom: 20px;
  }
}

.letter-wrapper {
  font-size: 12px;
  padding: 10px 30px;
  background: #262626;
}

.section-wrapper {
  .result-wrapper {
    padding: 15px 30px;
    display: flex;
    flex-direction: column;

    span {
      font-size: 12px;
      color: var(--color-60);
    }

    &:not(:last-child) {
      border-bottom: 2px solid #373737;
    }

    &:hover {
      background: #4d4d4d;
      cursor: pointer;
    }
  }
}

.modal-code-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.added {
  border-color: #2ABA7C;
}

.token-header {
  align-items: center;
  margin-bottom: 25px;

  .logo-wrap {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .logo {
    height: 25px;
  }
}

.balance {
  display: flex;
  flex-direction: column;
  line-height: 0.9;
  gap: 6px;

  &__value {
    width: 600px;
    font-size: 24px;
  }

  &__string {
    font-size: 12px;
    color: var(--color-50)
  }
}

.token-balance {
  font-size: 14px;
  color: var(--color-30);
}

.mr {
  margin-right: 6px;
}
.mb {
  margin-bottom: 4px;
}
</style>