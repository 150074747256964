import actions from "./actions";

export default {
  namespaced: true,
  state: {
    cards: [],
    dataLoading: false
  },

  getters: {
    cards: state => {
      return state.cards;
    },

    dataLoading: state => {
      return state.dataLoading;
    }
  },

  mutations: {
    setCards(state, cards) {
      state.cards = cards;
    },

    setDataLoading(state, dataLoading) {
      state.dataLoading = dataLoading;
    }
  },

  actions
};
