<template>
  <div
    class="main-panel"
    :no-column-reverse="noColumnReverse"
  >
    <div
      :adaptive="$route.meta.adaptiveRightPanel"
      class="main-panel__center"
    >
      <div>
        <WizardEmail class="app-wizard" />
        <IssuerWizard
          v-if="isIssuer"
          class="app-wizard"
        />
        <Wizard
          v-else
          class="app-wizard"
        />
        <RouterView />
      </div>

      <div class="footer">
        <Footer
          divider
        />
      </div>
    </div>
  </div>
</template>

<script>
import Footer from "@/panels/Footer.vue";
import IssuerWizard from "@/components/wizard/IssuerWizard";
import Wizard from "@/components/wizard/InvestingWizard.vue";
import WizardEmail from "@/components/wizard/EmailWizard.vue";

export default {
  components: {
    IssuerWizard,
    Footer,
    WizardEmail,
    Wizard
  },

  computed: {
    noColumnReverse() {
      return this.$route.meta?.noColumnReverse;
    },

    isIssuer() {
      return this.$auth.user().type == "issuer";
    }
  }
};
</script>

<style lang="scss" scoped>
.footer {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.main-panel {
  display: flex;

  &[no-column-reverse] {
    @media screen and (max-width: 1200px) {
      flex-direction: column;
    }
  }

  &__center {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-x: auto;
    overflow-y: auto;
    padding: 0 25px 15px 25px;
    background-color: var(--main-color);
    @media screen and (max-width: 840px) {
      padding: 0 15px;
    }

    &:not([adaptive="true"]) {
      @media screen and (max-width: 1200px) {
        overflow-x: auto;
      }
    }

    &:has(.projects) {
      overflow-y: scroll;
    }

    &:has(.dashboard) {
      overflow-y: scroll;
    }

  }
}

.app-wizard {
  margin: 20px 0;
  &:nth-of-type(1) {
    margin-bottom: 20px;
  }
  &:nth-of-type(2) {
    margin-top: 15px;
  }
}
</style>
