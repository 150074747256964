import StateControllerBase from "@/store/stateControllerBase";
import { setLoadingState } from "@/store/setLoadingState";

import DaoController from "@/api/daoController";

const daoController = new DaoController();

class Actions extends StateControllerBase {
  setDataLoading({ commit }, dataLoading) {
    commit("setDataLoading", dataLoading);
  }

  @setLoadingState
  async getDaoRequests({ commit }, { page, itemsPerPage, tokenDaoId, isFinished }) {
    const resp = await daoController.getDaoRequests(page, itemsPerPage, tokenDaoId, isFinished);
    commit("setDaoRequests", resp.data);
    commit("setDaoRequestsTotal", resp.totalItems);
  }

  @setLoadingState
  async getDaoEvents({ commit }, params) {
    const resp = await daoController.getDaoEvents(params);
    commit("setDaoEvents", resp.data);
    commit("setDaoEventsTotal", resp.totalItems);
  }

  @setLoadingState
  async getToken({ commit }, id) {
    commit("setToken", await daoController.getToken(id));
  }

  @setLoadingState
  async getVoitingTokens({ commit }) {
    commit("setVoitingTokens", await daoController.getVoitingTokens());
  }

  async getDaoElements({ commit }, requestId) {
    const resp = await daoController.getDaoElements(requestId);
    commit("setDaoElements", resp);
  }

  async addDaoRequest(_context, body) {
    await daoController.addDaoRequest(body);
  }

  async editDaoRequest(_context, { id, body }) {
    await daoController.editDaoRequest(id, body);
  }

  async deleteDaoRequest(_context, id) {
    await daoController.deleteDaoRequest(id);
  }

  async addDaoElement(_context, body) {
    await daoController.addDaoElements(body);
  }

  async editDaoElement(_context, { id, body }) {
    await daoController.editDaoElement(id, body);
  }

  async deleteDaoElement(_context, id) {
    await daoController.deleteDaoElement(id);
  }
}

export default (new Actions).asPlainObject();