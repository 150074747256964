<template>
  <div class="registration-form-page-wrapper">
    <div class="registration-form-header">
      <div class="wrapper">
        <AuIcon icon="logo" />
        <div class="progress-line">
          <div
            v-for="step in registrationSteps"
            :key="step.stepNum"
            class="step-wrapper"
          >
            <div
              class="step-round"
              :class="{'current-step': currentStep == step.stepNum, 'passed-step': currentStep > step.stepNum}"
              @click.native="setProgress(step)"
            >
              {{ step.stepNum }}
            </div>
            <div
              v-if="step.stepNum != registrationSteps.length"
              class="sep"
            />
          </div>
        </div>
        <div class="loc-wrapper">
          <AuSelect
            :value="language"
            :allow-empty="false"
            :options="languages"
            @input="value => setLocale(value)"
          />
        </div>
      </div>
    </div>
    <div class="registration-form-main-panel">
      <div class="registration-form-sidebar">
        <ul>
          <li
            v-for="step in registrationSteps"
            :key="step.stepNum"
          >
            <div
              class="step-name"
              :class="{'current-step': currentStep == step.stepNum, 'passed-step': currentStep > step.stepNum}"
              @click.native="setProgress(step)"
            >
              {{ step.stepName }}
            </div>
          </li>
        </ul>
      </div>
      <div class="registration-form">
        <div
          ref="first_step"
          class="first-step current-step"
        >
          <h2>
            {{ $t('welcome.firstStep.label') }} <span class="highlight-bright">Autentic!</span>
          </h2>
          <div class="description">
            <p>
              {{ $t('welcome.firstStep.text.firstParagraph') }}
            </p>
            <p>
              {{ $t('welcome.firstStep.text.secondParagraph') }}
            </p>
            <p>
              {{ $t('welcome.firstStep.text.thirdParagraph') }}
            </p>
            <p>
              {{ $t('welcome.firstStep.text.fourthParagraph') }}
            </p>
          </div>
          <div class="card">
            <ul>
              <li class="highlight-dim">
                {{ $t('welcome.firstStep.list.firstLine.highlight') }}<span>{{ $t('welcome.firstStep.list.firstLine.text') }}</span>
              </li>
              <li class="highlight-dim">
                {{ $t('welcome.firstStep.list.secondLine.highlight') }}<span>{{ $t('welcome.firstStep.list.secondLine.text') }}</span>
              </li>
              <li class="highlight-dim">
                {{ $t('welcome.firstStep.list.thirdLine.highlight') }}<span>{{ $t('welcome.firstStep.list.thirdLine.text') }}</span>
              </li>
              <li class="highlight-dim">
                {{ $t('welcome.firstStep.list.fourthLine.highlight') }}<span>{{ $t('welcome.firstStep.list.fourthLine.text') }}</span>
              </li>
            </ul>
          </div>
          <p>
            {{ $t('welcome.firstStep.text.fifthParagraph') }}
          </p>
          <div
            class="d-flex justify-content-center align-items-center button"
            @click="currentStep++"
          >
            <span>{{ $t('welcome.nextButton') }}</span>
          </div>
        </div>
        <div
          ref="second_step"
          class="second-step"
        >
          <h2>
            {{ $t('welcome.secondStep.label') }}
          </h2>
          <p>
            {{ $t('welcome.secondStep.text') }}
          </p>
          <div class="select-role-wrapper">
            <h3>
              {{ $t('welcome.secondStep.subTitle') }}
            </h3>
            <div class="roles-wrapper">
              <div class="role">
                <div
                  class="role-circle"
                  :class="{'selected': request.role == 'investor'}"
                  @click.native="request.role = 'investor'"
                >
                  {{ $t('welcome.secondStep.investorRole.label') }}
                </div>
                <p class="role-subtext">
                  {{ $t('welcome.secondStep.investorRole.text') }}
                </p>
              </div>
              <div class="role">
                <div
                  class="role-circle"
                  :class="{'selected': request.role == 'issuer'}"
                  @click.native="request.role = 'issuer'"
                >
                  {{ $t('welcome.secondStep.issuerRole.label') }}
                </div>
                <p class="role-subtext">
                  {{ $t('welcome.secondStep.issuerRole.text') }}
                </p>
              </div>
            </div>
          </div>
          <div
            class="d-flex justify-content-center align-items-center button"
            @click="currentStep++"
          >
            <span>{{ $t('welcome.nextButton') }}</span>
          </div>
        </div>
        <div
          ref="third_step"
          class="third-step"
        >
          <h2>
            {{ selectedRole }}
          </h2>
          <p v-if="request.role">
            {{ $t("welcome.thirdStep.text") }}
          </p>
          <h3 v-if="request.role">
            {{ $t("welcome.thirdStep.subTitle") }}
          </h3>
          <div
            v-if="request.role"
            class="form-wrapper"
          >
            <div
              v-if="request.role == 'investor'"
              class="form-line"
            >
              <AuSelect
                :value="request.bankAccountType"
                :options="accountTypeOptions"
                :placeholder="$t('welcome.thirdStep.form.bankAccountTypePlaceholder')"
                required
                clearable
                :select-label="$t('welcome.thirdStep.form.bankAccountTypeLabel')"
                @input="value => request.bankAccountType = value"
                @clearValue="request.bankAccountType = null"
              />
            </div>
            <div
              v-if="request.role == 'issuer' || request.bankAccountType != 'individual'"
              class="form-line"
            >
              <AuInput
                v-model="request.nameRus"
                :placeholder="$t('welcome.thirdStep.form.nameRusPlaceholder')"
                required
                :disabled="request.role == 'investor' && !request.bankAccountType"
                :label="$t('welcome.thirdStep.form.nameRusLabel')"
              />
            </div>
            <div
              v-if="request.role == 'issuer' || request.bankAccountType != 'individual'"
              class="form-line"
            >
              <AuInput
                v-model="request.typeOfActivity"
                :placeholder="$t('welcome.thirdStep.form.typeOfActivityPlaceholder')"
                required
                :disabled="request.role == 'investor' && !request.bankAccountType"
                :label="$t('welcome.thirdStep.form.typeOfActivityLabel')"
              />
            </div>
            <div
              v-if="request.role == 'issuer' || request.bankAccountType != 'individual'"
              class="form-line cols"
            >
              <AuInput
                v-model="request.rusInn"
                :placeholder="$t('welcome.thirdStep.form.rusInnPlaceholder')"
                required
                :disabled="request.role == 'investor' && !request.bankAccountType"
                :label="$t('welcome.thirdStep.form.rusInnLabel')"
              />
              <AuInput
                v-model="request.rusBik"
                :placeholder="$t('welcome.thirdStep.form.rusBikPlaceholder')"
                required
                :disabled="request.role == 'investor' && !request.bankAccountType"
                :label="$t('welcome.thirdStep.form.rusBikLabel')"
              />
            </div>
            <div
              v-if="request.role == 'issuer' || request.bankAccountType != 'individual'"
              class="form-line"
            >
              <AuInput
                v-model="request.bank"
                :placeholder="$t('welcome.thirdStep.form.bankPlaceholder')"
                required
                :disabled="request.role == 'investor' && !request.bankAccountType"
                :label="$t('welcome.thirdStep.form.bankLabel')"
              />
            </div>
            <div class="form-line cols">
              <AuInput
                v-model="person.lastName"
                :placeholder="$t('welcome.thirdStep.form.lastNamePlaceholder')"
                required
                :disabled="request.role == 'investor' && !request.bankAccountType"
                :label="request.bankAccountType == 'individual' ? $t('welcome.thirdStep.form.lastNameLabelIndividual') : $t('welcome.thirdStep.form.lastNameLabelEntity')"
              />
              <AuInput
                v-model="person.firstName"
                :placeholder="$t('welcome.thirdStep.form.firstNamePlaceholder')"
                required
                :disabled="request.role == 'investor' && !request.bankAccountType"
              />
            </div>
            <div class="form-line cols">
              <AuInput
                v-model="person.patronymic"
                :placeholder="$t('welcome.thirdStep.form.patronymicPlaceholder')"
                required
                :disabled="request.role == 'investor' && !request.bankAccountType"
              />
              <AuInput
                v-if="request.bankAccountType != 'individual'"
                v-model="person.post"
                :placeholder="$t('welcome.thirdStep.form.postPlaceholder')"
                required
                :disabled="request.role == 'investor' && !request.bankAccountType"
              />
            </div>
            <div class="form-line">
              <AuSelect
                :value="request.country"
                :placeholder="$t('welcome.thirdStep.form.countryPlaceholder')"
                :options="countryOptions"
                required
                clearable
                :disabled="request.role == 'investor' && !request.bankAccountType"
                :select-label="$t('welcome.thirdStep.form.countryLabel')"
                @input="value => request.country = value"
                @clearValue="request.country = null"
              />
            </div>
            <div class="form-line">
              <AuInput
                v-model="request.city"
                :placeholder="$t('welcome.thirdStep.form.cityPlaceholder')"
                required
                :label="$t('welcome.thirdStep.form.cityLabel')"
                :disabled="request.role == 'investor' && !request.bankAccountType"
              />
            </div>
            <div class="form-line">
              <AuInput
                v-model="request.addressRus"
                :placeholder="$t('welcome.thirdStep.form.addressRusPlaceholder')"
                required
                :label="$t('welcome.thirdStep.form.addressRusLabel')"
                :disabled="request.role == 'investor' && !request.bankAccountType"
              />
            </div>
            <div class="form-line cols files-wrapper">
              <AuInput
                v-if="request.bankAccountType == 'individual'"
                class="width-limit"
                :model-value="passportFile"
                type="File"
                :label="$t('welcome.thirdStep.form.passportFileLabel')"
                required
                @update:modelValue="passportChanged"
              />
              <AuInput
                v-if="request.role == 'issuer'"
                class="width-limit"
                :model-value="charterFile"
                type="File"
                :label="$t('welcome.thirdStep.form.charterFileLabel')"
                required
                @update:modelValue="charterChanged"
              />
              <AuInput
                v-if="request.role == 'investor' && request.bankAccountType != 'individual'"
                class="width-limit"
                :model-value="lprFile"
                type="File"
                :label="$t('welcome.thirdStep.form.lprFileLabel')"
                required
                :disabled="request.role == 'investor' && !request.bankAccountType"
                @update:modelValue="passportDMChanged"
              />
              <AuInput
                v-if="request.bankAccountType != 'individual'"
                class="width-limit"
                :model-value="innFile"
                type="File"
                required
                :label="$t('welcome.thirdStep.form.innFileLabel')"
                :disabled="request.role == 'investor' && !request.bankAccountType"
                @update:modelValue="INNChanged"
              />
              <AuInput
                v-if="request.role == 'issuer'"
                class="width-limit"
                :model-value="ogrnFile"
                type="File"
                required
                :label="$t('welcome.thirdStep.form.ogrnFileLabel')"
                @update:modelValue="OGRNChanged"
              />
              <AuInput
                v-if="request.role == 'issuer'"
                class="width-limit"
                :model-value="egrulFile"
                type="File"
                required
                :label="$t('welcome.thirdStep.form.egrulFileLabel')"
                @update:modelValue="USRLEChanged"
              />
              <AuInput
                v-if="request.role == 'investor' && request.bankAccountType != 'individual'"
                class="width-limit"
                :model-value="registrationFile"
                type="File"
                required
                :label="$t('welcome.thirdStep.form.registrationFileLabel')"
                :disabled="request.role == 'investor' && !request.bankAccountType"
                @update:modelValue="regCertChanged"
              />
              <AuInput
                v-if="request.role == 'investor' && request.bankAccountType != 'individual'"
                class="width-limit"
                :model-value="ogrnFile"
                type="File"
                required
                :label="$t('welcome.thirdStep.form.ogrnEgrulFileLabel')"
                :disabled="request.role == 'investor' && !request.bankAccountType"
                @update:modelValue="OGRNChanged"
              />
              <AuInput
                v-if="request.role == 'issuer'"
                class="width-limit"
                :model-value="lprFile"
                type="File"
                required
                :label="$t('welcome.thirdStep.form.lprFileLabel')"
                @update:modelValue="passportDMChanged"
              />
              <AuInput
                v-if="request.role == 'issuer'"
                class="width-limit"
                :model-value="cardFile"
                type="File"
                required
                :label="$t('welcome.thirdStep.form.cardFileLabel')"
                @update:modelValue="companyCardChanged"
              />
              <AuInput
                v-if="request.role == 'issuer'"
                class="width-limit"
                :model-value="protocolFile"
                type="File"
                required
                :label="$t('welcome.thirdStep.form.protocolFileLabel')"
                @update:modelValue="protocolChanged"
              />
            </div>
            <div class="form-line">
              <div class="input-check">
                <input
                  id="exampleRadios1"
                  v-model="tosAgree"
                  :class="{'empty' : !tosAgree}"
                  class="input-check__input"
                  type="checkbox"
                  :disabled="request.role == 'investor' && !request.bankAccountType"
                >
                <label
                  class="input-check__label"
                  for="exampleRadios1"
                >
                  {{ $t('welcome.thirdStep.form.tosAgree.text') }}
                  <span class="highlight-bright">{{ $t('welcome.thirdStep.form.tosAgree.highlightToS') }}</span>
                  {{ $t('welcome.thirdStep.form.tosAgree.and') }}<span class="highlight-bright">{{ $t('welcome.thirdStep.form.tosAgree.highlightPP') }}</span>
                </label>
              </div>
            </div>
            <div class="form-line semigap">
              <div class="input-check">
                <input
                  id="exampleRadios1"
                  v-model="newsLettersAgree"
                  class="input-check__input"
                  type="checkbox"
                  :disabled="request.role == 'investor' && !request.bankAccountType"
                >
                <label
                  class="input-check__label"
                  for="exampleRadios1"
                >
                  {{ $t('welcome.thirdStep.form.newsLettersAgree') }}
                </label>
              </div>
            </div>
            <div class="form-line buttons d-flex">
              <div
                class="d-flex justify-content-center align-items-center button"
                @click="currentStep--"
              >
                <span>{{ $t('welcome.backButton') }}</span>
              </div>
              <div
                class="d-flex justify-content-center align-items-center button"
                @click="formNext"
              >
                <span>{{ $t('welcome.nextButton') }}</span>
              </div>
            </div>
            <div
              v-if="showError && fillingIndicator"
              class="error"
            >
              {{ $t('welcome.error') }}
            </div>
          </div>
        </div>
        <div
          ref="final_step"
          class="final-step"
        >
          <h2 v-if="!successfulSending && !request.role">
            {{ $t('welcome.finalStep.label.noRole') }}
          </h2>
          <h2 v-else-if="!successfulSending && request.role">
            {{ $t('welcome.finalStep.label.noData') }}
          </h2>
          <div v-else>
            <h2>
              {{ $t('welcome.finalStep.label.successfulSending') }}
            </h2>
            <p>
              {{ $t('welcome.finalStep.text') }}
            </p>
            <img
              src="/svg/companies/welcome.svg"
              :alt="$t('welcome.finalStep.label.successfulSending')"
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";

import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      currentStep: 1,
      tosAgree: false,
      newsLettersAgree: false,
      person: {
        firstName: null,
        lastName: null,
        patronymic: null,
        post: null
      },
      clearPerson: {
        firstName: null,
        lastName: null,
        patronymic: null,
        post: null
      },
      request: {
        role: null,
        nameRus: null,
        typeOfActivity: null,
        rusInn: null,
        rusBik: null,
        bank: null,
        decisionMaker: null,
        country: null,
        city: null,
        addressRus: null,
        charterFile: null,
        innFile: null,
        ogrnFile: null,
        egrulFile: null,
        lprFile: null,
        cardFile: null,
        protocolFile: null,
        passportFile: null,
        registrationFile: null,
        bankAccountType: null
      },
      clearRequest: {
        nameRus: null,
        typeOfActivity: null,
        rusInn: null,
        rusBik: null,
        bank: null,
        decisionMaker: null,
        country: null,
        city: null,
        addressRus: null,
        charterFile: null,
        innFile: null,
        ogrnFile: null,
        egrulFile: null,
        lprFile: null,
        cardFile: null,
        protocolFile: null,
        passportFile: null,
        registrationFile: null,
        bankAccountType: null
      },
      charterFile: null,
      innFile: null,
      ogrnFile: null,
      egrulFile: null,
      lprFile: null,
      cardFile: null,
      protocolFile: null,
      passportFile: null,
      registrationFile: null,
      showError: false,
      successfulSending: false
    };
  },

  computed: {
    ...mapGetters({
      country: "country",
      language: "language"
    }),

    registrationSteps() {
      return [
        {
          stepName: this.$t("welcome.firstStep.stepName"),
          stepNum: 1,
          stepRef: "first_step"
        },
        {
          stepName: this.$t("welcome.secondStep.stepName"),
          stepNum: 2,
          stepRef: "second_step"
        },
        {
          stepName: this.$t("welcome.thirdStep.stepName"),
          stepNum: 3,
          stepRef: "third_step"
        },
        {
          stepName: this.$t("welcome.finalStep.stepName"),
          stepNum: 4,
          stepRef: "final_step"
        }
      ];
    },

    accountTypeOptions() {
      return [{
        display: this.$t("welcome.thirdStep.form.bankAccountTypeEntity"),
        value: "entity"
      }, {
        display: this.$t("welcome.thirdStep.form.bankAccountTypeIndividual"),
        value: "individual"
      }];
    },

    languages() {
      return [
        { display: this.$t("settings.welcomeLanguage.ru"), value: "ru" },
        { display: this.$t("settings.welcomeLanguage.en"), value: "en" },
        { display: this.$t("settings.welcomeLanguage.uz"), value: "uz" }
      ];
    },

    countryOptions() {
      return _.map(this.country, c => ({ display: c.name, value: `/api/countries/${c.id}` }));
    },

    selectedRole() {
      return this.request.role ? this.request.role == "issuer" ?
        this.$t("welcome.thirdStep.label.issuerRole") :
        this.$t("welcome.thirdStep.label.investorRole") :
        this.$t("welcome.thirdStep.label.noRole");
    },

    roleRequest() {
      let roleRequest = {};
      if (this.request.role == "issuer") {
        roleRequest = {
          user: `/api/users/${this.$auth.user().id}`,
          role: this.request.role,
          nameRus: this.request.nameRus,
          typeOfActivity: this.request.typeOfActivity,
          rusInn: this.request.rusInn,
          rusBik: this.request.rusBik,
          bank: this.request.bank,
          decisionMaker: this.request.decisionMaker,
          country: this.request.country,
          city: this.request.city,
          addressRus: this.request.addressRus,
          charterFile: this.request.charterFile,
          innFile: this.request.innFile,
          ogrnFile: this.request.ogrnFile,
          egrulFile: this.request.egrulFile,
          lprFile: this.request.lprFile,
          cardFile: this.request.cardFile,
          protocolFile: this.request.protocolFile,
          newsLettersAgree: this.newsLettersAgree
        };
      }
      else if (this.request.role == "investor" && this.request.bankAccountType == "entity") {
        roleRequest = {
          user: `/api/users/${this.$auth.user().id}`,
          role: this.request.role,
          bankAccountType: this.request.bankAccountType,
          nameRus: this.request.nameRus,
          typeOfActivity: this.request.typeOfActivity,
          rusInn: this.request.rusInn,
          rusBik: this.request.rusBik,
          bank: this.request.bank,
          decisionMaker: this.request.decisionMaker,
          country: this.request.country,
          city: this.request.city,
          addressRus: this.request.addressRus,
          ogrnFile: this.request.ogrnFile,
          registrationFile: this.request.registrationFile,
          lprFile: this.request.lprFile,
          newsLettersAgree: this.newsLettersAgree
        };
      }
      else {
        roleRequest = {
          user: `/api/users/${this.$auth.user().id}`,
          role: this.request.role,
          nameRus: this.request.decisionMaker,
          bankAccountType: this.request.bankAccountType,
          decisionMaker: this.request.decisionMaker,
          country: this.request.country,
          city: this.request.city,
          addressRus: this.request.addressRus,
          passportFile: this.request.passportFile,
          newsLettersAgree: this.newsLettersAgree
        };
      }
      return roleRequest;
    },

    fillingIndicator() {
      return Object.values(this.roleRequest).length != _.filter(Object.values(this.roleRequest), value => value !== null && value !== "").length || !this.tosAgree;
    }
  },

  watch: {
    currentStep(val) {
      let curStep = _.find(this.registrationSteps, { stepNum: val });
      if (curStep) {
        this.registrationSteps.forEach(step => {
          this.$refs[step.stepRef].classList.remove("current-step");
        });

        this.$refs[curStep.stepRef].classList.add("current-step");
      }
    },

    "request.role"(newRole) {
      this.request = _.cloneDeep(this.clearRequest);
      this.request.role = newRole;
      this.showError = false;
    },

    "request.bankAccountType"(value) {
      let role = this.request.role;
      this.request = _.cloneDeep(this.clearRequest);
      for (let prop in this.person) {
        this.person[prop] = null;
      }
      this.request.role = role;
      this.request.bankAccountType = value;
      this.showError = false;
    },

    person: {
      handler(value) {
        this.request.decisionMaker = `${value.lastName || ""} ${value.firstName || ""} ${value.patronymic || ""} ${value.post || ""}`.trim().replace(/ {1,}/g, " ");
      },
      deep: true
    }
  },

  mounted() {
    if (_.isEmpty(this.country)) {
      this.getCountry();
    }
  },

  methods: {
    ...mapActions({
      addCompanyFromWelcome: "addCompanyFromWelcome",
      uploadFile: "files/uploadFile",
      setLanguage: "setLanguage",
      getCountry: "getCountry"
    }),

    setLocale(language) {
      this.setLanguage(language);
    },

    async formNext() {
      if (this.fillingIndicator) {
        this.showError = true;
      }
      else {
        this.showError = false;
        if (this.successfulSending) {
          this.$toast.success(this.$t("toasts.success.companySecondaryAdd"));
        }
        else {
          try {
            for (const key in this.roleRequest) {
              if (_.includes(key, "File")) {
                let file = await this.uploadFile(this.roleRequest[key]);
                this.request[key] = `/api/uploaded_files/${file.id}`;
              }
            }
            await this.addCompanyFromWelcome(this.roleRequest);
            this.successfulSending = true;
            this.$toast.success(this.$t("toasts.success.companyAdd"));
            this.currentStep++;

            this.$gtm.trackEvent({
              event: null, // Event type [default = 'interaction'] (Optional)
              label: "Успешная регистрация"
            });
          }
          catch (e) {
            this.successfulSending = false;
            this.$toast.error(this.$t("toasts.error.companyDelete"));
          }
        }
      }
    },

    async sendFile(file) {
      let upload = null;
      try {
        upload = this.uploadFile(file);
      }
      catch (e) {
        this.showError("Ошибка при загрузке файла");
      }
      return upload;
    },

    setProgress(step) {
      this.currentStep = step.stepNum;
    },

    charterChanged(file) {
      this.request.charterFile = file;
    },

    INNChanged(file) {
      this.request.innFile = file;
    },

    OGRNChanged(file) {
      this.request.ogrnFile = file;
    },

    USRLEChanged(file) {
      this.request.egrulFile = file;
    },

    regCertChanged(file) {
      this.request.registrationFile = file;
    },

    passportDMChanged(file) {
      this.request.lprFile = file;
    },

    passportChanged(file) {
      this.request.passportFile = file;
    },

    companyCardChanged(file) {
      this.request.cardFile = file;
    },

    protocolChanged(file) {
      this.request.protocolFile = file;
    }
  }
};
</script>

<style lang="scss" scoped>
.registration-form {
  width: 100%;
  padding: 60px 120px 120px;
  overflow: scroll;
  @media screen and (max-width: 1024px) {
    padding: 50px 40px 40px;
  }
  @media screen and (max-width: 640px){
    padding: 50px 20px 40px;
  }
  @media screen and (max-width: 360px){
    padding: 50px 15px 40px;
  }
  .first-step {
    display: flex;
    flex-direction: column;
    gap: 40px;
    max-width: 780px;
    h2 {
      font-size: 24px;
    }
    .card {
      padding: 20px;
      ul {
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-bottom: 0;
        padding-left: 20px;
        li {
          span {
            color: var(--text-color);
          }
        }
      }
    }
  }
  .second-step {
    flex-direction: column;
    max-width: 780px;
    h2 {
      font-size: 24px;
      margin-bottom: 20px;
    }
    p {
      color: var(--color-50);
      margin-bottom: 30px;
    }
    .select-role-wrapper {
      margin-bottom: 80px;
      h3 {
        font-weight: 450;
        font-size: 20px;
        color: var(--text-color);
        margin-bottom: 30px;
      }
      .roles-wrapper {
        display: flex;
        gap: 60px;
        @media screen and (max-width: 950px) {
          flex-direction: column;
          align-items: center;
        }
        .role {
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;
          gap: 20px;
          width: 280px;
          &-circle {
            border: 1px solid var(--color-40);
            border-radius: 100%;
            height: 280px;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            user-select: none;
            &.selected {
              border-color: #28E593;
            }

            &:hover {
              cursor: pointer;
            }
          }
          &-subtext {
            margin-bottom: 0;
            text-align: center;
            font-size: 12px;
          }
        }
      }
    }
  }
  .third-step {
    flex-direction: column;
    max-width: 780px;
    h2 {
      font-weight: 450;
      font-size: 24px;
      margin-bottom: 20px;
    }
    p {
      color: var(--color-50);
      margin-bottom: 30px;
    }
    h3 {
      font-weight: 450;
      font-size: 20px;
      color: var(--text-color);
      margin-bottom: 30px;
    }
    .form-wrapper {
      display: flex;
      flex-direction: column;
      gap: 30px;
      .form-line {
        &.cols {
          display: flex;
          gap: 20px;
          align-items: end;
          @media screen and (max-width: 406px) {
            flex-direction: column;
            gap: 30px;
          }
          &.files-wrapper {
            flex-wrap: wrap;
          }
        }
        &.buttons {
          gap: 15px;
          margin-top: 30px;
          &>.button {
            margin-top: 0;
            &:first-child {
              border-color: var(--color-50);
              width: 30%;
            }
          }
        }
        &.semigap {
          margin-top: -15px;
        }
      }
    }
  }
  .final-step {
    div {
      display: flex;
      flex-direction: column;
      h2 {
        font-weight: 450;
        font-size: 24px;
        margin-bottom: 20px;
      }
      p {
        color: var(--color-50);
      }
      img {
        width: 100%;
        max-width: 500px;
        @media screen and (max-width: 640px) {
          margin: 0 auto;
        }
      }
    }
  }

  .first-step,
  .second-step,
  .third-step,
  .final-step {
    display: none;
    &.current-step {
      display: flex;
    }
  }
  &-header {
    height: 68px;
    display: flex;
    padding: 19px 0 19px 25px;
    border-bottom: 1px solid var(--color-70);
    @media screen and (max-width: 640px) {
      padding: 19px 0 19px 20px;
    }
    @media screen and (max-width: 360px) {
      padding: 19px 0 19px 15px;
    }
    .loc-wrapper {
      width: 120px;
      flex-shrink: 0;
    }
    .wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-width: 1110px;
      gap: 223px;
      @media screen and (max-width: 1230px){
        padding-right: 10px;
      }
      @media screen and (max-width: 1024px){
        gap: 143px;
      }
      @media screen and (max-width: 640px){
        gap: 20px;
      }
      @media screen and (max-width: 490px){
        .icon {
          width: 70px;
        }
      }
      .progress-line {
        display: flex;
        width: 100%;
        margin-right: -183px;

        @media screen and (max-width: 1024px){
          margin-right: -103px;
        }

        @media screen and (max-width: 640px){
          margin-right: 0px;
        }

        .step-wrapper {
          display: flex;
          align-items: center;
          gap: 10px;
          width: 100%;
          margin-right: 10px;
          &:last-child {
            width: fit-content;
            margin-right: 0;
          }
          .step-round {
            border: 1px solid var(--color-50);
            border-radius: 100px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 30px;
            height: 30px;
            font-size: 12px;
            &:hover {
              cursor: pointer;
            }
            &.current-step {
              border-color: var(--text-color);
            }
            &.passed-step {
              border-color: #28E593;
            }
          }
          .sep {
            width: calc(100% - 40px);
            height: 1px;
            background: var(--color-50);
          }
          &:has(>.step-round.passed-step) {
            .sep {
              background: #28E593;
            }
          }
        }
      }
    }
  }
  &-sidebar {
    border-right: 1px solid var(--color-70);
    display: flex;
    padding: 60px 30px;
    @media screen and (max-width: 640px) {
      display: none;
    }
    ul {
      list-style-type: none;
      padding-left: 0;
      display: flex;
      flex-direction: column;
      margin-bottom: 0;
      gap: 25px;
      .step-name {
        color: var(--color-50);
        white-space: nowrap;
        &.current-step {
          color: var(--text-color);
          &:hover {
            color: var(--text-color);
          }
        }
        &.passed-step {
          color: #28E593;
        }
        &:hover {
          color: var(--color-60);
          cursor: pointer;
        }
      }
    }
  }
  &-page-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: var(--main-color);
    overflow: hidden;
  }
  &-main-panel {
    display: flex;
    height: calc(100% - 68px);
  }
}

.highlight {
  &-bright {
    color: #28E593;
  }
  &-dim {
    color: #2ABA7C;
  }
}

.input-check {
  display: flex;
  align-items: center;
  gap: 10px;
  padding-left: 0px;
  margin-bottom: 0;
  &:has(>input[disabled]){
    .input-check__input {
      border-color: var(--color-40);
      &:hover {
        cursor: auto
      }
    }
    .input-check__label {
      color: var(--color-40);
      span {
        color: var(--color-40);
      }
    }
  }
  &__input {
    min-width: 24px;
    height: 24px;
    border-radius: 6px;
    vertical-align: middle;
    cursor: pointer;
    border: 1px solid var(--text-color);
    appearance: none;
    -webkit-appearance: none;
    outline: none;
    position: relative;
    &.empty {
      border-color: #ff000069;
    }
    &:checked {
      &::before {
        content: "✓";
        width: 10px;
        height: 10px;
        position: absolute;
        top: calc(24px - 50% - 12px);
        right: calc(24px - 50% - 7px);
        color: #28E593;
        border-radius: 100%;
      }
    }
  }

  &__label {
    font-size: 12px;
    color: var(--color-80);
  }
}

.width-limit {
  max-width: 180px;
  @media screen and (max-width: 1024px) {
    max-width: 170px;
  }
  @media screen and (min-width:640px) and (max-width:691px) {
    max-width: 400px;
  }
  @media screen and (max-width: 406px) {
    max-width: 400px;
  }
}

.button{
  border: 1px dashed #28E593;
  border-radius: 14px;
  padding: 12px;
  width: 100%;
  margin-top: 20px;
  &:hover {
    cursor: pointer;
  }
}
.error {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  color: red;
}
</style>
