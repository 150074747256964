<template>
  <div class="tabs">
    <ul
      v-if="!loading"
      class="tabs_header"
    >
      <Swiper
        class="swiper"
        :navigation="true"
        :slides-per-view="slidesPerView"
        :hash-navigation="{
          watchState: true,
        }"
        :modules="modules"
      >
        <SwiperSlide
          v-for="(title, index) in tabTitles"
          :key="index"
        >
          <li
            v-if="!hiddens[index].status"
            :class="{ selected: title == selectedTitle, tabs_error: tabErrors[index].status }"
            @click="changeTab(title, index)"
          >
            {{ title }} {{ selectedTab }}
          </li>
        </SwiperSlide>
      </Swiper>
    </ul>
    <div class="tabs_info">
      <slot />
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { Navigation } from "swiper";
import { provide, ref, watch } from "vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import { useRoute } from "vue-router";

import "swiper/css";
import "swiper/css/navigation";

export default {
  name: "AuTabView",
  components: {
    Swiper,
    SwiperSlide
  },

  props: {
    activeTab: {
      type: String,
      default: null
    }
  },

  setup(props, { slots }) {
    const route = useRoute();
    const tabTitles = ref(slots.default().map((tab) => tab.props.title));
    const tabErrors = ref(slots.default().map(tab => ({
      title: tab.props.title,
      status: tab.props.showError === undefined ? false : tab.props.showError
    })
    )).value;
    const hiddens = ref(slots.default().map(tab => ({
      title: tab.props.title,
      status: tab.props.hidden === undefined ? false : tab.props.hidden
    })
    )).value;
    let selectedTitle = ref(tabTitles.value[0]);
    if (route.query?.selectedTitle) {
      let index = _.findIndex(tabTitles.value, (item) => item == route.query?.selectedTitle);
      if (index !== -1) {
        selectedTitle = ref(tabTitles.value[index]);
      }
    }

    watch(() => props.activeTab, (newActiveTab) => {
      if (newActiveTab && tabTitles.value.includes(newActiveTab)) {
        selectedTitle.value = newActiveTab;
      }
    });

    provide("selectedTitle", selectedTitle);
    provide("tabErrors", tabErrors);
    provide("hiddens", hiddens);
    return {
      selectedTitle,
      tabTitles,
      tabErrors,
      hiddens,
      modules: [Navigation]
    };
  },

  data() {
    return {
      slidesPerView: 0,
      loading: true
    };
  },

  mounted() {
    let doc = document.querySelector(".tabs");
    this.slidesPerView = Math.floor((doc.offsetWidth / 200));
    this.loading = false;
  },

  methods: {
    changeTab(t, i) {
      this.selectedTitle = t;
      this.$emit("change", t);
      this.$emit("changeIndex", i);
    }
  }
};
</script>

<style lang="scss">
.tabs {
  .tabs_header {
    .swiper-button-prev {
      display: none;;
    }

    .swiper-button-next {
      display: none;;
    }
  }
}

.tabs[resizable] {
  @media screen and (max-width:500px) {
    div.swiper-slide {
      padding-top: 0px;
      margin-top: 10px;
      height: auto !important;

      &:last-child {
        margin-bottom: 10px;
      }
    }

    div.swiper-wrapper {
      flex-direction: column !important;

      li {
        border-radius: 8px;
      }
    }
  }
}
</style>

<style scoped lang="scss">
div.swiper {
  margin-left: 0px;
  width: 100%;

  &-slide {
    padding-top: 20px;
    cursor: pointer;
    width: auto !important;
  }
}

.tabs_header {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;

  li {
    cursor: pointer;
    background: var(--card-color);
    color: var(--color-50);
    text-align: center;
    margin-right: 2px;
    padding: 0.625rem 1.25rem;
    border-radius: 8px 8px 0px 0px;
    transition: 0.2s ease-in-out;
    &:hover,
    &:focus {
      color: var(--text-color);
      &.tabs_error{
        color: red;
      }
    }
    &.selected {
      color: var(--text-color);
      &.tabs_error{
        color: red;
      }
    }
    &.tabs_error{
      color: #FFB7AF;
    }
    @media screen and (max-width:500px) {
      margin-right: 0px;
    }
  }
}
.tabs_info {
  background: var(--card-color);
  border-radius: 0 10px 10px 10px;
  @media screen and (max-width:500px) {
    border-radius: 10px;
  }
}
</style>
