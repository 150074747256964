<template>
  <div class="mi-form">
    <AuSelect
      :value="body.section_id"
      :options="capitalsOptions"
      :select-label="$t('goods.form.goodSectionLabel')"
      :placeholder="$t('goods.form.goodSectionPlaceholder')"
      clearable
      required
      @input="(value) => {body.section_id = value; sendBody()}"
      @clearValue="body.section_id = null"
    />

    <AuInput
      v-model="body.productName"
      clearable
      required
      :label="$t('goods.form.goodNameLabel')"
      :placeholder="$t('goods.form.goodNamePlaceholder')"
      @input="sendBody"
    />

    <AuInput
      v-model="body.description"
      type="TextArea"
      clearable
      :label="$t('goods.form.goodDescLabel')"
      :placeholder="$t('goods.form.goodDescPlaceholder')"
      @input="sendBody"
    />

    <AuSelect
      :value="body.company_id"
      :options="companiesOptions"
      :select-label="$t('goods.form.goodCompanyLabel')"
      :placeholder="$t('goods.form.goodCompanyPlaceholder')"
      clearable
      @input="(value) => {body.company_id = value; sendBody()}"
      @clearValue="body.company_id = null"
    />

    <div>
      <div class="mi-label">
        Тип товара
      </div>
      <div class="d-flex justify-content-between">
        <div>
          <input
            v-model="body.goodType"
            type="radio"
            name="type"
            value="single"
            checked
            @change="sendBody"
          >
          <span class="mi-text">
            Единичный товар
          </span>
        </div>
        <div>
          <input
            v-model="body.goodType"
            type="radio"
            name="type"
            value="multi"
            @change="sendBody"
          >
          <span class="mi-text">
            Количественный товар
          </span>
        </div>
        <div>
          <input
            v-model="body.goodType"
            type="radio"
            name="type"
            value="fragment"
            @change="sendBody"
          >
          <span class="mi-text">
            Криптоактив с дроблением
          </span>
        </div>
      </div>
    </div>

    <AuInput
      v-if="body.goodType !== 'single'"
      v-model="body.amount"
      type="Number"
      clearable
      required
      :label="body.goodType==='multi' ? $t('goods.form.goodCountLabel') : 'Количество частей'"
      :placeholder="body.goodType==='multi' ? $t('goods.form.goodCountLabel') : 'Количество частей'"
      @input="sendBody"
    />

    <AuSelect
      :value="body.units"
      :options="unitsOption"
      select-label="Единица измерения"
      placeholder="Выберите единицу измерения"
      clearable
      @input="(value) => {body.units = value; sendBody()}"
      @clearValue="body.units = null"
    />

    <AuInput
      v-model="body.placeOfShipment"
      type="TextArea"
      clearable
      label="Информация о доставке"
      placeholder="Информация о доставке"
      @input="sendBody"
    />

    <AuSelect
      :value="body.country"
      :options="countryOptions"
      select-label="Страна физического нахождения актива"
      placeholder="Выберите страну"
      clearable
      @input="(value) => {body.country = value; sendBody()}"
      @clearValue="body.country = null"
    />

    <AuInput
      v-model="body.matCity"
      clearable
      label="Город"
      placeholder="Введите название города"
      @input="sendBody"
    />

    <AuInput
      v-model="body.serialNumber"
      clearable
      :label="$t('goods.form.goodSerialNumLabel')"
      :placeholder="$t('goods.form.goodSerialNumPlaceholder')"
      @input="sendBody"
    />

    <AuInput
      v-model="body.internalCode"
      clearable
      label="Внутренний код (виден только текущему владельцу товара)"
      placeholder="Введите внутренний код"
      @input="sendBody"
    />
  </div>
</template>

<script>
import _ from "lodash";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "MainInformation",
  props: {
    modelValue: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    ...mapGetters({
      companies: "companies",
      sectionMarket: "sectionMarket",
      country: "country",
      units: "goods/units"
    }),

    companiesOptions() {
      let verifiedCompany = this.companies.filter((item) => {
        return item.isVerified == true;
      });
      return _.map(verifiedCompany, (company) => ({
        display: company.nameRus || company.nameEng,
        value: company.id
      }));
    },

    capitalsOptions() {
      return _.map(this.sectionMarket, section => ({ display: section.name, value: section.id }));
    },

    countryOptions() {
      return _.map(this.country, item => ({ display: item.name, value: `/api/countries/${item.id}` }));
    },

    unitsOption() {
      return _.map(this.units, item => ({ display: item.name, value: item.name }));
    },

    isError() {
      return _.isNull(this.body.section_id) ||
          _.isNull(this.body.productName) ||
          this.body.amount === "0";
    },

    body() {
      if (!_.isEmpty(this.modelValue)) {
        return this.modelValue;
      }
      else {
        return {
          section_id: null,
          productName: null,
          description: null,
          company_id: null,
          goodType: "single",
          amount: "1",
          units: null,
          matCity: null,
          country: null,
          placeOfShipment: null,
          serialNumber: null,
          internalCode: null
        };
      }
    }
  },
  async mounted() {
    if (_.isEmpty(this.units)) {
      await this.getUnits();
    }
    if (_.isEmpty(this.country)) {
      await this.getCountry();
    }
    if (_.isEmpty(this.sectionMarket)) {
      await this.getSectionMarket();
    }
    if (_.isEmpty(this.companies)) {
      await this.getCompanies();
    }
    this.sendBody();
  },
  methods: {
    ...mapActions({
      getUnits: "goods/getUnits",
      getSectionMarket: "getSectionMarket",
      getCompanies: "getCompanies",
      getCountry: "getCountry"
    }),

    sendBody() {
      if (this.body.goodType === "single") {
        this.body.isMultiToken = false;
        this.body.isFracted = false;
        this.body.amount = "1";
      }
      else {
        this.body.isMultiToken = true;
        this.body.isFracted = this.body.goodType === "fragment";
      }
      this.$emit("update:modelValue", this.body);
      this.$emit("error", this.isError);
    }
  }
};
</script>

<style scoped lang="scss">
.mi-form{
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  border-radius: 10px;
}
.mi-label{
  font-size: 14px;
  color: var(--color-50);
  margin-bottom: 15px;
}
.mi-text{
  font-size: 15px;
  color: var(--text-color);
  margin-bottom: 15px;
}
</style>