<template>
  <div class="panel">
    <Apexchart
      class="chart"
      :options="options"
      :series="[...series]"
      height="327"
    />

    <AuSelect
      :value="tokenId"
      :options="tokenOptions"
      @input="value => tokenId = value"
    />

    <div v-if="currentRate">
      Текущий курс - {{ currentRate }}
    </div>
    <div v-else>
      Нет данных
    </div>

    <div
      v-if="currentRate"
      class="token-table"
    >
      <AuTable
        :columns="columns"
        :items="debugItems"
        :loading="loading"
      />
    </div>

    <div class="token-table token-table_button">
      <AuTable
        :columns="pivotTableColumns"
        :items="pivotTableItems"
        :loading="loading"
      />
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import moment from "moment";
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      tokenId: 1,
      loading: true,
      columns: [{ name: "Дата" }, { name: "Значение валюты на выбранную дату", align: "end" }, { name: "Цена к основной валюте", align: "end" }, { name: "Результат перемножения", align: "end" }]
    };
  },

  computed: {
    ...mapGetters({
      history: "history",
      tokens: "tokens",
      defaultTokenId: "defaultToken/defaultTokenId"
    }),

    items() {
      let group = this.grouped[`/api/au_tokens/${this.tokenId}`];

      if (group) {
        return _.map(group, g => {
          let token = _.find(this.tokens, token => token.id == this.tokenId);
          let value = (Number(g.value) / Math.pow(10, token.decimals)).toFixed(token.indent);

          return { date: moment(g.createdAt).lang("ru").format("L"), value: value };
        });
      }

      return [];
    },

    tokenOptions() {
      return _.map(this.series, s => ({ value: s.id, display: s.name }));
    },

    isLightTheme() {
      return this.$store.getters.isLightTheme;
    },

    options() {
      return {
        chart: {
          type: "area",
          zoom: {
            enabled: false
          },
          toolbar: {
            show: false
          }
        },
        dataLabels: {
          enabled: false
        },
        legend: {
          show: false
        },
        stroke: {
          curve: "smooth"
        },
        xaxis: {
          type: "datetime",
          min: this.startDate,
          tickAmount: _.isEmpty(this.series) ? 0 : this.series[0].data.length,
          labels: {
            show: true,
            format: undefined,
            formatter(value) {
              return new Date(value).toLocaleDateString();
            },
            style: {
              colors: this.isLightTheme ? "#08080899" : "#FFFFFF99"
            }
          }
        },
        yaxis: {
          labels: {
            style: {
              colors: this.isLightTheme ? "#08080899" : "#FFFFFF99"
            }
          }
        },
        tooltip: {
          theme: this.isLightTheme ? "light" : "dark",
          x: {
            format: "dd MMM yyyy"
          }
        }
      };
    },

    grouped() {
      return _.groupBy(this.history, "tokenOfValue");
    },

    groupWithDebug() {
      let data = this.grouped["/api/au_tokens/" + this.defaultTokenId];

      let dataDebug = _.filter(data, d => d.debug);

      return dataDebug;
    },

    currentRate() {
      let last = _.last(this.debugItems);

      if (last) {
        return last.currency;
      }

      return null;
    },

    debugItems() {
      let items = [];

      this.groupWithDebug.forEach(historyData => {
        let debugToken = _.find(historyData.debug, { tokenId: this.tokenId });

        let token = _.find(this.tokens, token => token.id == this.tokenId);

        if (debugToken) {
          let amountValue = (Number(debugToken.amount) / Math.pow(10, token.decimals)).toFixed(token.indent);
          let value = (Number(debugToken.amount * debugToken.currency) / Math.pow(10, token.decimals)).toFixed(token.indent);

          let historyItem = {
            time: moment(historyData.createdAt).lang("ru").format("L"),
            amount: amountValue,
            currency: debugToken.currency,
            res: value
          };

          items.push(historyItem);
        }
      });

      return _.reverse(items);
    },

    startDate() {
      return new Date(this.history[0]?.createdAt);
    },

    series() {
      let series = [];

      for (const tokenValue in this.grouped) {
        let historyData = this.grouped[tokenValue];

        let timeGrouped = _.groupBy(historyData, data => moment(data.createdAt).format("l"));

        let token = _.find(this.tokens, token => token.id == _.split(tokenValue, "/")[3]);

        if (token) {
          let historyItem = {
            name: token.nameEng,
            id: token.id,
            color: this.isLightTheme ? "rgba(8, 8, 8, 0.2)" : "rgba(242, 242, 247, 0.5)",
            data: []
          };

          for (const date in timeGrouped) {
            let value = (Number(timeGrouped[date][0].value) / Math.pow(10, token.decimals)).toFixed(token.indent);

            historyItem.data.push([date, value]);
          }

          series.push(historyItem);
        }
      }

      return series;
    },

    pivotTableColumns() {
      let columns = [{ name: "Время" }];

      for (const tokenId in this.grouped) {
        let token = _.find(this.tokens, token => token.id == _.split(tokenId, "/")[3]);

        if (token) {
          let column = { name: token.mnemonicEng, align: "end" };
          columns.push(column);
        }
        else {
          columns.push({ name: "", align: "end" });
        }
      }

      columns.push({ name: "Результат", align: "end" });

      return columns;
    },

    pivotTableItems() {
      let timeGrouped = _.groupBy(this.history, "createdAt");

      let items = [];

      for (const time in timeGrouped) {
        let valuesInTime = timeGrouped[time];
        let currentToken = _.find(valuesInTime, { tokenOfValue: "/api/au_tokens/" + this.defaultTokenId });

        if (!currentToken) {
          continue;
        }

        let item = {};

        item["!time"] = moment(time).lang("ru").format("L");

        for (const tokenId in this.grouped) {
          let valueToken = _.find(timeGrouped[time], { tokenOfValue: tokenId });

          item[tokenId] = null;

          if (valueToken && valueToken.debug) {
            let tokenDebugValue = _.find(valueToken.debug, tokenv => tokenv.tokenId == _.split(tokenId, "/")[3]);

            if (tokenDebugValue) {
              let token = _.find(this.tokens, token => token.id == _.split(tokenId, "/")[3]);
              let value = (Number(tokenDebugValue.amount) / Math.pow(10, token.decimals)).toFixed(token.indent);

              item[tokenId] = value;
            }
          }
        }

        let token = _.find(this.tokens, token => token.id == this.defaultTokenId);
        let value = (Number(currentToken.value) / Math.pow(10, token.decimals)).toFixed(token.indent);
        item.result = value;

        items.push(item);
      }

      return _.reverse(items);
    }
  },

  async created() {
    await this.getHistoryData({ userId: this.$auth.user().id });
    this.loading = false;
  },

  methods: {
    ...mapActions({
      getHistory: "getHistory"
    }),

    async getHistoryData(params) {
      await this.getHistory(params);
    }
  }
};
</script>

<style lang="scss" scoped>
.panel {
  height: calc(100% - 67px);
  overflow: auto;
}

.token-table {
  overflow: auto;
  height: 400px;
  margin-right: 10px;

  &_button {
    height: 800px;
    margin-top: 10px;
    padding-top: 10px;
    border-top: var(--text-color) 1px solid;
  }
}
</style>