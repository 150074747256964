<template>
  <div class="card payments">
    <div class="title">
      {{ $t("auCapitals.dividendsPay.title") }}
    </div>
    <AuTable
      :items="dividendItems"
      :columns="dividendsColumns"
      :loading="dividendsLoading"
      :action-column="actionColumn"
      action-type="Dropdown"
      @drop="showHistory"
    >
      <template #default="{item, index}">
        <div class="history">
          <div v-if="item[symbolTotal] !== 0">
            <div class="sub-title">
              {{ $t("auCapitals.dividendsPay.paymentData") }}
            </div>
            <AuTable
              :columns="dividendsHistoryColumns"
              :items="item[symbolHistory]"
              :loading="historyLoading[index]"
            />
            <AuPagination
              v-if="item[symbolTotal] > itemsPerPageHistory"
              :total-items="item[symbolTotal]"
              :index="index"
              @pageChangedIndex="changePageHistory"
            />
          </div>
          <div v-else>
            {{ $t("auCapitals.dividendsPay.noPayments") }}
          </div>
        </div>
      </template>
    </AuTable>
    <AuPagination
      v-if="dividendsTotal > itemsPerPage"
      :total-items="dividendsTotal"
      @pageChanged="changePage"
    />
  </div>
</template>

<script>
import _ from "lodash";
import moment from "moment";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "DividendsPay",
  props: {
    id: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      page: 1,
      itemsPerPage: 30,
      symbolId: null,
      symbolHistory: null,
      symbolTotal: null,
      historyLoading: [],
      dividendsLoading: false,
      pageHistory: 1,
      itemsPerPageHistory: 30,
      dividendsHistoryLoading: false,
      actionColumn: { icon: ["chevron_down", "chevron_up"] }
    };
  },
  computed: {
    ...mapGetters({
      dividends: "dividends/dividends",
      dividendsTotal: "dividends/dividendsTotal",
      dividendsHistory: "dividends/dividendsHistory",
      dividendsHistoryTotal: "dividends/dividendsHistoryTotal"
    }),

    dividendsColumns() {
      return [
        { name: this.$t("auCapitals.dividendsPay.dividendsName"), align: "start" },
        { name: this.$t("auCapitals.dividendsPay.dividendsDescription"), align: "start" },
        { name: this.$t("auCapitals.dividendsPay.dividendsStatus"), align: "start" },
        { name: "", align: "end" }];
    },

    dividendsHistoryColumns() {
      return [
        { name: this.$t("auCapitals.dividendsPay.historyCount"), align: "start" },
        { name: this.$t("auCapitals.dividendsPay.historyCountToken"), align: "center" },
        { name: this.$t("auCapitals.dividendsPay.historyStatus"), align: "start" },
        { name: this.$t("auCapitals.dividendsPay.historyDate"), align: "start" },
        { name: this.$t("auCapitals.dividendsPay.historyUser"), align: "start" },
        { name: this.$t("auCapitals.dividendsPay.historyAddress"), align: "start" }];
    },

    dividendsStatus() {
      return {
        draft: this.$t("auCapitals.dividendsPay.statusDraft"),
        waiting: this.$t("auCapitals.dividendsPay.statusWaiting"),
        process: this.$t("auCapitals.dividendsPay.statusProcess"),
        completed: this.$t("auCapitals.dividendsPay.statusCompleted")
      };
    },

    dividendItems() {
      return _.map(this.dividends, el => {
        const item = {
          title: el.title,
          description: el.description,
          status: _.get(this.dividendsStatus, el.status, "-")
        };
        item[this.symbolId] = el.id;
        item[this.symbolHistory] = [];
        item[this.symbolTotal] = 0;
        return item;
      });
    }
  },
  async created() {
    this.symbolId = Symbol("id");
    this.symbolHistory = Symbol("history");
    this.symbolTotal = Symbol("total");
    await this.getData();
  },
  methods: {
    ...mapActions({
      getDividends: "dividends/getDividends",
      getDividendsHistory: "dividends/getDividendsHistory"
    }),

    async getData() {
      this.dividendsLoading = true;
      await this.getDividends({
        page: this.page,
        itemsPerPage: this.itemsPerPage,
        auTokenId: this.id
      });
      for (let i = 0; i < this.dividendsTotal; i++) {
        this.historyLoading[i] = false;
      }
      this.dividendsLoading = false;
    },

    async getHistoryData(item) {
      await this.getDividendsHistory({
        page: this.pageHistory,
        itemsPerPage: this.itemsPerPageHistory,
        dividendsId: item[this.symbolId]
      });
      item[this.symbolTotal] = this.dividendsHistoryTotal;
      item[this.symbolHistory] = _.map(this.dividendsHistory, el => ({
        amountToken: el.amountToken,
        amountTokenReserved: el.amountTokenReserved,
        status: el.status,
        createdAt: moment(el.createdAt).format("DD.MM.YYYY"),
        user: el.user,
        ethAddress: el.ethAddress
      }));
    },

    async changePage(page) {
      this.page = page;
      await this.getData();
    },

    async changePageHistory(event) {
      this.pageHistory = event.actualPage;
      this.historyLoading[event.index] = true;
      await this.getHistoryData(this.dividendItems[event.index]);
      this.historyLoading[event.index] = false;
    },

    async showHistory(event) {
      if (event.visible) {
        this.historyLoading[event.index] = true;
        await this.getHistoryData(this.dividendItems[event.index]);
        this.historyLoading[event.index] = false;
      }
    }
  }
};
</script>

<style scoped lang="scss">
.payments {
  padding: 20px;
  .title {
    font-weight: 600;
    font-size: 24px;
    margin-bottom: 40px;
  }
  .sub-title {
    font-weight: 450;
    font-size: 18px;
    margin-bottom: 10px;
  }
  .history {
    padding: 20px;
  }
}
</style>