<template>
  <!-- <a
    class="fs14 transaction-info__item-name a_link scan"
    :href="link + volume"
    target="_blank"
  ><img
    class="t-image"
    src="../../assets/img/scan_icon.svg"
    alt="scaner"
  ></a> -->

  <AuIcon
    class="scan"
    :is-link="true"
    :href="link + volume"
    target="_blank"
    icon="scan"
  />
</template>

<script>
export default {
  props: {
    volume: {
      type: String,
      default: "#"
    }
  },

  computed: {
    theme() {
      return this.$store.getters.isLightTheme ? "light" : "dark";
    },
    link() {
      return `${process.env.VUE_APP_SCAN_BASE_URL}tx/`;
    }
  }
};
</script>

<style scoped lang="scss">
.scan {
  display: flex;
  align-items: center;
  margin-left: 15px;
}
</style>
