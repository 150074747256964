import actions from "./actions";

export default {
  namespaced: true,
  state: {
    blogPages: [],
    page: 1,
    count: 0,
    dataLoading: false
  },

  getters: {
    blogPages: state => {
      return state.blogPages;
    },

    page: state => {
      return state.page;
    },

    blogPagesCount: state => {
      return state.count;
    },

    dataLoading: state => {
      return state.dataLoading;
    }
  },

  mutations: {
    setBlogPages(state, blogPages) {
      state.blogPages = blogPages;
    },

    setBlogPagesPage(state, page) {
      state.page = page;
    },

    setBlogPagesCount(state, count) {
      state.count = count;
    },

    setDataLoading(state, dataLoading) {
      state.dataLoading = dataLoading;
    }
  },

  actions
};
