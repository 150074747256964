<template>
  <div />
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      outerPath: "outerPath"
    })
  },

  created() {
    if (this.$route.query.loginAs) {
      this.$auth.token(null, this.$route.query.loginAs, false);
      this.$cookies.set("is_login_in", true, "14d");
      this.$auth.fetch();
    }

    if (this.outerPath) {
      this.$router.push({ path: this.outerPath });
    }
    else {
      this.$router.push({ path: "/dashboard" });
    }
  }
};

</script>