<template>
  <span class="au-tooltip">
    <span
      v-if="type === 'question'"
      ref="tooltip"
      class="tooltip-content"
    >
      <span class="tooltip-text">?</span>
    </span>
    <span
      v-if="type === 'info'"
      ref="tooltip"
    >
      <div class="tooltip-round" />
    </span>
    <span
      v-if="type === 'slot'"
      ref="tooltip"
    >
      <slot />
    </span>
  </span>
</template>

<script>
import tippy from "tippy.js";
import "tippy.js/dist/tippy.css";

export default {
  name: "AuTooltip",
  props: {
    description: {
      type: String,
      default: ""
    },
    type: {
      type: String,
      default: "question"
    }
  },

  data() {
    return {
      tooltip: null
    };
  },

  computed: {
    theme() {
      return this.$store.getters.isLightTheme ? "light" : "dark";
    }
  },

  watch: {
    theme(newTheme) {
      if (this.tippyInstance) {
        this.tippyInstance.setProps({ theme: newTheme });
      }
    }
  },

  mounted() {
    this.tooltip = this.$refs.tooltip;
    this.initTippy();
  },
  beforeUnmount() {
    if (this.tippyInstance) {
      this.tippyInstance.destroy();
    }
  },

  methods: {
    initTippy() {
      this.tippyInstance = tippy(this.tooltip, {
        content: this.description,
        allowHTML: true,
        trigger: "mouseenter click",
        arrow: true,
        theme: this.theme
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.au-tooltip {
  position: relative;
}
.tooltip-content {
  position: absolute;
}

.tooltip-round{
  display: inline-block;
  width: 10px;
  height: 10px;
  background: #5fc396;
  border-radius: 50%;
  cursor: pointer;
}

.tooltip-text {
  font-size: 14px;
  display: flex;
  cursor: pointer;
  color: #888;
  margin-top: -5px;
  margin-left: 3px;
}

.tippy-box[data-theme~="light"] {
  padding: 2px;
  background-color: #1c2434;
  color: #fff;
  border-radius: 6px;
  font-size: 14px;
}

.tippy-box[data-theme~="light"][data-placement^="top"] > .tippy-arrow:before {
  border-top-color: #1c2434;
}
.tippy-box[data-theme~="light"][data-placement^="bottom"]
  > .tippy-arrow:before {
  border-bottom-color: #1c2434;
}

.tippy-box[data-theme~="dark"] {
  padding: 2px;
  background-color: #fff;
  color: #1c2434;
  border-radius: 6px;
  font-size: 14px;
}

.tippy-box[data-theme~="dark"][data-placement^="top"] > .tippy-arrow:before {
  border-top-color: #fff;
}
.tippy-box[data-theme~="dark"][data-placement^="bottom"]
  > .tippy-arrow:before {
  border-bottom-color: #fff;
}

.tippy-box .tippy-content > div {
  font-size: 14px;
}

.tippy-box .tippy-content > li {
  text-indent: 8px;
}
.tippy-box .tippy-content > li {
  list-style: none;
  position: relative;
  padding-left: 18px;
}

.tippy-box .tippy-content > li::before {
  content: '•';
  position: absolute;
  left: 0;
  font-size: 14px;
}
</style>
