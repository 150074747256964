<template>
  <li>
    <span
      v-if="page === null"
      class="DotsHolder"
    >
      <AuIcon
        icon="page_dots"
        class="Dots select__chevron-icon"
        :class="{ 'Control-active': isNextControlsActive }"
        @click="goToLast"
      />
    </span>
    <button
      v-else
      class="Page"
      type="button"
      :aria-label="`Go to page ${page}`"
      :class="{ 'Page-active': isActive }"
      :style="`background-color: ${isActive ? activeColor : 'transparent'};`"
      @click="clickHandler"
    >
      {{ page }}
    </button>
  </li>
</template>

<script>
import AuIcon from "@/components/extended/AuIcon.vue";
import { computed, defineComponent } from "vue";

export default defineComponent({
  name: "MyPage",
  components: {
    AuIcon
  },
  props: {
    page: {
      type: Number,
      default: null
    },
    current: {
      type: Number,
      default: 0
    },
    activeColor: {
      type: String,
      default: ""
    }
  },
  emits: ["update"],

  setup(props, { emit }) {
    const isActive = computed(() => {
      return props.page === props.current;
    });

    function clickHandler() {
      emit("update", props.page);
    }

    return { isActive, clickHandler };
  }
});
</script>

<style scoped lang="scss">

.Page {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  margin: 0 2px;
  color: var(--color-50);
  background-color: transparent;
  font-size: 14px;
  border-radius: 5px;
  box-sizing: border-box;
  border-color: transparent;
  cursor: pointer;
  outline: 0;
  user-select: none;

  &:hover {
    transition: 0.2s ease-in-out;
    border: 1px solid var(--color-50);
  }

  &-active {
    transition: 0.2s ease-in-out;
    color: var(--text-color);
    border: 1px solid var(--color-70);
  }
}

.DotsHolder {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 22px;
  margin: 0 2px;
  box-sizing: border-box;
}

.Dots {
  display: flex;
  margin-top: 8px;
  width: 8px;
  height: 4px;
}
</style>
