<template>
  <div class="no_data">
    <span>{{ computedText }}</span>
  </div>
</template>

<script>
export default {
  name: "AuNoData",
  props: {
    text: {
      type: String,
      default: ""
    }
  },
  computed: {
    computedText() {
      return this.text || this.$t("briefcase.ordersNoData");
    }
  }
};
</script>

<style scoped lang="scss">
.no_data {
  display: flex;
  justify-content: center;
  color: var(--color-50);
}
</style>

