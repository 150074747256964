<template>
  <div />
</template>

<script>
export default {
  name: "BlockScan",

  mounted() {
    window.open(process.env.VUE_APP_SCAN_BASE_URL, "_blank");
    this.$router.go(-1);
  }
};
</script>

<style scoped>

</style>