<template>
  <ProgressLoader
    v-if="breadcrumbLoading"
    mode="indeterminate"
    color="#5AC396"
    size="150px"
  />

  <div v-else>
    <AuBreadcrumb
      :pages="breadcrumb"
    />
    <div class="data-info card">
      <div class="form-header">
        <div>
          <div class="title">
            {{ $t(`auCapitals.capitalHistory.title${vote}`) }}
          </div>
          <div class="title">
            <span class="explain">
              {{ $t("auCapitals.capitalHistory.project") }}
              <span class="data">{{ currentCapital?.tokenBase?.nameEng }}
              </span>
            </span>
          </div>
        </div>
        <div>
          <AuSelect
            :value="type"
            class="type-select"
            :options="typeArray"
            :placeholder="$t('transactions.transactionType.placeholder')"
            @input="changeType"
          />
        </div>
      </div>
      <div class="delimiter" />

      <ProgressLoader
        v-if="loading"
        mode="indeterminate"
        color="#5AC396"
        size="150px"
      />
      <div v-if="transactions?.length">
        <LastTransactionsList
          :transactions="transactions"
          :invesrt-signs="true"
        />
        <div
          v-if="itemPerPage < totalItems"
          class="footer"
        >
          <AuButton
            center
            @click="getMore"
          >
            {{ $t("auCapitals.capitalHistory.more") }}
          </AuButton>
        </div>
      </div>
      <div v-if="!transactions?.length && !loading">
        <AuNoData
          class="no_data"
          :text="$t('briefcase.ordersNoTrx')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import LastTransactionsList from "../components/lastTransactions/LastTransactionsList.vue";
import ProgressLoader from "@/loaders/progress-bar";

import _ from "lodash";
import moment from "moment";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "CapitalHistory",
  components: {
    LastTransactionsList,
    ProgressLoader
  },
  props: {
    id: {
      type: Number,
      default: null
    }
  },

  data() {
    return {
      transactions: [],
      currentCapital: [],
      page: 1,
      totalItems: 0,
      itemPerPage: 30,
      morePage: 30,
      loading: true,
      key: 1,
      type: "",
      typeArray: [
        { display: this.$t("transactions.transactionType.all"), value: "all" },
        { display: this.$t("transactions.transactionType.my"), value: "my" }
      ],
      breadcrumbLoading: true,
      userIdUrl: ""
    };
  },

  computed: {
    ...mapGetters({
      arrEth: "ethAddresses"
    }),

    breadcrumb() {
      const breadcrumb = [
        { key: 0, name: "Dashboard", path: "/" },
        { key: 1, name: "My projects", path: "/my-projects" }
      ];
      if (this.projectName) {
        breadcrumb.push({ key: breadcrumb?.length, name: this.projectName, path: `/project-card/${this.id}` });
      }
      return breadcrumb;
    },

    projectName() {
      return this.currentCapital?.tokenBase?.nameEng || null;
    },

    vote() {
      return this.$route.meta?.voting ? "Vote" : "";
    }
  },

  async created() {
    this.currentCapital = await this.getAuCapitals(this.id);
    this.$route.matched[1].path = `/project-card/${this.id}`;
    this.$route.matched[1].meta.title = this.currentCapital.tokenBase.nameEng;
    this.key = 2;
    this.userIdUrl = `/api/users/${this.$auth.user().id}`;
    await this.getTransactions();
    this.breadcrumbLoading = false;
  },

  methods: {
    ...mapActions({
      getTransactionsHistory: "getTransactionsHistory",
      getAuCapitals: "capitals/getAuCapitals"
    }),

    async getTransactions() {
      this.loading = true;
      if (!this.arrEth[0]?.verifiedAt) {
        this.loading = false;
        return;
      }

      let params = {
        page: this.page,
        itemsPerPage: this.itemPerPage,
        auCapitalId: this.id,
        methodName: "vote,investing,investing_nft"
      };

      if (this.type == "my") {
        params.fromUserId = this.$auth.user().id;
      }
      let resp = await this.getTransactionsHistory(params);
      let transfer = resp.data;
      this.totalItems = resp.totalItems;
      this.transactions = [];

      if (transfer.length == 0) {
        this.loading = false;
        return;
      }
      transfer.forEach((transaction) => {
        const time = moment(transaction.createdAt).format("DD.MM.YYYY");
        let records = this.transactions.find(
          (el) => el.date == time
        )?.transactions;
        if (records === undefined) {
          this.transactions.push({
            date: time,
            sort: transaction.createdAt,
            transactions: []
          });
          records = this.transactions.at(-1).transactions;
        }
        this.arrEth.forEach((eth) => {
          if (_.toLower(eth.value) === _.toLower(transaction?.addressFrom)) {
            this.addressEth = transaction?.addressFrom;
          }
          if (this.userIdUrl == _.toLower(transaction?.fromUser)) {
            this.addressEth = transaction?.addressFrom;
            transaction.isOwnerTransaction = true;
          }
          else if (
            _.toLower(eth.value) === _.toLower(transaction?.addressTo)
          ) {
            this.addressEth = transaction?.addressTo;
          }
          else {
            this.addressEth = transaction?.addressFrom;
          }
        });

        records.push({
          icon:
            transaction?.auToken?.logoMain ??
            transaction?.auToken?.mainImage?.name,
          name: _.isNil(this.addressEth)
            ? this.$t("transactions.unspecified")
            : this.addressEth.slice(0, 4) + "..." + this.addressEth.slice(-4),
          addressFrom: _.get(transaction, "addressFrom", "-"),
          addressTo: _.get(transaction, "addressTo", "-"),
          addressEth: this.addressEth,
          amount: _.get(transaction, "amount", null),
          isConfirmed: _.get(transaction, "isConfirmed", false),
          cardType: moment(transaction.createdAt).format("DD.MM.YYYY HH:mm:ss"),
          isRefill: _.toLower(this.ourEth) == _.toLower(transaction?.addressTo),
          type: _.get(transaction, "methodName", "transfer"),
          decimals: _.get(transaction, "auToken.decimals", 2),
          indent: _.get(transaction, "auToken.indent", 2),
          mnemonic: _.get(transaction, "auToken.mnemonicEng", "-"),
          createdAt: _.get(transaction, "createdAt", "-"),
          hash: _.get(transaction, "hash", "-"),
          isOwnerTransaction: _.get(
            transaction,
            "isOwnerTransaction",
            transaction?.isOwnerTransaction
          ),
          projectId: this.id,
          projectName: this.currentCapital?.tokenBase?.nameEng,
          isVoting: transaction?.auCapital?.projectType === "voting"
        });
      });

      this.transactions.sort((a, b) => {
        const diff = moment(b.sort).unix() - moment(a.sort).unix();
        if (diff > 0) {
          return 1;
        }
        if (diff < 0) {
          return -1;
        }
        return 0;
      });

      this.loading = false;
    },

    async changeType(type) {
      this.type = type;
      await this.getTransactions();
    },
    async getMore() {
      this.itemPerPage += this.morePage;
      await this.getTransactions();
    }
  }
};
</script>

<style scoped lang="scss">
.card {
  width: 100%;
  padding: 20px 22px 22px 20px;
}

.form-header {
  margin-bottom: 24px;
  .title {
    font-size: 24px;
    color: var(--text-color);
    line-height: 0.8;
  }
  .explain {
    font-weight: 450;
    font-size: 12px;
    color: var(--color-60);
    .data {
      font-weight: 500;
      font-size: 14px;
      color: var(--color-60);
    }
  }
}
.delimiter {
  border-bottom: 1px solid var(--color-70);
}

.footer {
  display: flex;
  justify-content: center;
  padding: 20px;
  margin-top: 40px;
  border-top: 1px dashed var(--color-70);
}
.filter {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.type-select {
  width: 172px;
}

.no_data {
  padding-top: 24px;
  padding-bottom: 24px;
  border-top: var(--color-70) 1px solid;
  border-bottom: var(--color-70) 1px solid;
}
</style>
