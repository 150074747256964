<template>
  <div>
    <div class="title">
      {{ $t("changePassword.titlePassword") }}
    </div>
    <div class="panel">
      <span
        v-if="!allField"
        class="text-explain"
      >{{
        $t("changePassword.requiredFields")
      }}</span>
      <span
        v-else-if="!isConfirm"
        class="text-explain"
      >{{
        $t("changePassword.passwordDifficult")
      }}</span>
      <span
        v-else-if="!strongPassword"
        class="text-explain"
      >Усложните пароль{{ $t("changePassword.requiredFields") }}</span>
      <span
        v-else
        class="text-explain"
      >{{
        $t("changePassword.passwordRemember")
      }}</span>

      <input
        id="password"
        v-model="passOld"
        class="data-in"
        type="password"
        :placeholder="$t(`changePassword.passwordValid`)"
      >
      <label
        for="password"
        class="minimal"
      >{{
        $t("changePassword.passwordNew")
      }}</label>
      <label
        v-if="!strongPassword"
        for="password"
        class="minimal"
      >{{
        $t("changePassword.passwordStrong")
      }}</label>
      <input
        id="password"
        v-model="pass"
        class="data-in"
        type="password"
        :placeholder="$t(`changePassword.passwordNewPass`)"
      >
      <input
        v-model="pass_confirm"
        class="data-in"
        type="password"
        :placeholder="$t(`changePassword.passwordRepeat`)"
      >
      <AuButton
        :disabled="!allField || !isConfirm || !strongPassword"
        center
        type="primary"
        @click="changePassword"
      >
        <img
          v-if="progress"
          src="/svg/dark/progress_rolling_green.svg"
          alt="progress"
        >
        {{ $t("changePassword.passwordChange") }}
      </AuButton>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "ChangePassword",
  data() {
    return {
      progress: false,
      passOld: "",
      pass: "",
      pass_confirm: ""
    };
  },

  computed: {
    ...mapGetters({
      isLightTheme: "isLightTheme"
    }),

    allField() {
      return (
        this.passOld.length >= 1 &&
        this.pass !== null &&
        this.pass.length >= 8 &&
        this.pass_confirm !== null &&
        this.pass_confirm.length >= 1
      );
    },

    isConfirm() {
      return this.pass === this.pass_confirm;
    },

    strongPassword() {
      const containsUppercase = /[A-Z]/.test(this.pass);
      const containsLowercase = /[a-z]/.test(this.pass);
      const containsNumber = /[0-9]/.test(this.pass);
      return containsUppercase && containsLowercase && containsNumber;
    }
  },

  methods: {
    ...mapActions({
      postPassword: "postPassword"
    }),
    async changePassword() {
      this.progress = true;

      const params = {
        oldPassword: this.passOld,
        newPassword: this.pass
      };
      try {
        let res = await this.postPassword({ params: params });
        if (res.request.status == "200") {
          this.$toast.success("Congratulations! <br> Password changed");
          this.passOld = this.pass = this.pass_confirm = "";
          this.progress = false;
        }
        else {
          this.$toast.error(`Error or <br> ${res.response.data.detail}`);
          this.progress = false;
        }
      }
      catch (error) {
        this.$toast.error(`Error or <br> ${error.response.data.errors}`);
        this.progress = false;
      }
    }
  }
};
</script>

<style scoped lang="scss">
.title {
  font-weight: 600;
  font-size: 22px;
  margin-bottom: 30px;
}
.panel {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  max-width: 350px;
  border: 1px solid var(--color-70);
  border-radius: 20px;
  padding: 20px;
  background: var(--card-color);
}
.data-in {
  border-radius: 10px;
  border: 1px solid var(--color-70);
  padding: 15px;
  margin-bottom: 10px;

  &::placeholder {
    color: var(--color-50);
  }
}
.text-explain {
  font-weight: 400;
  font-size: 14px;
  margin: 0px auto 10px auto;
  color: var(--color-50);
}
.minimal {
  color: var(--color-50);
  font-size: 12px;
  margin-bottom: 5px;
}
</style>
