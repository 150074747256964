<template>
  <div>
    <AuBreadcrumb />
    <div class="card dao-info container-fluid">
      <div class="row">
        <div class="col">
          <div class="dao-info-title">
            {{ token?.nameEng }}
          </div>
        </div>
      </div>
      <div class="row dao-info-first-line">
        <div class="col">
          <div class="icons">
            <img
              class="icon"
              :src="tokenLogo"
              alt=""
            >
            <img
              class="round"
              src="../assets/img/green_icon.svg"
              alt=""
            >
          </div>

          <div class="no_span_redef">
            <span class="dao-info-wrapper-value">{{ ownerAmount }}</span>
            <span class="dao-info-wrapper-span">{{ $t("daoTokens.amountDaoPortfolio") }}</span>
          </div>
          <div class="no_span_redef">
            <span class="dao-info-wrapper-value">{{ tokenAmount }}</span>
            <span class="dao-info-wrapper-span">{{ $t("daoTokens.totalTokens") }}</span>
          </div>
          <div class="dao-info-wrapper">
            <span class="dao-info-wrapper-value">{{ percent }}</span>
            <span class="dao-info-wrapper-span">{{ $t("daoTokens.percentPortfolio") }}</span>
          </div>
        </div>
      </div>
      <div class="row dao-info-second-line">
        <div class="col">
          <div class="dao-info-wrapper">
            <span class="dao-info-wrapper-value">{{ augCost }} AUg</span>
            <span class="dao-info-wrapper-span">{{ $t("daoTokens.priceAug") }}</span>
          </div>
          <div class="dao-info-wrapper dashed-right">
            <span class="dao-info-wrapper-value">{{ rubleCost }}</span>
            <span class="dao-info-wrapper-span">{{ $t("daoTokens.priceIn") }} {{ defaultTokenMnemonic }}</span>
          </div>
          <div class="dao-info-wrapper dashed-left">
            <span class="dao-info-wrapper-value">{{ dividentsDate }}</span>
            <span class="dao-info-wrapper-span">{{ $t("daoTokens.expectedDividends") }}</span>
          </div>
          <div class="dao-info-wrapper">
            <span class="dao-info-wrapper-value">{{ augDividentsSum }}</span>
            <span class="dao-info-wrapper-span">{{ $t("daoTokens.amoutDividends") }}</span>
          </div>
        </div>
      </div>
      <div class="row dao-info-tabs">
        <div class="col">
          <AuButton
            bordered
            width="100%"
            center
            height="44px"
            border-radius="10"
            :active-bg-color="switcher === 1 ? '#2ABA7C' : ''"
            @click="switcher = 1"
          >
            <span class="button-text">{{ $t("daoTokens.communityProjects") }}</span>
          </AuButton>
        </div>
        <div class="col">
          <AuButton
            bordered
            width="100%"
            height="44px"
            border-radius="10"
            center
            :active-bg-color="switcher === 2 ? '#2ABA7C' : ''"
            @click="switcher = 2"
          >
            <span class="button-text">{{ $t("daoTokens.archiveDao") }}</span>
          </AuButton>
        </div>
        <div class="col">
          <AuButton
            bordered
            width="100%"
            height="44px"
            border-radius="10"
            center
            :active-bg-color="switcher === 3 ? '#2ABA7C' : ''"
            @click="switcher = 3"
          >
            <span class="button-text">{{ $t("daoTokens.futureEvent") }}</span>
          </AuButton>
        </div>
      </div>
      <!--<div class="row dao-info-header">
        <div class="col-3">
          <span> Подведение итогов </span>
        </div>
        <div class="col-4">
          <span class="pe-2"> Цель предложения </span>
        </div>
        <div class="col-5">
          <span> Аргументация </span>
        </div>
      </div>-->
      <div v-if="switcher === 1">
        <div
          v-for="(daoRequest, index) in daoRequests"
          :key="index"
        >
          <ProjectSurvey
            v-if="daoRequest.result.length <= 2"
            :dao-request="daoRequest"
            :owner-amount="ownerAmount"
            :user-data="userData"
            @newDaoRequest="getDao"
          />
        </div>
      </div>
      <div v-if="switcher === 1">
        <div
          v-for="(daoRequest, index) in daoRequests"
          :key="index"
        >
          <ProjectSurveyMany
            v-if="daoRequest.result.length > 2"
            :dao-request="daoRequest"
            :owner-amount="ownerAmount"
            :user-data="userData"
            @newDaoRequest="getDao"
          />
        </div>
      </div>
      <div v-if="switcher === 2">
        <ProjectSurveyArchive
          v-for="(daoRequest, index) in daoRequestsArchive"
          :key="index"
          :dao-request="daoRequest"
        />
      </div>
      <div v-if="switcher === 3">
        <AuTable
          :columns="columns"
          :items="items"
          :loading="soonLoading"
        />
        <AuPagination
          v-if="daoSoonTotal > soonItemsPerPage"
          :total-items="daoSoonTotal"
          @pageChanged="soonPageChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import BigNumber from "bignumber.js";
import CurrencySupport from "@/help/currencySupport";
import moment from "moment";
import ProjectSurvey from "../components/ProjectSurvey.vue";
import ProjectSurveyArchive from "../components/ProjectSurveyArchive.vue";
import ProjectSurveyMany from "../components/ProjectSurveyMany.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    ProjectSurvey,
    ProjectSurveyArchive,
    ProjectSurveyMany
  },
  props: {
    id: {
      type: String,
      default: "0"
    }
  },

  data() {
    return {
      switcher: 1,
      currencySup: new CurrencySupport(),
      dividentsDate: "29.11.2022",
      augDividentsSum: 14,
      daoRequestsArchive: [],
      daoRequestsArchiveTotal: 0,
      daoSoon: [],
      daoSoonTotal: null,
      soonPage: 1,
      soonItemsPerPage: 20,
      soonLoading: false,
      augCost: null,
      rubleCost: null,
      columns: [
        { name: "Дата старта", align: "center" },
        { name: "Название", align: "center" },
        { name: "Описание", align: "center" }
      ]
    };
  },

  computed: {
    ...mapGetters({
      tokens: "tokens",
      auOwners: "auOwners",
      userData: "ethAddresses",
      defaultTokenMnemonic: "defaultToken/defaultTokenMnemonic",
      daoRequests: "dao/daoRequests",
      daoRequestsTotal: "dao/daoRequestsTotal",
      token: "dao/token"
    }),

    tokenLogo() {
      return _.get(this.token, "logoMain", "/svg/token/criptoactive_icon.svg");
    },

    ownerAmount() {
      const ret = _.find(this.auOwners, owner => owner.auToken.id == this.id);
      return BigNumber(_.get(ret, "amount", 0)).div(BigNumber(10).pow(this.token?.decimals)).toString(10);
    },

    tokenAmount() {
      return _.get(this.token, "amount", 0);
    },

    augId() {
      return _.find(this.tokens, token => token.mnemonicEng === "AUG")?.id;
    },

    percent() {
      let ret = BigNumber(this.ownerAmount);
      ret = ret.multipliedBy(100);
      ret = ret.dividedBy(BigNumber(this.tokenAmount));
      return ret.isFinite() ? ret.toString(10) : "0";
    },

    items() {
      return _.map(this.daoSoon, item => ({
        startAt: moment(item.startAt).format("DD.MM.YYYY"),
        name: item.name,
        description: item.description
      }));
    }
  },

  async mounted() {
    await this.getDaoSoon();
    await this.getDao();
    await this.getToken(this.id);
    this.augCost = this.currencySup.getCurrency(this.id, this.augId);
    this.rubleCost = this.currencySup.getCurrency(this.id, this.defaultTokenMnemonic);
  },

  methods: {
    ...mapActions({
      getDaoRequests: "dao/getDaoRequests",
      getToken: "dao/getToken"
    }),

    async getDao() {
      await this.getDaoRequests({
        page: 1,
        itemsPerPage: 20,
        isFinished: true,
        tokenDaoId: this.id
      });
      this.daoRequestsArchive = _.cloneDeep(this.daoRequests);
      this.daoRequestsArchiveTotal = this.daoRequestsTotal;
      await this.getDaoRequests({
        page: 1,
        itemsPerPage: 20,
        isFinished: false,
        tokenDaoId: this.id
      });
    },

    async getDaoSoon() {
      this.soonLoading = true;
      await this.getDaoRequests({
        page: this.soonPage,
        itemsPerPage: this.soonItemsPerPage,
        tokenDaoId: this.id,
        status: "prepared"
      });
      this.daoSoon = _.cloneDeep(this.daoRequests);
      this.daoSoonTotal = this.daoRequestsTotal;
      this.soonLoading = false;
    },

    async soonPageChange(page) {
      this.soonPage = page;
      await this.getDaoSoon();
    }
  }
};
</script>

<style lang="scss" scoped>
.dao {
  &-info {
    padding: 20px;
    .row {
      margin-right: 0;
      margin-left: 0;
    }
    .col,
    .col-3,
    .col-4,
    .col-5,
    .col-7 {
      padding: 0;
    }
    &-title {
      font-weight: 600;
      font-size: 18px;
    }
    &-first-line {
      padding: 30px 0;
      border-bottom: 1px dashed var(--color-40);
      margin-bottom: 30px;

      @media screen and (max-width: 576px) {
        padding: 35px 0 30px;
      }
      .col {
        // border-right: 1px dashed var(--text-color);
        // padding-right: 60px;
        display: flex;
        gap: 40px;
        @media screen and (max-width: 576px) {
          column-gap: 30px;
        }
        // @media screen and (max-width:576px) {
        //   border-right: none;
        //   width: 100%;
        //   flex-wrap: wrap;
        //   justify-content: left !important;
        //   border-bottom: 1px dashed var(--color-80)
        // }
      }
      .col {
        .icon {
          border-radius: 50%;
          height: 38px;
          width: 38px;
        }
        .icons {
          height: 75%;
          display: flex;
          align-items: center;
          position: relative;
          .round {
            width: 12px;
            height: 12px;
            position: absolute;
            top: -10px;
            right: -10px;
          }
        }
      }
      .col-5 {
        justify-content: space-evenly;
        @media screen and (max-width: 576px) {
          width: 100%;
          justify-content: left;
          padding-top: 20px;
          border-bottom: 1px dashed var(--color-40);
        }
      }
    }
    &-second-line {
      margin-bottom: 35px;
      @media screen and (max-width: 576px) {
        margin-bottom: 50px;
      }
      .col {
        display: flex;
        flex-direction: row;
        gap: 40px;
        @media screen and (max-width: 576px) {
          column-gap: 30px;
        }
      }
    }
    &-wrapper {
      display: flex;
      flex-direction: column;
      font-weight: 500;
      font-size: 15px;
      &.dashed-right {
        border-right: 1px dashed var(--color-40);
        padding-right: 50px;
      }
      &.dashed-left {
        padding-left: 10px;
      }
      @media screen and (max-width: 576px) {
        margin-bottom: 20px;
      }

      &-span {
        color: var(--color-50);
        font-weight: 500;
        font-size: 12px;
      }

      &-value {
        font-weight: 500;
        font-size: 16px;
      }
    }

    &-tabs {
      margin-bottom: 25px;
      display: flex;
      gap: 8px;

      @media screen and (max-width: 576px) {
        gap: 10px;
      }
      .pe-2 {
        @media screen and (max-width: 576px) {
          padding-right: 0 !important;
        }
      }

      .pe-1 {
        @media screen and (max-width: 576px) {
          padding-right: 0 !important;
          padding-left: 0 !important;
        }
      }

      .ps-2 {
        @media screen and (max-width: 576px) {
          padding-left: 0 !important;
        }
      }

      .button-text {
        color: var(--color-20);
        &.active {
          color: white;
        }
      }
    }

    &-header {
      font-size: 12px;
      color: var(--color-20);
      font-weight: 450;
      padding: 20px 0;
      border-top: 1px solid var(--color-70);
      border-bottom: 1px solid var(--color-70);
      @media screen and (max-width: 576px) {
        display: none;
      }
    }
  }
}

.dao-info-title {
  font-weight: 600;
  font-size: 24px;
}
.no_span_redef {
  display: flex;
  flex-direction: column;
  font-weight: 500;
  font-size: 15px;
  @media screen and (max-width: 576px) {
    margin-bottom: 20px;
  }
}
.span_def {
  color: var(--color-80);
  font-weight: 500;
  font-size: 11px;
  margin-top: 5px;
}
</style>
