<template>
  <div class="pnf-main">
    <AuIcon
      icon="logo"
      class="pnf-logo"
    />
    <span class="pnf-text">
      404
    </span>
    <span class="pnf-text">
      {{ $t("pageNotFound.notOnSite") }}
    </span>
    <AuButton
      active-bg-color="#2aba7c"
      hovered-bg-color="#2aba7c"
      bordered
      border-color="black"
      font-color="white"
      font-size="12px"
      center
      height="55px"
      @click="$router.push({ name: 'base' })"
    >
      {{ $t("pageNotFound.toMain") }}
    </AuButton>
  </div>
</template>

<script>
export default {
  name: "PageNotFound"
};
</script>

<style scoped lang="scss">
.pnf-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: var(--main-color);
  height: 100vh;
  width: 100vw;
  gap: 20px;
}
.pnf-logo {
  width: 250px;
}
.pnf-text {
  font-size: 30px;
  text-align: center;
}
</style>