<template>
  <!-- More two variable  -->
  <div
    v-if="daoRequest?.result.length > 2"
    class="container-fluid proposal-info"
  >
    <div class="row proposal-info-first-line">
      <div class="col-12 col-sm-3 col-md-3">
        <span class="mobile-body">
          {{ momentDate(daoRequest.endAt).format("DD.MM.YYYY HH:mm:ss Z") }}
        </span>
      </div>
      <div class="col-12 col-sm-4 col-md-4 left-indent">
        <span class="mobile-body">
          {{ daoRequest.name }}
        </span>
      </div>
      <div class="col-12 col-sm-5 col-md-5">
        <span class="mobile-body">
          {{ daoRequest.description }}
        </span>
      </div>
    </div>
    <div class="row proposal-info-second-line">
      <div class="separator" />
      <div class="col-6">
        <div
          v-if="daoRequest?.daoEvents"
          class="wrapper"
        >
          <span>Результаты</span>
          <div class="chart-wrapper">
            <Apexchart
              height="160px"
              class="left-panel__card-chard"
              type="donut"
              :options="options"
              :series="series.perсent"
            />
          </div>
        </div>
        <div
          v-else
          class="wrapper"
        >
          <span>Небходимо проголосовать</span>
          <div class="chart-wrapper">
            <Apexchart
              height="160px"
              class="left-panel__card-chard"
              type="donut"
              :options="optionsDefault"
              :series="seriesDefault"
            />
          </div>
        </div>
      </div>
      <div class="col-6">
        <div
          v-if="!daoRequest?.daoEvents"
          class="wrapper vote"
        >
          <span>Выберите вариант ответа</span>
          <div class="vote-wrapper">
            <div
              v-for="(item, index) in daoRequest.result"
              :key="index"
              class="main_container"
            >
              <label class="radio-container">
                <input
                  v-model="radioInfo"
                  type="radio"
                  name="radio"
                  :value="item.id"
                >
                <span class="checkmark many" />
                <span class="answer">{{ item.name }}</span>
              </label>
            </div>
          </div>
          <div class="down_block">
            <div class="col mt-3 mb-3">
              <AuButton
                bordered
                width="100%"
                height="44px"
                border-radius="10"
                center
                :disabled="!allFields"
                @click="openConfirmDialog"
              >
                <span> Проголосовать и узнать результаты </span>
              </AuButton>
            </div>
            <span v-if="ownerAmount < daoRequest.tokenLimit">Вам не хватает DAO Token для голосования</span>
            <span>Стоимость голосования {{ daoRequest.tokenLimit }} DAO Token</span>
          </div>
        </div>
        <div
          v-else
          class="wrapper vote"
        >
          <span>Вы уже проголосовали</span>
          <div class="vote-wrapper">
            <div class="result">
              {{ daoRequest?.daoEvents[0].name }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <AuDialogModal
      v-model="showDialogModal"
      :body-text="confirmPhrase"
      @confirm="getVoice"
    />
  </div>
</template>

<script>
import _ from "lodash";
import moment from "moment";

export default {
  props: {
    daoRequest: {
      type: Object,
      default: () => {},
      required: true
    },
    userData: {
      type: Object,
      default: () => {},
      required: true
    },
    ownerAmount: {
      type: Number,
      default: 0,
      required: true
    }
  },

  data() {
    return {
      showDialogModal: false,
      radioInfo: null,
      optionsDefault: {
        colors: ["#b3b3b3"],
        dataLabels: {
          enabled: false
        },
        pie: {
          expandOnClick: false
        },
        legend: {
          show: false
        },
        labels: ["No info"]
      },
      seriesDefault: [1]
    };
  },

  computed: {
    allFields() {
      return this.radioInfo && this.ownerAmount > this.daoRequest.tokenLimit;
    },
    confirmPhrase() {
      let res = _.get(_.find(this.daoRequest.result, res => res.id == this.radioInfo), "name");
      return `Вы хотите проголосовать "${res}" по вопросу "${this.daoRequest.name}"?`;
    },
    series() {
      const count = [];
      let sum = null;
      if ("daoResults" in this.daoRequest) {
        sum = _.reduce(this.daoRequest.daoResults, (acc, el) => {
          count.push(+el.amount);
          return acc + Number(el.amount);
        }, 0);
      }
      else {
        sum = _.reduce(this.daoRequest?.result, (acc, el) => {
          count.push(el.count);
          return acc + el.count;
        }, 0);
      }

      const perсent = _.map(count, (el) => Math.round(((el * 100) / sum) * 10) / 10);
      return { count, perсent };
    },
    options() {
      const labels = [];
      this.daoRequest.result.forEach((element) => {
        labels.push(element.name);
      });

      return {
        labels: labels,
        // pie: {
        //   expandOnClick: false,
        //   donut: {
        //     size: "100%"
        //   },
        //   customScale: 0.1
        // },
        dataLabels: {
          enabled: false
        },
        legend: {
          show: true,
          fontSize: "11px",
          fontFamily: "Suisse Intl",
          labels: {
            colors: [this.isLightTheme ? "#080808" : "#FFFFFF"]
          },
          markers: {
            width: 12,
            height: 8
          },
          formatter: function (seriesName, opts) {
            return [
              seriesName,
              " - ",
              opts.w.globals.series[opts.seriesIndex],
              "%"
            ];
          }
        }
      };
    },
    isLightTheme() {
      return this.$store.getters.isLightTheme;
    }
  },

  methods: {
    openConfirmDialog() {
      this.showDialogModal = true;
    },

    async getVoice() {
      const params = {
        element: `/api/dao_elements/${this.radioInfo}`,
        value: true,
        user: this.userData[0].user,
        createdAt: new Date(),
        address: `/api/eth_addresses/${this.userData[0].id}`,
        amount: String(this.daoRequest.tokenLimit)
      };
      try {
        const res = await this.axios.post("api/dao_events", params);
        console.log("res_Voice", res.data);
        this.$emit("newDaoRequest");
      }
      catch (error) {
        console.log(error);
      }
    },
    momentDate(date) {
      return moment(date);
    }
  }
};
</script>

<style lang="scss" scoped>
.proposal-info {
  &-first-line {
    padding-top: 30px;
    padding-bottom: 25px;
    font-weight: 450;
    font-size: 12px;
    border-bottom: 1px dashed var(--color-70);
    @media screen and (max-width: 576px) {
      padding-bottom: 0px;
      .col-12 {
        margin-bottom: 35px;
        &:first-child {
          margin-bottom: 20px;
          display: flex;
          justify-content: space-between;
          padding: 10px 0;
          border-top: 1px solid var(--color-80);
          border-bottom: 1px solid var(--color-80);
          .mobile-header {
            margin-bottom: 0;
          }
        }
        &:last-child {
          margin-bottom: 30px;
        }
      }
    }
  }
  &-second-line {
    padding-top: 20px;
    padding-bottom: 40px;
    border-bottom: 1px dashed var(--color-40);
    position: relative;
    @media screen and (max-width: 576px) {
      display: flex;
      flex-direction: column;
      border-bottom: none;
    }
    .separator {
      position: absolute;
      height: 50px;
      width: 0px;
      border-right: 0.01px dashed var(--color-70);
      padding: 0;
      left: 55%;
      top: 0;
      @media screen and (max-width: 576px) {
        display: none;
      }
    }
    .wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 10px;
      @media screen and (max-width: 576px) {
        align-items: flex-start;
      }
      &.vote {
        @media screen and (max-width: 576px) {
          margin-top: 30px;
          row-gap: 20px;
        }
      }
      span {
        font-weight: 450;
        font-size: 12px;
      }
    }
  }
}
.mobile-header {
  font-size: 12px;
  color: var(--color-60);
  font-weight: 450;
  display: none;
  margin-bottom: 8px;
  @media screen and (max-width: 576px) {
    display: flex;
  }
}

.mobile-body {
  font-size: 14px;
}

.chart-wrapper {
  width: 350px;
  position: relative;
  @media screen and (max-width: 576px) {
    margin-left: -120px;
  }
}
.col,
.col-3,
.col-4,
.col-5,
.col-7 {
  padding: 0;
}

.row > * {
  padding-left: 0;
  padding-right: 0;
}
input {
  display: none;
}
.vote-wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  .main_container {
    display: flex;
    margin: 6px;
  }
  .radio-container {
    display: flex;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .radio-container input {
    opacity: 0;
    cursor: pointer;
  }
  .checkmark {
    padding: 2px;
    height: 24px;
    width: 24px;
    background-color: transparent;
    &.many {
      border: 1px solid #ed8805;
      color: #ed8805;
    }
    border-radius: 50%;
  }
  .answer {
    font-size: 10px;
    margin-top: 2px;
    margin-left: 6px;
  }
  .checkmark:after {
    display: none;
    border: 1px solid;
    border-radius: 50%;
    font-size: 16px;
    justify-content: center;
    align-items: center;
  }
  .checkmark.many:after {
    content: "✓";
  }

  .radio-container input:checked ~ .checkmark:after {
    display: flex;
  }
  .radio-container .checkmark:after {
    height: 100%;
    align-items: center;
  }
}
.left-indent {
  padding-right: 39px;
}
.down_block {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
