<template>
  <div class="news-page-wrapper">
    <h1>{{ $route.meta.title }}</h1>
    <div class="news-wrapper">
      <BlogPanel
        v-for="(blogPage, index) in blogPages"
        :key="index"
        :blog-item="blogPage"
        :style="`--bg-image: url('${blogPagesImages[index].image}');`"
      />
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { mapActions, mapGetters } from "vuex";

import BlogPanel from "../panels/components/BlogPanel.vue";

export default {
  components: {
    BlogPanel
  },
  computed: {
    ...mapGetters({
      blogPages: "blogPages"
    }),

    blogPagesImages() {
      return _.map(this.blogPages, bp => ({ image: bp.imageFile }));
    }
  },

  async mounted() {
    await this.getBlogPages();
  },

  methods: {
    ...mapActions({
      getBlogPages: "getBlogPages"
    })
  }
};
</script>

<style lang="scss" scoped>
  .news-page-wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
    .news-wrapper {
      display: flex;
      gap: 30px;
      flex-wrap: wrap;
    }
  }

</style>