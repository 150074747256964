import axios from "axios";

export default class axiosClient {
  async getNfts(params) {
    const headers = {
      accept: "application/ld+json",
      "Content-Type": "application/ld+json"
    };
    try {
      const resp = await axios.get("api/nfts", { headers, params });
      const data = resp.data["hydra:member"];
      const totalItems = resp.data["hydra:totalItems"];
      return { data, totalItems };
    }
    catch (error) {
      console.log(error);
    }
  }

  async postNft(body) {
    const headers = {
      "Content-Type": "multipart/form-data"
    };

    const resp = await axios.post("api/nfts/post_mp", body, { headers });
    return resp.data;
  }

  async patchNft(body) {
    const headers = {
      "Content-Type": "application/merge-patch+json"
    };

    const resp = await axios.patch(`api/nfts/${body.id}`, body, { headers });
    return resp.data;
  }

  async removeNft(id) {
    const headers = {
      "Content-Type": "multipart/form-data"
    };

    const resp = axios.delete(`api/nfts/${id}`, { headers });
    return resp.data;
  }

  async getNftById(id) {
    const resp = await axios.get(`api/nfts/${id}`);
    return resp.data;
  }

  async getUnits() {
    const params = {
      pagination: false
    };
    const resp = await axios.get("/api/units", params);
    return resp.data;
  }
}