import actions from "./actions";

export default {
  namespaced: true,
  state: {
    messages: [],
    totalMessages: null,
    unviewedMessageCount: null
  },

  getters: {
    messages: state => {
      return state.messages;
    },

    totalMessages: state => {
      return state.totalMessages;
    },

    unviewedMessageCount: state => {
      return state.unviewedMessageCount;
    }
  },

  mutations: {
    setMessages(state, messages) {
      state.messages = messages;
    },

    setTotalMessages(state, totalMessages) {
      state.totalMessages = totalMessages;
    },

    setUnviewedMessageCount(state, unviewedMessageCount) {
      state.unviewedMessageCount = unviewedMessageCount;
    }
  },

  actions
};