<template>
  <div
    v-click-outside="() => hideMenu()"
    class="profile"
  >
    <div class="profile__person">
      <div class="name-and-role">
        <span class="name">{{ $auth.user().first_name }}</span>
        <span class="role">{{ $auth.user().type }}</span>
      </div>
      <AuIcon
        icon="profile"
        :clickable="true"
        @click="toggleMenu"
      />
    </div>

    <ProfileMenu
      v-if="profileMenu"
      class="menu"
      @click="hideMenu"
    />

    <AuIcon
      class="profile__person-photo profile__person-mini"
      icon="profile"
      :clickable="true"
      @click="toggleMenu"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import MetaMask from "@/mixins/MetaMask";
import ProfileMenu from "./ProfileMenu";

export default {
  components: {
    ProfileMenu
  },

  mixins: [MetaMask],

  data() {
    return {
      profileMenu: false
    };
  },

  computed: {
    ...mapGetters({
      isLightTheme: "isLightTheme",
      unviewedMessageCount: "message/unviewedMessageCount",
      isChatVisible: "isChatVisible"
    })
  },

  async mounted() {
    await this.getUnviewedMessageCount();
  },

  methods: {
    ...mapActions({
      getUnviewedMessageCount: "message/getUnviewedMessageCount",
      setChatVisible: "setChatVisible"
    }),

    toggleMenu() {
      this.profileMenu = !this.profileMenu;
    },

    hideMenu() {
      this.profileMenu = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.theme-switcher {
  margin-right: 24px;
}

.profile {
  display: flex;
  align-items: center;

  &__person {
    display: flex;
    align-items: center;
    gap: 10px;

    &-photo {
      width: 40px;
      height: 40px;

      cursor: pointer;

      border-radius: 15px;
    }

    @media screen and (max-width: 640px) {
      display: none;
    }

    &-mini {
      display: none;
      @media screen and (max-width: 640px) {
        display: block;
      }
    }
  }
}

.menu {
  position: fixed;
  top: 80px;
  right: 30px;
  z-index: 100;
}
.name-and-role {
  display: flex;
  flex-direction: column;
  align-items: end;
  .name {
    font-size: 14px;
    font-weight: 500;
    white-space: nowrap;
  }
  .role {
    font-size: 12px;
    font-weight: 450;
    color: var(--color-50);
  }
}
</style>
