<template>
  <div
    class="swap-form"
    :class="{'swap-active': isActive}"
  >
    <div class="d-flex justify-content-between">
      <span class="swap-text">{{ header }}</span>
      <span class="swap-text">{{ balance }}</span>
    </div>
    <div
      class="spot"
      :can-set="!faded"
      @click.native="setSpotToValue"
    >
      <span v-if="tokenSpot">{{ $t("swap.swapInput.balance") }}</span>
      <AuDecimalStatic
        v-if="tokenSpot"
        :value="tokenSpot"
        :decimal="tokenOwner?.auToken?.decimals"
        :indent="tokenOwner?.auToken?.indent"
      />
    </div>
    <div class="swap-main">
      <div class="d-flex align-items-center">
        <span class="swap-label">{{ label }}</span>
        <AuDecimalInput
          :value="modelValue.swapValue"
          class="swap-input"
          :decimal="decimal"
          :indent="indent"
          :readonly="readonly"
          :faded="faded"
          @focusin="swapFocusIn"
          @focusout="swapFocusOut"
          @input="swapInput"
        />
      </div>
      <div class="select-width">
        <AuSelect
          :value="modelValue.swapToken"
          :options="options"
          track-by="value"
          label="label"
          :image="true"
          @input="swapSelect"
        />
      </div>
    </div>
    <div
      v-if="showPrice"
      class="d-flex align-items-center"
    >
      <span
        class="swap-label"
        :class="{'swap-red': modelValue.swapPrice == 0}"
      >
        {{ $t("swap.swapInput.price") }}
      </span>
      <AuDecimalInput
        :value="modelValue.swapPrice"
        class="swap-input"
        :decimal="decimalQuote"
        :indent="indentQuote"
        @focusin="isActive=true"
        @focusout="isActive=false"
        @input="swapPrice"
      />
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import BigNumber from "bignumber.js";
import CurrencySupport from "@/help/currencySupport";
import { mapGetters } from "vuex";

export default {
  name: "SwapInput",
  props: {
    modelValue: {
      type: Object,
      default: () => {}
    },
    tokenSpot: {
      type: String,
      default: ""
    },
    tokenOwner: {
      type: Object,
      default: () => {}
    },
    label: {
      type: String,
      default: "Value:"
    },
    header: {
      type: String,
      default: ""
    },
    options: {
      type: Array,
      default: () => []
    },
    readonly: {
      type: Boolean,
      default: false
    },
    tokenQuote: {
      type: String,
      default: ""
    },
    showPrice: {
      type: Boolean,
      default: false
    },
    faded: {
      type: Boolean,
      default: false
    }
  },

  emits: ["update:modelValue"],

  data() {
    return {
      isActive: false,
      currencySup: new CurrencySupport()
    };
  },

  computed: {
    ...mapGetters({
      tokensFiat: "tokensFiat",
      tokensGold: "tokensGold"
    }),

    balance() {
      return _.isNull(this.modelValue.swapToken) ? "--" : _.find(this.options, item => item.value == this.modelValue.swapToken).name;
    },

    token() {
      return _.isNull(this.modelValue.swapToken) ? null :
        [...this.tokensFiat, ...this.tokensGold].find(token => token.nameEng == this.modelValue.swapToken);
    },

    decimal() {
      return _.isNull(this.token) ? 2 : this.token?.decimals;
    },

    indent() {
      return _.isNull(this.token) ? 2 : this.token?.indent;
    },

    mnemonic() {
      return _.isNull(this.token) ? "-" : this.token?.mnemonicEng;
    },

    tQuote() {
      return _.isEmpty(this.tokenQuote) ? null :
        [...this.tokensFiat, ...this.tokensGold].find(token => token.nameEng == this.tokenQuote);
    },

    decimalQuote() {
      return _.isNull(this.tQuote) ? 2 : this.tQuote?.decimals;
    },

    indentQuote() {
      return _.isNull(this.tQuote) ? 2 : this.tQuote?.indent;
    }
  },

  methods: {
    swapSelect(value) {
      this.$emit("update:modelValue", {
        swapValue: this.modelValue.swapValue,
        swapToken: value,
        swapPrice: this.modelValue.swapPrice,
        isActive: this.modelValue.isActive
      });
    },

    swapInput(value) {
      this.$emit("update:modelValue", {
        swapValue: value,
        swapToken: this.modelValue.swapToken,
        swapPrice: this.modelValue.swapPrice,
        isActive: this.modelValue.isActive
      });
    },

    setSpotToValue() {
      if (!this.faded) {
        let tokenSpot = BigNumber(this.tokenSpot);
        tokenSpot = tokenSpot.dividedBy(BigNumber(10).pow(this.tokenOwner?.auToken?.decimals));
        tokenSpot = tokenSpot.toString(10);
        this.$emit("update:modelValue", {
          swapValue: tokenSpot,
          swapToken: this.modelValue.swapToken,
          swapPrice: this.modelValue.swapPrice,
          isActive: this.modelValue.isActive
        });
      }
    },

    swapPrice(value) {
      this.$emit("update:modelValue", {
        swapValue: this.modelValue.swapValue,
        swapToken: this.modelValue.swapToken,
        swapPrice: value,
        isActive: this.modelValue.isActive
      });
    },

    swapFocusIn() {
      this.isActive = true;
      this.$emit("update:modelValue", {
        swapValue: this.modelValue.swapValue,
        swapToken: this.modelValue.swapToken,
        swapPrice: this.modelValue.swapPrice,
        isActive: true });
    },

    swapFocusOut() {
      this.isActive = false;
      this.$emit("update:modelValue", {
        swapValue: this.modelValue.swapValue,
        swapToken: this.modelValue.swapToken,
        swapPrice: this.modelValue.swapPrice,
        isActive: false });
    }
  }
};
</script>

<style scoped lang="scss">
.swap-form{
  width: 400px;
  padding: 15px;
  border-radius: 10px;
  background: var(--menu-color);
  border: 1px solid transparent;
}
.swap-active{
  border: 1px solid #279063;
}
.swap-main{
  position: relative;
  display: flex;
  margin-top: 22px;
}
.swap-text{
  font-weight: 400;
  font-size: 14px;
}
.swap-input{
  border: none;
  outline:none;
  font-size: 20px !important;
}
.spot{
  font-weight: 400;
  font-size: 14px;
  display: flex;
  gap: 5px;
  width: fit-content;
  margin: 0 auto;
  justify-content: center;
  color: var(--color-40);
  margin-top: 10px;
  height: 19.5px;
  &[can-set=true] {
    span {
      &:last-child {
        border-bottom: 1px dashed;
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}
.select-width{
  width: 140px;
}
.swap-label
{
  font-size: 10px;
  margin-right: 5px;
  color: var(--color-60)
}
.swap-red{
  color: red;
}
</style>
