<template>
  <div>
    <AuBreadcrumb />
    <AuTabView @change="t => title = t">
      <AuTabPanel :title="$t('goods.indivisibleGoodsTabLabel')">
        <div class="card invest-goods">
          <!--<div class="invest-goods-title">
            Товары
          </div> -->
          <div v-if="noItemsNft">
            <span>
              {{ $t("goods.noData") }}
            </span>
          </div>
          <div v-else>
            <div class="d-flex">
              <div class="invest-goods-indent">
                <div class="invest-goods-count">
                  <span>{{ `${totalItemsNft} ${$t('goods.goodsUnits')}` }}</span>
                </div>
                <div class="invest-goods-explain">
                  {{ $t('goods.goodsSubtitle') }}
                </div>
              </div>
            </div>
            <div class="invest-goods-table">
              <AuTable
                :columns="columnsNft"
                :items="itemsNft"
                :icons="null"
                action-type="Dropdown"
                :action-column="actionColumn"
                :loading="loading"
                goods-link
                is-icon-links
              >
                <template #default="{ item }">
                  <div class="buttons">
                    <AuButton
                      bordered
                      center
                      @click="transferGood(item)"
                    >
                      {{ $t('goods.transferGoodButton') }}
                    </AuButton>
                    <AuButton
                      bordered
                      center
                      @click="repayGood(item)"
                    >
                      {{ $t('goods.repayGoodButton') }}
                    </AuButton>
                    <div class="d-flex align-items-center ms-4">
                      {{ $t('goods.marketLabel') }}:
                    </div>
                    <AuButton
                      bordered
                      center
                      @click="showSaleDialog(item)"
                    >
                      {{ $t('goods.saleGoodButton') }}
                    </AuButton>
                    <AuButton
                      bordered
                      center
                      @click="showMarketDialog('change', item)"
                    >
                      {{ $t("goods.changePrice") }}
                    </AuButton>
                    <AuButton
                      bordered
                      center
                      @click="showMarketDialog('remove', item)"
                    >
                      {{ $t("goods.removeFromSale") }}
                    </AuButton>
                  </div>
                </template>
                <template #image="{ value }">
                  <div>
                    <img
                      class="ig-preview"
                      :src="value"
                      alt=""
                    >
                  </div>
                </template>
              </AuTable>
              <AuPagination
                v-if="totalItemsNft > 30"
                :total-items="totalItemsNft"
                @pageChanged="pageChangedNft"
              />
            </div>
            <div class="invest-goods-accordion">
              <AuAccordion
                v-for="(itemNft, index) in itemsNft"
                :key="index"
                :last-child="(index == itemsNft.length - 1)"
              >
                <template #title>
                  <span class="nft-item-header">{{ (index + 1) }}</span>
                </template>
                <template #content>
                  <div class="nft-item-info container-fluid">
                    <div class="info-row">
                      <div class="col nft-item-info-title">
                        <span>
                          Номер
                        </span>
                      </div>
                      <div class="col">
                        <span>{{ itemNft.id }}</span>
                      </div>
                    </div>
                    <div class="info-row">
                      <div class="col nft-item-info-title">
                        <span>
                          Изображение
                        </span>
                      </div>
                      <div class="col">
                        <img
                          class="nft-item-icon"
                          :src="getNftImage(itemsNft, itemNft.id)"
                          :alt="itemNft.name"
                        >
                      </div>
                    </div>
                    <div class="info-row">
                      <div class="col nft-item-info-title">
                        <span>
                          Товар
                        </span>
                      </div>
                      <div class="col">
                        <span>{{ itemNft.name }}</span>
                      </div>
                    </div>
                    <div class="info-row">
                      <div class="col nft-item-info-title">
                        <span>
                          Стоимость
                        </span>
                      </div>
                      <div class="col">
                        <span>{{ itemNft.price }}</span>
                      </div>
                    </div>
                    <div class="info-row">
                      <div class="col nft-item-info-title">
                        <span>
                          {{ `Стоимость покупки в ${defaultTokenMnemonic}` }}
                        </span>
                      </div>
                      <div class="col">
                        <span>{{ itemNft.priceInDefaultToken }}</span>
                      </div>
                    </div>
                    <div class="buttons mobile">
                      <AuButton
                        bordered
                        center
                        @click="transferGood(itemNft)"
                      >
                        {{ $t('goods.transferGoodButton') }}
                      </AuButton>
                      <AuButton
                        bordered
                        center
                        @click="repayGood(itemNft)"
                      >
                        {{ $t('goods.repayGoodButton') }}
                      </AuButton>
                      <div class="d-flex align-items-center">
                        {{ $t('goods.marketLabel') }}:
                      </div>
                      <AuButton
                        bordered
                        center
                        @click="showSaleDialog(itemNft)"
                      >
                        {{ $t('goods.saleGoodButton') }}
                      </AuButton>
                      <AuButton
                        bordered
                        center
                        @click="showMarketDialog('change', itemNft)"
                      >
                        {{ $t("goods.changePrice") }}
                      </AuButton>
                      <AuButton
                        bordered
                        center
                        @click="showMarketDialog('remove', itemNft)"
                      >
                        {{ $t("goods.removeFromSale") }}
                      </AuButton>
                    </div>
                  </div>
                </template>
              </AuAccordion>
            </div>
          </div>
        </div>
      </AuTabPanel>
      <AuTabPanel :title="$t('goods.quantitativeGoodsTabLabel')">
        <div v-if="noItemsNftOwner">
          <span>
            {{ $t("goods.noData") }}
          </span>
        </div>
        <div v-else>
          <div class="d-flex">
            <div class="invest-goods-indent">
              <div class="invest-goods-count">
                <span>{{ `${totalItemsNftOwner} ${$t('goods.goodsUnits')}` }}</span>
              </div>
              <div class="invest-goods-explain">
                {{ $t('goods.goodsSubtitle') }}
              </div>
            </div>
          </div>
          <div class="countable-goods-table">
            <AuTable
              :columns="columnsNftOwners"
              :items="itemsNftOwner"
              :icons="null"
              action-type="Dropdown"
              :action-column="actionColumn"
              :loading="loading"
              goods-link
              is-icon-links
            >
              <template #default="{ item }">
                <div class="buttons">
                  <AuButton
                    bordered
                    center
                    @click="transferGood(item)"
                  >
                    {{ $t('goods.transferGoodButton') }}
                  </AuButton>
                  <AuButton
                    bordered
                    center
                    @click="repayGood(item)"
                  >
                    {{ $t('goods.repayGoodButton') }}
                  </AuButton>
                  <div class="d-flex align-items-center ms-4">
                    {{ $t('goods.marketLabel') }}:
                  </div>
                  <AuButton
                    bordered
                    center
                    @click="showSaleDialog(item)"
                  >
                    {{ $t('goods.saleGoodButton') }}
                  </AuButton>
                  <AuButton
                    bordered
                    center
                    @click="showMarketDialog('change', item)"
                  >
                    {{ $t("goods.changePrice") }}
                  </AuButton>
                  <AuButton
                    bordered
                    center
                    @click="showMarketDialog('remove', item)"
                  >
                    {{ $t("goods.removeFromSale") }}
                  </AuButton>
                </div>
              </template>
              <template #image="{ value }">
                <div>
                  <img
                    class="ig-preview"
                    :src="value"
                    alt=""
                  >
                </div>
              </template>
            </AuTable>
            <AuPagination
              v-if="totalItemsNftOwner > 30"
              :total-items="totalItemsNftOwner"
              @pageChanged="pageChangedNftOwner"
            />
          </div>
          <div class="countable-goods-accordion">
            <AuAccordion
              v-for="(itemNft, index) in itemsNftOwner"
              :key="index"
              :last-child="(index == itemsNftOwner.length - 1)"
            >
              <template #title>
                <span class="nft-item-header">{{ (index + 1) }}</span>
              </template>
              <template #content>
                <div class="nft-item-info container-fluid">
                  <div class="info-row">
                    <div class="col nft-item-info-title">
                      <span>
                        Номер
                      </span>
                    </div>
                    <div class="col">
                      <span>{{ itemNft.id }}</span>
                    </div>
                  </div>
                  <div class="info-row">
                    <div class="col nft-item-info-title">
                      <span>
                        Изображение
                      </span>
                    </div>
                    <div class="col">
                      <img
                        class="nft-item-icon"
                        :src="getNftImage(itemsNftOwner, itemNft.id)"
                        :alt="itemNft.name"
                      >
                    </div>
                  </div>
                  <div class="info-row">
                    <div class="col nft-item-info-title">
                      <span>
                        Товар
                      </span>
                    </div>
                    <div class="col">
                      <span>{{ itemNft.name }}</span>
                    </div>
                  </div>
                  <div class="info-row">
                    <div class="col nft-item-info-title">
                      <span>
                        Остаток
                      </span>
                    </div>
                    <div class="col">
                      <span>{{ itemNft.reminder }}</span>
                    </div>
                  </div>
                  <div class="info-row">
                    <div class="col nft-item-info-title">
                      <span>
                        В продаже
                      </span>
                    </div>
                    <div class="col">
                      <span>{{ itemNft.onSale }}</span>
                    </div>
                  </div>
                  <div class="info-row">
                    <div class="col nft-item-info-title">
                      <span>
                        Цена
                      </span>
                    </div>
                    <div class="col">
                      <span>{{ itemNft.price }}</span>
                    </div>
                  </div>
                  <div class="buttons mobile">
                    <AuButton
                      bordered
                      center
                      @click="transferGood(itemNft)"
                    >
                      {{ $t('Передать') }}
                    </AuButton>
                    <AuButton
                      bordered
                      center
                      @click="repayGood(itemNft)"
                    >
                      {{ $t('Погасить') }}
                    </AuButton>
                    <div class="d-flex align-items-center">
                      Маркет:
                    </div>
                    <AuButton
                      bordered
                      center
                      @click="showSaleDialog(itemNft)"
                    >
                      {{ $t('Продать') }}
                    </AuButton>
                    <AuButton
                      bordered
                      center
                      @click="showMarketDialog('change', itemNft)"
                    >
                      {{ $t("goods.changePrice") }}
                    </AuButton>
                    <AuButton
                      bordered
                      center
                      @click="showMarketDialog('remove', itemNft)"
                    >
                      {{ $t("goods.removeFromSale") }}
                    </AuButton>
                  </div>
                </div>
              </template>
            </AuAccordion>
          </div>
        </div>
      </AuTabPanel>
      <AuTabPanel :title="$t('goods.displacementsTabLabel')">
        <div :class="{ 'card': !noTokenTransitions }">
          <div v-if="noTokenTransitions">
            <span>
              {{ $t("goods.displacementsLabel") }}
            </span>
          </div>
          <div v-else>
            <div class="card__header">
              <div class="fs24">
                {{ $t('goods.displacementsLabel') }}
              </div>

              <div class="filters">
                <div class="filter-buttons">
                  <AuSwitchButton
                    :active="isAll"
                    width="66px"
                    border-radius="8"
                    :label="$t('transactions.allPlaceholder')"
                    @click="cleanFilters"
                  >
                    {{ $t('goods.all') }}
                  </AuSwitchButton>

                  <AuSelect
                    :value="showFracted"
                    class="show-fracted-select"
                    :options="showFractedOptions"
                    :placeholder="$t('goods.divisibleOptionsPlaceholder')"
                    :allow-empty="true"
                    @input="setShowFracted"
                  />

                  <Datepicker
                    v-model="date"
                    :locale="locale"
                    auto-apply
                    :max-date="new Date()"
                    :enable-time-picker="false"
                    :format="format"
                    :placeholder="$t('transactions.datePickerPlaceholder')"
                    range
                    :dark="!isLightTheme"
                  />
                </div>
              </div>
            </div>

            <div>
              <div class="transitions-list">
                <div
                  v-for="(group, groupIndex) in filtered"
                  :key="groupIndex"
                  class="transitions-group"
                >
                  <div class="period">
                    {{ group.date }}
                  </div>

                  <div class="transitions">
                    <TokenTransfer
                      v-for="(transition, index) in group.transitions"
                      :key="`${groupIndex}${index}`"
                      :nft="transition.nft"
                      :transfer-date="transition.createdAt"
                      :amount="transition.amount"
                      :hash="transition.hash"
                      :type="checkTransitionType(transition.id) == 'from' ? $t('goods.transferFrom') : $t('goods.transferTo')"
                      :address="checkTransitionType(transition.id) == 'from' ? transition.ethAddressToString : transition.ethAddressFromString"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AuTabPanel>
    </AuTabView>
    <AuModal
      v-model="saleDialog"
      width="400px"
    >
      <template #header>
        <div class="modal_header">
          Выставление на продажу
        </div>
      </template>
      <template #body>
        <div class="modal_text_small mt-2">
          Введите цену
        </div>
        <AuInput
          v-model="salePrice"
          type="Number"
          clearable
        />
        <div v-if="!isSimpleNft">
          <div class="modal_text_small mt-2">
            Введите количество
          </div>
          <AuInput
            v-model="saleValue"
            type="Number"
            clearable
          />
        </div>
        <div class="modal_text_small mt-2">
          Выберите токен валюты
        </div>
        <AuSelect
          :value="saleTokenQuote"
          :options="tokensOptions"
          clearable
          @input="inputTokenQuote"
          @clearValue="clearTokenQuote"
        />
      </template>
      <template #footer>
        <div class="modal_button">
          <div class="modal_button_margin">
            <AuButton
              bordered
              center
              width="100"
              @click="saleGood"
            >
              На продажу
            </AuButton>
          </div>
          <div class="modal_button_margin">
            <AuButton
              bordered
              center
              width="100"
              @click="saleDialog = false"
            >
              {{ $t("goods.modal.goodsButtonCancel") }}
            </AuButton>
          </div>
        </div>
      </template>
    </AuModal>
    <AuModal
      v-model="showDialog"
      width="400px"
    >
      <template #header>
        <div class="modal_header">
          {{ header }}
        </div>
      </template>
      <template #body>
        <div class="mb-2 modal_text_name">
          {{ $t("goods.secondColumn") }}: {{ dialogNft?.productName }}
        </div>
        <div class="mb-2">
          <img
            class="modal_image"
            :src="dialogNft?.image"
            alt=""
          >
        </div>
        <AuInput
          v-if="dialogNft?.isMultiToken || dialogMode === 'change'"
          v-model="dialogCount"
          type="Number"
          clearable
          :placeholder="placeholder"
        />
        <div
          v-if="dialogMode === 'change'"
          class="mt-2"
        >
          <AuSelect
            :value="dialogTokenQuote"
            :options="tokensOptions"
            clearable
            @input="v => dialogTokenQuote = v"
            @clearValue="dialogTokenQuote = null"
          />
        </div>
        <div class="d-flex mt-2">
          <span
            v-if="isRemoteError"
            class="modal_text_error"
          >
            {{ $t("toasts.error.goodAmountExcess") }}
          </span>
        </div>
      </template>
      <template #footer>
        <div class="modal_button">
          <div class="modal_button_margin">
            <AuButton
              bordered
              center
              width="80"
              :disabled="isRemoteError"
              @click="changeOrRemote"
            >
              {{ button }}
            </AuButton>
          </div>
          <div class="modal_button_margin">
            <AuButton
              bordered
              center
              width="80"
              @click="showDialog = false"
            >
              {{ $t("goods.modal.goodsButtonCancel") }}
            </AuButton>
          </div>
        </div>
      </template>
    </AuModal>
  </div>
</template>

<script>
import _ from "lodash";
import i18n from "@/localization/localization.js";
import moment from "moment";

import AuTabPanel from "@/components/extended/tabs/AuTabPanel";
import AuTabView from "@/components/extended/tabs/AuTabView";
import CurrencySupport from "@/help/currencySupport";
import TokenTransfer from "@/components/tokenTransfer/TokenTransfer";

import BigNumber from "bignumber.js";
import Datepicker from "@vuepic/vue-datepicker";

import { mapActions, mapGetters } from "vuex";
export default {
  name: "InvestGoods",
  components: { AuTabPanel, AuTabView, Datepicker, TokenTransfer },
  data() {
    return {
      columnsNftOwners: [
        { name: this.$t("goods.columnsNftOwnersTable.firstColumn"), align: "start" },
        { name: this.$t("goods.columnsNftOwnersTable.secondColumn"), align: "start", slot: "image" },
        { name: this.$t("goods.columnsNftOwnersTable.thirdColumn"), align: "start" },
        { name: this.$t("goods.columnsNftOwnersTable.fourthColumn") },
        { name: this.$t("goods.columnsNftOwnersTable.fifthColumn") },
        { name: this.$t("goods.columnsNftOwnersTable.sixthColumn") },
        { name: "", align: "end" }
      ],
      actionColumn: { icon: ["chevron_down", "chevron_up"] },
      pageNft: 1,
      totalItemsNft: 1,
      pageNftOwner: 1,
      totalItemsNftOwner: 1,
      nfts: [],
      date: [new Date(new Date().getFullYear(), new Date().getMonth(), 1), new Date()],
      nftOwners: [],
      saleDialog: false,
      salePrice: null,
      saleTokenQuote: null,
      title: "Неделимые товары",
      saleId: null,
      saleValue: "1",
      ifInMarket: false,
      currencySup: new CurrencySupport(),
      showDialog: false,
      dialogCount: null,
      dialogDecimal: null,
      dialogTokenQuote: null,
      dialogNft: null,
      dialogMode: "change",
      showFracted: null,
      loading: true
    };
  },

  computed: {
    ...mapGetters({
      tokensFiat: "tokensFiat",
      tokensGold: "tokensGold",
      defaultTokenMnemonic: "defaultToken/defaultTokenMnemonic",
      userToNftTransactions: "userToNftTransactions",
      userFromNftTransactions: "userFromNftTransactions",
      isLightTheme: "isLightTheme"
    }),

    columnsNft() {
      return [
        { name: this.$t("goods.columnsNftTable.firstColumn"), align: "start" },
        { name: this.$t("goods.columnsNftTable.secondColumn"), align: "start", slot: "image" },
        { name: this.$t("goods.columnsNftTable.thirdColumn"), align: "start" },
        { name: this.$t("goods.columnsNftTable.fourthColumn") },
        { name: `${this.$t("goods.columnsNftTable.fifthColumn")} ${this.defaultTokenMnemonic}` },
        { name: "", align: "end" }];
    },

    allTokenTransitions() {
      let groupedByData = _.groupBy([...this.userToNftTransactions, ...this.userFromNftTransactions], data => moment(data.createdAt).locale("ru").format("L"));
      let res = [];
      for (const key in groupedByData) {
        res.push({
          date: key,
          transitions: groupedByData[key]
        });
      }
      return res;
    },

    filtered() {
      if (this.isAll) {
        return this.allTokenTransitions;
      }

      let res = _.cloneDeep(this.allTokenTransitions);

      if (!_.isUndefined(this.showFracted)) {
        res.forEach(item => {
          if (this.showFracted) {
            item.transitions = _.filter(item.transitions, t => _.get(t, "nft.isFracted"));
          }
          else {
            item.transitions = _.reject(item.transitions, t => _.get(t, "nft.isFracted"));
          }
        });
        res = _.reject(res, item => _.isEmpty(item.transitions));
      }

      if (this.date !== null) {
        const day1 = this.date[0].getDate();
        const month1 = this.date[0].getMonth() + 1;
        const year1 = this.date[0].getFullYear();

        const day2 = this.date[1].getDate();
        const month2 = this.date[1].getMonth() + 1;
        const year2 = this.date[1].getFullYear();

        if (year1 == year2 && month1 == month2 && day1 == day2) {
          res = _.filter(res, item => {
            return moment(item.date, "DD.MM.YYYY").format("DD.MM.YYYY").toString() == moment(this.date[0]).format("DD.MM.YYYY").toString();
          });
        }
        else {
          res = _.filter(res, item => {
            return moment(item.date, "DD.MM.YYYY").format("DD").toString() >= moment(this.date[0]).format("DD").toString() &&
              moment(item.date, "DD.MM.YYYY").format("MM").toString() >= moment(this.date[0]).format("MM").toString() &&
              moment(item.date, "DD.MM.YYYY").format("YYYY").toString() >= moment(this.date[0]).format("YYYY").toString() &&
              moment(item.date, "DD.MM.YYYY").format("DD").toString() <= moment(this.date[1]).format("DD").toString() &&
              moment(item.date, "DD.MM.YYYY").format("MM").toString() <= moment(this.date[1]).format("MM").toString() &&
              moment(item.date, "DD.MM.YYYY").format("YYYY").toString() <= moment(this.date[1]).format("YYYY").toString();
          });
        }
        res = _.reject(res, item => _.isEmpty(item.transitions));
      }
      return res;
    },

    showFractedOptions() {
      return [
        {
          display: this.$t("goods.divisibleOption"),
          value: true
        },
        {
          display: this.$t("goods.indivisibleOption"),
          value: false
        }
      ];
    },

    itemsNft() {
      return _.map(this.nfts, item => {
        let price = BigNumber(item.price).dividedBy(BigNumber(10).pow(item.tokenQuote?.decimals));
        let priceInDefaultToken = this.currencySup.getCurrency(item.tokenQuote?.id, this.defaultTokenMnemonic);
        priceInDefaultToken = price.multipliedBy(priceInDefaultToken).toFixed(item.tokenQuote?.decimals);
        price = price.toFixed(item.tokenQuote?.decimals) + ` ${item.tokenQuote?.mnemonicEng}`;
        return {
          id: item.id,
          image: item.image,
          name: item.productName,
          price: price,
          priceInDefaultToken: priceInDefaultToken
        };
      });
    },

    isAll() {
      return this.showFracted == null && this.date == null;
    },

    itemsNftOwner() {
      return _.map(this.nftOwners, item => ({
        id: item.nft.id,
        image: item.nft.image,
        name: item.nft?.productName,
        reminder: !item.isInMarket ? item.amount : 0,
        onSale: item.isInMarket ? item.amount : 0,
        price: _.get(item, "marketPrice", "-")
      }));
    },

    sumPrice() {
      let sum = 0;
      this.items.forEach(i => {
        sum += i.price;
      });
      return sum;
    },

    isSimpleNft() {
      return this.title === this.$t("goods.indivisibleGoodsTabLabel");
    },

    header() {
      return this.dialogMode === "change" ? this.$t("goods.modal.goodsChangeHeader") :
        this.$t("goods.modal.goodsRemoveHeader");
    },

    placeholder() {
      return this.dialogMode === "change" ? this.$t("goods.modal.goodsChangePlaceholder") :
        this.$t("goods.modal.goodsRemovePlaceholder");
    },

    button() {
      return this.dialogMode === "change" ? this.$t("goods.modal.goodsButtonChange") :
        this.$t("goods.modal.goodsButtonRemove");
    },

    isRemoteError() {
      return this.dialogMode !== "change" &&
        (BigNumber(this.dialogCount).isGreaterThan(this.dialogNft.onSale));
    },

    tokensOptions() {
      return _.map([...this.tokensGold, ...this.tokensFiat], token => (
        { display: token.nameRus || token.nameEng, value: `/api/au_tokens/${token.id}` }));
    },

    noItemsNft() {
      return _.isEmpty(this.itemsNft) && !this.loading;
    },

    noItemsNftOwner() {
      return _.isEmpty(this.itemsNftOwner) && !this.loading;
    },

    noTokenTransitions() {
      return _.isEmpty(this.allTokenTransitions) && !this.loading;
    },

    locale() {
      return i18n.global.locale;
    }
  },

  async mounted() {
    this.$router.push({ name: "projects" });
    await this.getNftsPage();
    await this.getUsersNftTransactions(this.$auth.user().id);
    await this.getNftOwnersPagination();

    this.loading = false;
  },

  methods: {
    ...mapActions({
      getNfts: "goods/getCurrentUserNfts",
      getNftOwnersPage: "getNftOwnersPage",
      sendTokenEvents: "sendTokenEvents",
      getNftOwnersById: "marketplace/getNftOwnersById",
      getUsersNftTransactions: "getUsersNftTransactions"
    }),

    checkTransitionType(id) {
      if (_.some(this.userFromNftTransactions, { id })) {
        return "from";
      }
      return "to";
    },

    getNftImage(collection, id) {
      return _.chain(_.find(collection, { id })).get("image").value();
    },

    async pageChangedNft(actualPage) {
      this.pageNft = actualPage;
      await this.getNftsPage();
    },

    setShowFracted(value) {
      if (_.isUndefined(value)) {
        this.showFracted = null;
      }
      else {
        this.showFracted = value;
      }
    },

    async pageChangedNftOwner(actualPage) {
      this.pageNftOwner = actualPage;
      await this.getNftOwnersPagination();
    },

    format(date) {
      const day1 = date[0].getDate();
      const month1 = date[0].getMonth() + 1;
      const year1 = date[0].getFullYear();

      const day2 = date[1].getDate();
      const month2 = date[1].getMonth() + 1;
      const year2 = date[1].getFullYear();

      if (year1 == year2 && month1 == month2 && day1 == day2) {
        return `${moment(date[0]).locale(this.locale).format("L")}`;
      }

      if (year1 == year2 && month1 == month2 && day1 == 1 && (day2 == new Date(year2, month2, 0).getDate() || day2 == new Date().getDate())) {
        let month = moment(date[0]).locale(this.locale).format("MMMM");
        return month[0].toUpperCase() + month.slice(1) + " " + year1;
      }

      if (year1 == year2 && month1 == 1 && day1 == 1 && month2 == 12 && day2 == 31) {
        return year1;
      }

      return `${moment(date[0]).locale(this.locale).format("L")} - ${moment(date[1]).locale(this.locale).format("L")}`;
    },

    cleanFilters() {
      this.showFracted = null;
      this.date = null;
    },

    async getNftsPage() {
      const resp = await this.getNfts({
        page: this.pageNft
      });
      this.nfts = resp.data;
      this.totalItemsNft = resp.totalItems;
    },

    async getNftOwnersPagination() {
      const resp = await this.getNftOwnersPage({
        page: this.pageNftOwner,
        userId: this.$auth.user().id
      });
      this.totalItemsNftOwner = resp.totalItems;
      this.nftOwners = resp.data;
    },

    transferGood(item) {
      console.log("Передать", item);
    },

    async showSaleDialog(item) {
      this.saleId = item.id;
      this.saleValue = "1";
      this.saleTokenQuote = null;
      this.salePrice = null;
      if (!this.isSimpleNft) {
        await this.isNftInMarketplace(item.id);
      }
      this.saleDialog = true;
    },

    async isNftInMarketplace(id) {
      this.loading = true;
      const resp = await this.getNftOwnersById({ id: id, isInMarket: true });
      if (resp.length !== 0) {
        this.ifInMarket = true;
        this.saleTokenQuote = resp[0].nft?.tokenQuote;
      }
      else {
        this.ifInMarket = false;
      }
      this.loading = false;
    },

    showMarketDialog(mode, item) {
      this.dialogMode = mode;
      if (this.isSimpleNft) {
        this.dialogNft = _.find(this.nfts, el => el.id == item.id);
        this.dialogTokenQuote = `/api/au_tokens/${this.dialogNft?.tokenQuote?.id}`;
      }
      else {
        this.dialogNft = _.find(this.nftOwners, el => el?.nft?.id == item.id)?.nft;
        this.dialogTokenQuote = this.dialogNft?.tokenQuote;
        this.dialogNft.onSale = item.onSale;
      }
      this.dialogCount = mode === "change" ? String(this.dialogNft?.price) : "1";
      this.dialogDecimal = mode === "change" ? this.dialogNft?.tokenQuote?.decimals : 0;
      this.showDialog = true;
    },

    changeOrRemote() {
      this.showDialog = false;
      if (this.dialogMode === "change") {
        this.changePrice();
      }
      this.removeNft();
    },

    async saleGood() {
      this.saleDialog = false;
      const params = {
        user: `/api/users/${this.$auth.user().id}`,
        type: "token_put_on_market",
        description: "on market",
        isTask: true,
        value: this.saleValue,
        nftNumber: this.saleId,
        tokenQuote: this.saleTokenQuote,
        price: this.salePrice
      };

      try {
        await this.sendTokenEvents(params);
        this.showSuccess("Заявка на размещение товара в маркете успешно отправлена");
      }
      catch (e) {
        console.log(e);
        this.showError("Ошибка размещения товара в маркете");
      }
    },

    async changePrice() {
      const params = {
        user: this.currentUser,
        type: "token_nft_change_price",
        description: "change price",
        priceString: this.dialogCount,
        isTask: true,
        nftNumber: this.dialogNft.id,
        tokenQuote: this.dialogTokenQuote
      };

      try {
        await this.sendTokenEvents(params);
        this.showSuccess(this.$t("toasts.success.goodChangePrice"));
      }
      catch (e) {
        this.showError(this.$t("toasts.error.goodChangePrice"));
      }
    },

    async removeNft() {
      const params = {
        user: this.currentUser,
        type: "token_nft_return",
        description: "token_nft_return",
        value: this.dialogCount,
        isTask: true,
        nftNumber: this.dialogNft.id
      };

      try {
        await this.sendTokenEvents(params);
        this.showSuccess(this.$t("toasts.success.goodRemove"));
      }
      catch (e) {
        this.showError(this.$t("toasts.error.goodRemove"));
      }
    },

    repayGood(item) {
      console.log("Погасить", item);
    },

    showSuccess(message) {
      this.$toast.success(message);
    },

    showError(message) {
      this.$toast.error(message);
    },

    inputTokenQuote(value) {
      if (!this.isSimpleNft && this.ifInMarket) {
        return;
      }
      this.saleTokenQuote = value;
    },

    clearTokenQuote() {
      if (!this.isSimpleNft && this.ifInMarket) {
        return;
      }
      this.saleTokenQuote = null;
    }
  }
};
</script>

<style scoped lang="scss">
.buttons {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;

  &.mobile {
    margin: 0;
    flex-direction: column;

  }
}

div.invest-goods {
  // padding: 20px;
  font-family: 'Suisse Intl', serif;
  font-style: normal;
  padding: 0;

  &-table {
    @media screen and (max-width:500px) {
      display: none;
    }
  }

  &-accordion {
    display: none;

    @media screen and (max-width:500px) {
      display: block;
    }
  }
}

.countable-goods {
  &-table {
    @media screen and (max-width:500px) {
      display: none;
    }
  }

  &-accordion {
    display: none;

    @media screen and (max-width:500px) {
      display: block;
    }
  }
}

.invest-goods-title {
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 35px;
}

.invest-goods-count {
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 10px;
}

.invest-goods-explain {
  font-weight: 450;
  font-size: 12px;
  color: var(--color-60);
  margin-bottom: 40px;
}

.invest-goods-divider {
  border: 1px dashed var(--color-60);
  margin-bottom: 20px;
}

.invest-goods-indent {
  margin-right: 40px;
}

.modal_button {
  display: flex;
  justify-content: end;
  margin-top: 15px;
}

.modal_button_margin {
  margin-right: 10px;
}

.show-fracted-select {
  width: 215px;
}

.modal_header {
  margin-bottom: 10px;
  border-bottom: 1px solid var(--color-70);
}

.modal_text_small {
  font-size: 12px;
}

.modal_text_error {
  color: red;
  font-size: 10px;
}

.modal_image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 10px;
}

.modal_text_name {
  color: var(--color-40);
  font-size: 13px;
}

.ig-preview {
  width: 50px;
  height: 30px;
  object-fit: contain;
}

.transitions {
  display: flex;
  flex-direction: column;
  gap: 15px;

  &-list {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  &-footer {
    margin-top: 40px;
    padding-top: 20px;
    display: flex;
    justify-content: center;

    border-top: 1px dashed var(--color-70);
  }

  &-info {
    display: flex;
    border-bottom: 1px solid var(--color-70);
    padding: 12px 0px;

    @media screen and (max-width:576px) {
      flex-direction: column;
    }

    &__chart {
      display: flex;
      align-items: center;
      margin-left: -17px;
      margin-right: 40px;

      @media screen and (max-width:576px) {
        justify-content: space-between;
      }
    }

    &__cards {
      display: flex;
      align-items: center;
      gap: 10px;
      flex-wrap: wrap;
      padding: 50px 0px;
    }
  }
}

.period {
  margin: 20px 0px;
  font-size: 12px;
  color: var(--color-60);
}

.card {
  width: 100%;
  padding: 12px 22px 22px 20px;

  &__header {
    display: flex;
    flex-direction: column;
    gap: 40px;

    border-bottom: 1px solid var(--color-70);
    padding-bottom: 25px;

    &-date {
      font-size: 14px;
    }

    &-line {
      display: flex;
      justify-content: space-between;
    }

    &-month {
      color: #279063
    }
  }

  &__action {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-right: -15px;
  }
}

.filters {
  display: flex;
  justify-content: space-between;
}

.filter-buttons {
  display: flex;
  gap: 10px;
}

.col {
  padding: 0;
  font-size: 12px;
}

.nft-item {
  &-header {
    color: var(--text-color);
    font-size: 12px;
  }

  &-info {
    color: var(--text-color);
    display: flex;
    padding-right: 0;
    padding-left: 0;
    flex-direction: column;
    gap: 20px;

    &-title {
      color: var(--color-60);
      text-align: start
    }

    .info-row {
      font-weight: 450;
      font-size: 12px;
      display: flex;

      .col:last-child {
        text-align: end;
      }
    }
  }

  &-icon {
    width: 26px;
    height: 26px;
  }
}
</style>