<template>
  <span
    class="text-error"
  >{{ errorMsg }}</span>
</template>

<script>
export default {
  name: "AuError",
  props: {
    errorMsg: {
      type: String,
      default: ""
    }
  }
};
</script>
<style lang="scss" scoped>
.text-error {
  font-size: 12px;
  text-align: center;
  color: red;
}
</style>