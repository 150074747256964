<template>
  <div
    class="card"
    :style="envColors"
  >
    <div class="card__header">
      <div class="title">
        Info from .env
      </div>
    </div>

    <table class="env-table">
      <thead>
        <tr>
          <th>#</th>
          <th>Key</th>
          <th>Value</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(envVar, index) in envVariables"
          :key="index"
        >
          <td>{{ index + 1 }}</td>
          <td>{{ envVar.key }}</td>
          <td>{{ envVar.value }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Env",
  data() {
    return {
      envVariables: []
    };
  },
  computed: {
    ...mapGetters({
      isLightTheme: "isLightTheme"
    }),
    envColors() {
      return {
        "--even-color": this.isLightTheme ? "#f9f9f9" : "#393939",
        "--hover-color": this.isLightTheme ? "#f1f1f1" : "#858585"
      };
    }
  },
  mounted() {
    this.getEnvVariables();
  },
  methods: {
    getEnvVariables() {
      const envVars = Object.keys(process.env)
        .filter(key => key.startsWith("VUE_APP_"))
        .reduce((acc, key) => {
          acc.push({ key, value: process.env[key] });
          return acc;
        }, []);

      this.envVariables = envVars;
    }
  }
};
</script>

<style lang="scss" scoped>

.card {
  width: 100%;
  padding: 22px;

  &__header {
    display: flex;
    flex-direction: column;
    gap: 40px;

    border-bottom: 1px solid var(--color-70);
    padding-bottom: 15px;
  }
  .title {
    color: var(--text-color);
    font-size: 24px;
    line-height: 1em;
  }
}
.env-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;

  th, td {
    border: 1px solid #ddd;
    padding: 12px;
    text-align: left;
  }

  th {
    background-color: #f4f4f4;
    font-weight: bold;
  }

  tr:nth-child(even) {
    background-color: var(--even-color);
  }

  tr:hover {
    background-color: var(--hover-color);
  }

  th, td {
    padding: 8px 12px;
  }

  th {
    background-color: #5ac396;
    color: white;
  }

  td {
    word-wrap: break-word;
    max-width: 300px;
  }
}
</style>