<template>
  <div
    id="sumsub-websdk-container"
  />
</template>

<script>
import axios from "axios";
import snsWebSdk from "@sumsub/websdk";
import { mapGetters } from "vuex";

export default {
  name: "KYC",

  computed: {
    ...mapGetters({
      isLightTheme: "isLightTheme"
    })
  },
  watch: {
    async isLightTheme() {
      const resp = await this.getAccessToken();
      this.launch(resp?.token);
    }
  },

  async mounted() {
    const resp = await this.getAccessToken();
    this.launch(resp?.token);
  },

  methods: {
    scroll() {
      const scrollableElement = document.querySelector(".app");
      scrollableElement.scrollTop = 0;
    },
    launch(accessToken) {
      let snsWebSdkInstance = snsWebSdk
        .init(
          accessToken,
          async () => {
            return await this.getAccessToken()?.token;
          }
        )
        .withConf({
          lang: "en",
          theme: this.isLightTheme ? "light" : "dark"
        })
        .withOptions({ addViewportTag: false, adaptIframeHeight: true })
        .on("idCheck.onStepCompleted", (payload) => {
          console.log("onStepCompleted", payload);
        })
        .on("idCheck.onResize", () => {
          this.scroll();
        })
        .on("idCheck.onError", (error) => {
          console.log("onError", error);
        })
        .build();

      snsWebSdkInstance.launch("#sumsub-websdk-container");
    },

    async getAccessToken() {
      const params = {
        level: process.env.VUE_APP_SUMSUB_CHECK_LEVEL
      };
      try {
        const resp = await axios.post("/api/users/sumsub/get_token", params);
        return resp.data;
      }
      catch (e) {
        console.error("KYC error", e);
      }
    }
  }
};
</script>

<style scoped>

</style>