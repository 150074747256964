<template>
  <div>
    <AuButton
      v-if="googleBtn"
      center
      hovered-bg-color="#fafbfb"
      :width="'100%'"
      height="48px"
      @click="authGoogle"
    >
      <div class="btn_social">
        <img
          :src="`/img/login/google.svg`"
        >
      </div>
    </AuButton>
    <AuButton
      v-if="facebookBnt"
      center
      hovered-bg-color="#fafbfb"
      class="facebook-btn"
      :width="'100%'"
      height="48px"
      @click="authFb"
    >
      <div class="btn_social">
        <div
          class="facebook"
        />
        <div class="centering">
          Facebook
        </div>
      </div>
    </AuButton>
    <div class="line-container">
      <span class="line-text">or</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "SocialNetwork",
  props: {
    emitent: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    googleBtn() {
      return process.env.VUE_APP_GOOGLE_ENABLED === "true";
    },
    facebookBnt() {
      return process.env.VUE_APP_FACEBOOK_ENABLED === "true";
    }
  },
  methods: {
    async authGoogle() {
      const params = {};
      if (this.emitent) {
        params.userType = "issuer";
      }
      try {
        const resp = await this.axios.get("/api/users/oath/google/get", { params });
        window.open(resp.data.link, "_self");
      }
      catch (error) {
        console.log(error);
      }
    },
    async authFb() {
      const params = {};
      if (this.emitent) {
        params.userType = "issuer";
      }
      try {
        const resp = await this.axios.get("/api/users/oath/fb/get", { params });
        window.open(resp.data.link, "_self");
      }
      catch (error) {
        console.log(error);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.btn_social {
  display: flex;
  justify-content: center;
  align-items: center;
}
.centering {
  margin-top: 2px;
}

.facebook-btn {
  margin-top: 15px;
}

.facebook {
  background: url(../assets/img/fasebook_logo.svg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 10px;
  margin-right: 10px;
}
.google {
  background: url(../assets/img/google_logo.svg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 10px;
  margin-right: 10px;
}

.line-container {
    display: flex;
    align-items: center;
    text-align: center;
    width: 100%;
    margin-top: 30px;
    padding: 0 2px;
}
.line-container::before,
.line-container::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid #ccc;
}
.line-container::before {
    margin-right: .5em;
}
.line-container::after {
    margin-left: .5em;
}
.line-container .line-text {
    padding: 0 .5em;
    color: grey;
    margin-bottom: 4px;
}
</style>