<template>
  <div
    v-if="wizardText"
    class="card wizard"
    :style="{ background: wizardBg }"
  >
    <AuIcon
      class="icon"
      icon="alert"
    />

    <span
      v-if="wizardText.label"
    >
      {{ wizardText.label.text }}

      <RouterLink
        v-if="wizardText.link"
        :to="wizardText.link.link"
        class="link"
      >
        {{ wizardText.link.text }}
      </RouterLink>
    </span>

    <RouterLink
      v-if="wizardText.button"
      :to="wizardText.button.link"
      class="button"
    >
      {{ wizardText.button.text }}
    </RouterLink>
  </div>
</template>

<script>
import _ from "lodash";
import { mapGetters } from "vuex";

export default {
  props: {
    texts: {
      type: Array,
      required: true
    },
    stage: {
      type: Number,
      default: 0
    }
  },

  computed: {
    ...mapGetters({
      isLightTheme: "isLightTheme"
    }),

    wizardText() {
      return _.get(this.texts, `[${this.stage}]`, "");
    },

    wizardBg() {
      return _.get(this.texts, `[${this.stage}].verification`, {}) ? this.isLightTheme ? "#ffffff" : "#1c1c1e" : this.isLightTheme ? "#fcb7b7" : "#ff4848";
    }
  }
};
</script>

<style lang="scss">
.card {
  &.wizard {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;

    padding: 15px;
    color: var(--text-color);

    line-height: 1.1;

    .link {
      text-decoration: underline !important;
      color: var(--text-color);
    }

    .button {
      background-color: #5ac396;
      color: #FFF;

      padding: 5px 10px;
      border-radius: 10px;

      white-space: nowrap;

      height: fit-content;
    }

    .icon {
      margin-right: -10px;
      height: 21px;
      width: 21px;
    }
  }
}
</style>