<template>
  <WizardWrap
    v-if="!profile?.isConfirmed"
    :texts="texts"
    :stage="stage"
  />
</template>

<script>
import { mapGetters } from "vuex";

import WizardWrap from "./WizardWrap";

export default {
  components: {
    WizardWrap
  },

  data() {
    return {
      texts: [
        {
          label: {
            text: this.$t("emailWizard.title1")
          },
          link: {
            text: "",
            link: "/user"
          },
          button: {
            text: this.$t("emailWizard.titleBtn1"),
            link: "/user"
          },
          verification: false
        },
        {
          label: {
            text: this.$t("emailWizard.title2")
          },
          link: {
            text: "",
            link: "/"
          },
          button: {
            text: this.$t("emailWizard.titleBtn2"),
            link: "/user"
          },
          verification: true
        }
      ]
    };
  },

  computed: {
    ...mapGetters({
      profile: "profile/profile"
    }),

    stage() {
      if (!this.profile?.isConfirmed) {
        return 0;
      }
      return 1;
    }

  }
};
</script>