<template>
  <div>
    <AuBreadcrumb
      :parent-name="$t('router.pages.dashboard')"
    />
    <LastTransactionsTable />
  </div>
</template>

<script>
import LastTransactionsTable from "@/components/lastTransactions/LastTransactionsTable.vue";

export default {
  components: {
    LastTransactionsTable
  }
};
</script>