<template>
  <div class="dividents-info card">
    <AuTable
      class="dividents-table"
      :columns="columns"
      :items="edditedDividents"
      :icons="companyIcons"
    />
    <div class="dividents-accordion">
      <AuAccordion
        v-for="(divident, index) in edditedDividents"
        :key="divident"
        :last-child="(index == edditedDividents.length - 1)"
      >
        <template #title>
          <span class="company-header">{{ ( index + 1 ) }}</span>
        </template>
        <template #content>
          <div class="company-info container-fluid">
            <div class="row">
              <div class="col company-info-title">
                <span>{{ $t("dividents.firstColumn") }}</span>
              </div>
              <div class="col">
                <img
                  class="company-icon"
                  :src="companyIcons[index]"
                  :alt="divident.companyName"
                >
                <span>{{ divident.companyName }}</span>
              </div>
            </div>
            <div class="row">
              <div class="col company-info-title">
                <span>{{ $t("dividents.secondColumn") }}</span>
              </div>
              <div class="col">
                <span>{{ divident.tockensCount }}</span>
              </div>
            </div>
            <div class="row">
              <div class="col company-info-title">
                <span>{{ $t("dividents.thirdColumn") }}</span>
              </div>
              <div class="col">
                <span>{{ divident.tockenCost }}</span>
              </div>
            </div>
            <div class="row">
              <div class="col company-info-title">
                <span>{{ $t("dividents.fourthColumn") }}</span>
              </div>
              <div class="col">
                <span>{{ divident.expectedDividends }}</span>
              </div>
            </div>
            <div class="row">
              <div class="col company-info-title">
                <span>{{ $t("dividents.fifthColumn") }}</span>
              </div>
              <div class="col">
                <span>{{ divident.dividentsDate }}</span>
              </div>
            </div>
          </div>
        </template>
      </AuAccordion>
    </div>
  </div>
</template>

<script>
import _ from "lodash";

import { mapGetters } from "vuex";
export default {
  data() {
    return {
      columns: [{ name: this.$t("dividents.firstColumn"), align: "start" },
        { name: this.$t("dividents.secondColumn") },
        { name: this.$t("dividents.thirdColumn") },
        { name: this.$t("dividents.fourthColumn") },
        { name: this.$t("dividents.fifthColumn") }]
    };
  },

  computed: {
    ...mapGetters({
      dividents: "dividents",
      auOwners: "auOwners"
    }),

    companyIcons() {
      return _.map(_.cloneDeep(this.auOwners), company => {
        company.icon = "/svg/companies/" + _.get(company, "icon", "s_logo") + ".svg";
        return company.icon;
      });
    },

    edditedDividents() {
      return _.map(_.cloneDeep(this.auOwners), divident => {
        let newDivident = {
          companyName: divident.auToken.name,
          tockensCount: divident.amount + " шт.",
          tockenCost: `- ${this.$auth.user().defaultTokenMnemonic}`,
          expectedDividends: `- ${this.$auth.user().defaultTokenMnemonic}`,
          dividentsDate: "--/--/--"
        };
        return newDivident;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.dividents {
  &-info {
    padding: 20px 20px 0px 20px;;
    .title {
      font-weight: 600;
      font-size: 18px;
      color: var(--text-color);
      margin-bottom: 35px;
    }
  }

  &-table {
    @media screen and (max-width:520px) {
      display: none;
    }
  }

  &-accordion {
    display: none;

    @media screen and (max-width:520px) {
      display: block;
    }
  }
}
.company {
  &-header {
    color: var(--text-color);
    font-size: 12px;
  }
  &-info {
    color: var(--text-color);
    display: flex;
    padding-right: 0;
    padding-left: 0;
    flex-direction: column;
    gap: 20px;
    &-title {
      color: var(--color-60)
    }
    .row {
      font-weight: 450;
      font-size: 12px;
      .col:last-child {
        text-align: end;
      }
    }
  }
  &-icon {
    width: 26px;
    height: 26px;
    margin-right: 5px;
  }
}
</style>