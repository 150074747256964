import axios from "axios";

export default class axiosClient {
  async getCards(userId) {
    const params = {
      userId: userId
    };

    const resp = await axios.get("api/bank_cards", { params });
    return resp.data;
  }

  async addCard(card) {
    await axios.post("api/bank_cards", card);
  }

  async deleteCard(id) {
    await axios.delete("api/bank_cards/" + id);
  }

  async editCard(card) {
    await axios.put("api/bank_cards/" + card.id, card);
  }
}