import actions from "./actions";

export default {
  namespaced: true,
  state: {
    uploadedFiles: [],
    totalUploadedFiles: null,
    uploadedFile: null,
    dataLoading: false
  },

  getters: {
    uploadedFiles: state => {
      return state.uploadedFiles;
    },

    totalUploadedFiles: state => {
      return state.totalUploadedFiles;
    },

    uploadedFile: state => {
      return state.uploadedFile;
    },

    dataLoading: state => {
      return state.dataLoading;
    }
  },

  mutations: {
    setUploadedFiles(state, uploadedFiles) {
      state.uploadedFiles = uploadedFiles;
    },

    setTotalUploadedFiles(state, totalUploadedFiles) {
      state.totalUploadedFiles = totalUploadedFiles;
    },

    setUploadedFile(state, uploadedFile) {
      state.uploadedFile = uploadedFile;
    },

    setDataLoading(state, dataLoading) {
      state.dataLoading = dataLoading;
    }
  },

  actions
};