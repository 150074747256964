<template>
  <div />
</template>

<script>
import { mapActions } from "vuex";

export default {
  async mounted() {
    let secret = this.$route.params.secret;

    try {
      await this.unsubscribe(secret);

      this.$toast.success(this.$t("toasts.unsubscribe.success"));
    }
    catch (error) {
      this.$toast.error(this.$t("toasts.unsubscribe.unsuccess"));
      console.error(error);
    }
    finally {
      this.$router.push({ name: "base" });
    }
  },

  methods: {
    ...mapActions({
      unsubscribe: "unsubscribe"
    })
  }
};
</script>

<style>

</style>