<template>
  <div class="req-page-wrapper">
    <Requisite
      v-for="(company, index) in companies"
      :key="index"
      class="card requisites"
      :company="company"
      @editBankRequisites="editBankRequisites"
    />
    <AuModal
      v-if="editedBankRequisites"
      v-model="editDialog"
      width="400px"
      @closeModal="editedBankRequisites = null"
    >
      <template #header>
        <span class="modal_header">
          {{ $t('requisites.modalLabel') }}
        </span>
      </template>
      <template #body>
        <div class="modal_body">
          <AuInput
            v-model="editedBankRequisites.bankname"
            :label="$t('requisites.banknameLabel')"
            clearable
          />
          <AuInput
            v-model="editedBankRequisites.bik"
            :label="$t('requisites.bikLabel')"
            clearable
          />
          <AuInput
            v-model="editedBankRequisites.ks"
            :label="$t('requisites.correspondentAccountLabel')"
            clearable
          />
          <AuInput
            v-model="editedBankRequisites.rs"
            :label="$t('requisites.checkingAccountLabel')"
            clearable
          />
          <AuButton
            :is-progress="loading"
            border-radius="10"
            bordered
            center
            width="200px"
            @click="saveBankRequisites()"
          >
            {{ $t('requisites.saveButton') }}
          </AuButton>
        </div>
      </template>
    </AuModal>
  </div>
</template>

<script>
import _ from "lodash";

import { mapActions, mapGetters } from "vuex";

import Requisite from "@/components/requisites/Requisite";

export default {

  components: { Requisite },

  data() {
    return {
      editDialog: false,
      editedBankRequisites: null,
      loading: false
    };
  },

  computed: {
    ...mapGetters({
      companies: "companies"
    })
  },

  async mounted() {
    await this.getCompanies();
  },

  methods: {
    ...mapActions({
      postBankRequisites: "postBankRequisites",
      updateBankRequisites: "updateBankRequisites",
      deleteBankRequisites: "deleteBankRequisites",
      getCompanies: "getCompanies"
    }),

    editBankRequisites(company) {
      if ("requisites" in company) {
        this.editedBankRequisites = _.cloneDeep(company.requisites);
      }
      else {
        this.editedBankRequisites = {
          bankname: "",
          bik: "",
          company: `/api/companies/${company.id}`,
          ks: "",
          rs: "",
          user: `/api/users/${this.$auth.user().id}`
        };
      }
      this.editDialog = true;
    },

    async saveBankRequisites() {
      this.loading = true;

      if ("id" in this.editedBankRequisites) {
        await this.updateBankRequisites({ id: this.editedBankRequisites.id, newValue: this.editedBankRequisites });
      }
      else {
        await this.postBankRequisites(this.editedBankRequisites);
      }

      this.loading = false;

      this.editDialog = false;
    }
  }
};
</script>

<style scoped lang="scss">
.requisites {
  padding: 20px;
  font-family: 'Suisse Intl', serif;
  font-style: normal;
}

.req {
  &-page-wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &-data {
    display: flex;
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 15px;
  }

  &-name {
    width: 30%;
  }

  &-value {
    width: 70%;
    padding-left: 5px;
    white-space: break-spaces;
    color: var(--color-60)
  }

  &-buttons {
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 10px;
    right: 20px;
  }
}

.modal {
  &_header {
    font-weight: 400;
    font-size: 20px;
    margin-bottom: 10px;
    padding: 10px;
  }

  &_body {
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: space-between;
  }
}
</style>