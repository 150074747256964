import axios from "axios";

export default class axiosClient {

  async getProfile(id) {
    const headers = {
      accept: "application/json",
      "Content-Type": "application/json"
    };
    const { data } = await axios.get(`api/users/${id}`, { headers });
    return data;
  }

  async editProfile(id, body) {
    const headers = {
      accept: "application/json",
      "Content-Type": "application/merge-patch+json"
    };
    await axios.patch(`api/users/${id}`, body, { headers });
  }

  async confirmEmail(url) {
    const headers = {
      accept: "application/json",
      "Content-Type": "application/merge-patch+json"
    };
    return await axios.get(url, { headers });
  }
}